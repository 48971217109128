import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Tour from 'reactour';
import dotenv from 'dotenv';
import _ from 'lodash';
import { Grid, Typography, CardMedia, Card } from '@mui/material';
import { useAppSelector } from '../redux/hooks';
import { selectPlayer } from '../reducers/playerSlice';
import TopBar from '../component-modals/layout/TopBar';
import Footer from '../component-modals/layout/Footer';
import { FormattedMessage, useIntl } from 'react-intl';
import { gql, useQuery } from '@apollo/client';

dotenv.config();

const Home: React.FC = () => {
  const COUNT_CHALLENGES_BY_OPTIONS_QUERY = gql`
    query countChallengesByOptions($challenge: inputFindChallengesByOptions!) {
      countChallengesByOptions(challenge: $challenge)
    }
  `;

  // const COUNT_OPPONENTS_BY_OPTIONS_QUERY = gql`
  //   query countOpponentsByOptions($challenge: inputFindOpponentsByOptions!) {
  //     countOpponentsByOptions(challenge: $challenge)
  //   }
  // `;

  const COUNT_SPORT_TEAMS_BY_OPTIONS_QUERY = gql`
    query countSportTeamsByOptions($team: inputFindSportTeamsByOptions!) {
      countSportTeamsByOptions(team: $team)
    }
  `;

  const COUNT_AVAILABLE_FIELDS_QUERY = gql`
    query countAvailableFields($country_name: String!) {
      countAvailableFields(country_name: $country_name)
    }
  `;

  const COUNT_TOURNAMENTS_BY_OPTIONS_QUERY = gql`
    query countTournamentsByOptions($tournament: inputFindTournamentsByOptions!) {
      countTournamentsByOptions(tournament: $tournament)
    }
  `;

  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const [isTourOpen, setIsTourOpen] = React.useState(false);
  const [value, setValue] = React.useState(-1);
  const [today] = React.useState(Date.now());

  const loadedUpcomingChallenges = useQuery(COUNT_CHALLENGES_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      challenge: {
        offset: 0,
        start_date: today,
      },
    },
  });
  // const loadedOpponents = useQuery(COUNT_OPPONENTS_BY_OPTIONS_QUERY, {
  //   context: {
  //     headers: {
  //       'X-Anonymous-Access': 'true',
  //     },
  //   },
  //   variables: {
  //     challenge: {
  //       offset: 0,
  //       start_date: today,
  //     },
  //   },
  // });
  const loadedSportTeam = useQuery(COUNT_SPORT_TEAMS_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      team: {
        name: '',
        offset: 0,
      },
    },
  });
  const loadedAvailableFields = useQuery(COUNT_AVAILABLE_FIELDS_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      country_name: '',
    },
  });
  const loadedTournaments = useQuery(COUNT_TOURNAMENTS_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      tournament: {
        offset: 0,
        start_date: today,
      },
    },
  });

  const STEPS = [
    {
      selector: '.step1',
      content: 'This is my first Step',
    },
    {
      selector: '.step2',
      content: 'This is my second Step',
    },
  ];

  const ANONYMOUS_STEPS = [
    {
      avatar: 'https://images.pexels.com/photos/10667341/pexels-photo-10667341.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1510566337590-2fc1f21d0faa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1612021299961-d204280094b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1594749763924-c1fc94018423?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1594749794741-4c38168df1f4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1578636865240-07b13926f2eb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1486274613518-b260a67b38ba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1617975550828-26af98396018?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1649325884850-5d8b5a12d1b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2348&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1611443547770-368b359960dd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1335&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1675328924810-a4b0d8b7f10b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2542&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1572296232042-ef1181b39a1c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1646326477577-3c56e29dccbf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1678900798787-85707758caf2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1010&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
    {
      avatar: 'https://images.unsplash.com/photo-1663437269987-4ca17c903849?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80',
      steps: [
        {
          selector: '.avatar',
          content: 'Welcome to Fivezer, WHERE THE BEST TEAMS FROM ALL AROUND THE WORLD COMPETE AGAINST LOCAL AND OVERSEA TEAMS ANYWHERE, ANYTIME',
        },
      ],
    },
  ];

  if (value === -1) setValue(_.random(0, ANONYMOUS_STEPS?.length - 1));

  return (
    <Grid className={`home-page`} sx={{ p: '0 0 5%', overflowX: 'hidden', position: 'relative', background: 'rgba(15,15,15,1)' }}>
      <Helmet>
        <title>{`${intl.formatMessage({ id: 'Global_Meta_Title' })}`}</title>
        <meta name="description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <meta name="linkedin:title" content={`${intl.formatMessage({ id: 'Global_Meta_Title' })}`} />
        <meta name="linkedin:description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <meta name="twitter:title" content={`${intl.formatMessage({ id: 'Global_Meta_Title' })}`} />
        <meta name="twitter:description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <meta property="og:title" content={`${intl.formatMessage({ id: 'Global_Meta_Title' })}`} />
        <meta property="og:description" content={`${intl.formatMessage({ id: 'Global_Meta_Description' })}`} />

        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>
      <Grid item xs={12} md={12} lg={12}>
        <TopBar position={'relative'} backgroundColor={'transparent'} color={loggedPlayer?.app_configuration?.is_dark_mode ? '#F7FBFA' : 'rgb(15, 15, 15)'} colorHover={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} logo={undefined} />

        <Card sx={{ opacity: '.03', bgcolor: 'transparent', boxShadow: 'none', position: 'fixed', minHeight: '100vh', width: '100%', borderRadius: '0', zIndex: '0', top: '0', left: '0', backgroundImage: `linear-gradient(to bottom, rgba(15, 15, 15 ,0) 0%, rgba(15, 15, 15 ,0) 50%, rgba(15, 15, 15 ,.8) 100%), url(../img/pitch.jpg)`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <CardMedia
            sx={{
              backgroundImage: `url(${loggedPlayer?.avatar?.path || ANONYMOUS_STEPS[value]?.avatar || '../img/home-bg.png'})`,
              backgroundSize: {
                xs: '85%',
                sm: '65%',
                md: '60%',
                lg: '40%',
              },
              backgroundPosition: {
                xs: '200% 0%',
                sm: '110% 20%',
                md: '110% 20%',
                lg: '100% 30%',
              },
              mt: '5%',
              height: '70vh',
            }}
          >
            <Typography
              component="span"
              className="secondary-font"
              sx={{
                pt: '50px',
                fontSize: {
                  xs: '8em',
                  sm: '9em',
                  md: '13em',
                  lg: '15em',
                },
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: 'transparent',
                width: '100%',
                display: 'inline-block',
                lineHeight: {
                  xs: '110px',
                  sm: '150px',
                  md: '180px',
                  lg: '200px',
                },
              }}
            >
              <Grid sx={{ textAlign: 'left', position: 'relative', left: '-70px', WebkitTextStroke: '2px rgba(255, 255, 255, 1)' }}>
                <FormattedMessage id="Home_Street" />
              </Grid>
              <Grid sx={{ textAlign: 'center', position: 'relative', WebkitTextStroke: '2px rgba(255, 255, 255, 1)' }}>
                <FormattedMessage id="Home_Culture" />
              </Grid>
              <Grid sx={{ textAlign: 'right', position: 'relative', right: '-70px', WebkitTextStroke: '2px rgba(255, 255, 255, 1)' }}>
                <FormattedMessage id="Home_Football" />
              </Grid>
            </Typography>
          </CardMedia>
        </Card>

        <Grid className="header" sx={{ position: 'relative' }}>
          <Typography
            className="secondary-font"
            variant="h2"
            sx={{
              fontWeight: 'bold',
              // fontSize: {
              //   xs: '2em',
              //   sm: '4em',
              //   md: '6em',
              //   lg: '7em',
              // },
              // color: '#FFF',
              textTransform: 'uppercase',
              // lineHeight: {
              //   xs: '40px',
              //   sm: '50px',
              //   md: '65px',
              //   lg: '70px',
              // },
              textAlign: 'center',
              // textShadow: '3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
            }}
          >
            <Grid className="secondary-font" component="span" sx={{ textShadow: 'none', color: '#fff' }}>
              {/* <FivezerLogoIcon
                sx={{
                  height: {
                    xs: '60px',
                    sm: '90px',
                    md: '100px',
                    lg: '150px',
                  },
                  width: 'auto',
                  bottom: {
                    xs: '-30px',
                    sm: '-40px',
                    md: '-45px',
                    lg: '-50px',
                  },
                  position: 'relative',
                }}
              /> */}
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: '14vw', sm: '11vw', lg: '9vw' },
                  letterSpacing: '.2em',
                  position: 'relative',
                  mb: {
                    xs: '-10px',
                    sm: '-30px',
                    md: '-30px',
                    lg: '-40px',
                  },
                }}
              >
                FIVEZER
              </Typography>
            </Grid>
          </Typography>

          <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
            <Typography component="span" sx={{ display: 'block', mt: '0px' }}>
              {/* <Grid
                component="span"
                className="secondary-font"
                sx={{
                  fontWeight: 'bold',
                  color: '#FFF',
                  textShadow: '3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                  width: '100%',
                  display: 'inline-block',
                  mb: '30px',
                  p: '0 5%',
                  fontSize: {
                    xs: '2em',
                    sm: '4em',
                    md: '5em',
                    lg: '7em',
                  },
                  lineHeight: {
                    xs: '30px',
                    sm: '55px',
                    md: '65px',
                    lg: '90px',
                  },
                }}
              >
                <Typography variant="caption" sx={{ textShadow: 'none', fontSize: '.3em', fontWeight: '500' }}>
                  <FormattedMessage id="Home_Aka" />
                </Typography>
                <br />
                <FormattedMessage id="Home_The_Biggest" />
                <br />
                <FormattedMessage id="Home_Sports_Community" />
              </Grid> */}

              <Grid
                component="span"
                className="secondary-font"
                sx={{
                  width: '100%',
                  display: 'inline-block',
                  mb: '30px',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  p: '0 5%',
                  fontSize: { xs: '1.3em', sm: '2em', md: '2.6em', ld: '3em' },
                  lineHeight: {
                    xs: '25px',
                    sm: '35px',
                    md: '40px',
                    lg: '45px',
                  },
                  color: '#FFF',
                  textShadow: '3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
                  // color: 'transparent',
                  // WebkitTextStroke: '.5px rgba(255, 255, 255, 1)',
                  // textShadow: '3px 3px 0 #FFF, -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 rgba(199, 240, 11, 1)',
                }}
              >
                <FormattedMessage id="Home_Where" />
                <br />
                <FormattedMessage id="Home_From" />
                <br />
                <FormattedMessage id="Home_Compete" />
                <br />
                <FormattedMessage id="Home_Anywhere" />
              </Grid>
              <Grid
                component="span"
                id="tag-font"
                className="secondary-font"
                sx={{
                  width: '100%',
                  display: 'inline-block',
                  mb: '30px',
                  whiteSpace: 'nowrap',
                  fontSize: {
                    xs: '1.2em',
                    sm: '1.5em',
                    md: '1.8em',
                    lg: '2em',
                  },
                  textTransform: 'none',
                  // fontWeight: 'bold',
                  color: 'rgba(199, 240, 11, 1)',
                  // textShadow: '-1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF',
                }}
              >
                <FormattedMessage id="Home_Hashtag" />
              </Grid>
            </Typography>
          </Grid>
        </Grid>

        <Grid container className="selections" sx={{ position: 'relative', p: '0' }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ borderRadius: '10px', p: '20px', m: '0px', height: { xs: '200px', md: '400px' } }}>
              <Grid
                component={Link}
                to={`/feeds`}
                state={{ index: 1 }}
                sx={{
                  'borderRadius': '10px',
                  'backgroundImage': 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(../img/menu8.jpg)',
                  'backgroundSize': 'cover',
                  'backgroundPosition': { xs: '0 31%', sm: '0 31%', md: '0 30%' },
                  'display': 'inline-flex',
                  'justifyContent': 'center',
                  'alignItems': 'center',
                  'textAlign': 'center',
                  'width': '100%',
                  'height': '100%',
                  'textDecoration': 'none',
                  'color': '#FFF',
                  'border': 'solid 0px rgba(199, 240, 11, 1)',
                  '&:hover': { backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 50%), url(../img/menu8.jpg)' },
                }}
              >
                <Grid>
                  <Typography variant="h2" sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '1.3em', p: '0 20px' }}>
                    <FormattedMessage id="Home_View_Feeds" />
                  </Typography>
                  <Typography id="tag-font" sx={{ fontSize: '1.4em', lineHeight: '25px', color: 'rgba(199, 240, 11, 1)' }}>
                    <FormattedMessage id="Home_Enjoy_Feeds" />{' '}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ borderRadius: '10px', p: '20px', m: '0px', height: { xs: '200px', md: '400px' } }}>
              <Grid
                component={Link}
                to={loggedPlayer?.id !== undefined ? `/new_challenge` : `/signup`}
                sx={{
                  'borderRadius': '10px',
                  'backgroundImage': 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(../img/menu1.png)',
                  'backgroundSize': 'cover',
                  'backgroundPosition': 'center',
                  'display': 'inline-flex',
                  'justifyContent': 'center',
                  'alignItems': 'center',
                  'textAlign': 'center',
                  'width': '100%',
                  'height': '100%',
                  'textDecoration': 'none',
                  'color': '#FFF',
                  'border': 'solid 0px rgba(199, 240, 11, 1)',
                  '&:hover': { backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 50%), url(../img/menu1.png)' },
                }}
              >
                <Grid>
                  <Typography variant="h2" sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '1.3em', p: '0 20px' }}>
                    <FormattedMessage id="Home_New_Challenge" />
                  </Typography>
                  <Typography id="tag-font" sx={{ fontSize: '1.4em', lineHeight: '25px', color: 'rgba(199, 240, 11, 1)' }}>
                    {loadedAvailableFields.data?.countAvailableFields || 0}&nbsp;
                    <FormattedMessage id="Home_Throne" />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ borderRadius: '10px', p: '20px', m: '0px', height: { xs: '200px', md: '400px' } }}>
              <Grid
                component={Link}
                to={loggedPlayer?.id !== undefined ? `/find_challenge` : `/signup`}
                sx={{
                  'borderRadius': '10px',
                  'backgroundImage': 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(../img/menu2.png)',
                  'backgroundSize': 'cover',
                  'backgroundPosition': { xs: '0 30%', sm: '0 30%', md: 'center' },
                  'display': 'inline-flex',
                  'justifyContent': 'center',
                  'alignItems': 'center',
                  'textAlign': 'center',
                  'width': '100%',
                  'height': '100%',
                  'textDecoration': 'none',
                  'color': '#FFF',
                  'border': 'solid 0px rgba(199, 240, 11, 1)',
                  '&:hover': { backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 50%), url(../img/menu2.png)' },
                }}
              >
                <Grid>
                  <Typography variant="h2" sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '1.3em', p: '0 20px' }}>
                    <FormattedMessage id="Home_Find_Challenge" />
                  </Typography>
                  <Typography id="tag-font" sx={{ fontSize: '1.4em', lineHeight: '25px', color: 'rgba(199, 240, 11, 1)' }}>
                    {loadedUpcomingChallenges.data?.countChallengesByOptions > 0 ? <>{loadedUpcomingChallenges.data?.countChallengesByOptions}&nbsp;</> : ''}
                    {!loadedUpcomingChallenges.data?.countChallengesByOptions || loadedUpcomingChallenges.data?.countChallengesByOptions === 0 ? <FormattedMessage id="Home_Upcoming_No_Challenges" /> : loadedUpcomingChallenges.data?.countChallengesByOptions > 1 ? <FormattedMessage id="Home_Upcoming_Challenges" /> : <FormattedMessage id="Home_Upcoming_Challenge" />}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={4} lg={4} sx={{ borderRadius: '10px', p: '20px', m: '0px', height: { xs: '200px', md: '400px' } }}>
              <Grid
                component={Link}
                to={loggedPlayer?.id !== undefined ? `/find_opponent` : `/signup`}
                state={{ index: 1 }}
                sx={{
                  'borderRadius': '10px',
                  'backgroundImage': 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(../img/menu3.jpg)',
                  'backgroundSize': 'cover',
                  'backgroundPosition': { xs: '0 31%', sm: '0 31%', md: '0 30%' },
                  'display': 'inline-flex',
                  'justifyContent': 'center',
                  'alignItems': 'center',
                  'textAlign': 'center',
                  'width': '100%',
                  'height': '100%',
                  'textDecoration': 'none',
                  'color': '#FFF',
                  'border': 'solid 0px rgba(199, 240, 11, 1)',
                  '&:hover': { backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 50%), url(../img/menu3.jpg)' },
                }}
              >
                <Grid>
                  <Typography variant="h2" sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '1.3em', p: '0 20px' }}>
                    <FormattedMessage id="Home_Find_Opponent" />
                  </Typography>
                  <Typography id="tag-font" sx={{ fontSize: '1.4em', lineHeight: '25px', color: 'rgba(199, 240, 11, 1)' }}>
                    {loadedOpponents.data?.countOpponentsByOptions > 0 ? <>{loadedOpponents.data?.countOpponentsByOptions}&nbsp;</> : ''}
                    {!loadedOpponents.data?.countOpponentsByOptions || loadedOpponents.data?.countOpponentsByOptions === 0 ? <FormattedMessage id="Home_Find_No_Opponent" /> : loadedOpponents.data?.countOpponentsByOptions > 1 ? <FormattedMessage id="Home_Challengers" /> : <FormattedMessage id="Home_Challenger" />}
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}

            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ borderRadius: '10px', p: '20px', m: '0px', height: { xs: '200px', md: '400px' } }}>
              <Grid
                component={Link}
                to={loggedPlayer?.id !== undefined ? `/new_team` : `/signup`}
                sx={{
                  'borderRadius': '10px',
                  'backgroundImage': 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(../img/menu22.png)',
                  'backgroundSize': 'cover',
                  'backgroundPosition': { xs: '0 10%', sm: '0 10%', md: 'center' },
                  'display': 'inline-flex',
                  'justifyContent': 'center',
                  'alignItems': 'center',
                  'textAlign': 'center',
                  'width': '100%',
                  'height': '100%',
                  'textDecoration': 'none',
                  'color': '#FFF',
                  'border': 'solid 0px rgba(199, 240, 11, 1)',
                  '&:hover': { backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 50%), url(../img/menu22.png)' },
                }}
              >
                <Grid>
                  <Typography variant="h2" sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '1.3em', p: '0 20px' }}>
                    <FormattedMessage id="Home_Build_Team" />
                  </Typography>
                  <Typography id="tag-font" sx={{ fontSize: '1.4em', lineHeight: '25px', color: 'rgba(199, 240, 11, 1)' }}>
                    <FormattedMessage id="Home_Become" />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ borderRadius: '10px', p: '20px', m: '0px', height: { xs: '200px', md: '400px' } }}>
              <Grid
                component={Link}
                to={loggedPlayer?.id !== undefined ? `/join_team` : `/signup`}
                sx={{
                  'borderRadius': '10px',
                  'backgroundImage': 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(../img/menu6.jpg)',
                  'backgroundSize': 'cover',
                  'backgroundPosition': { xs: '0 30%', sm: '0 30%', md: '0 40%' },
                  'display': 'inline-flex',
                  'justifyContent': 'center',
                  'alignItems': 'center',
                  'textAlign': 'center',
                  'width': '100%',
                  'height': '100%',
                  'textDecoration': 'none',
                  'color': '#FFF',
                  'border': 'solid 0px rgba(199, 240, 11, 1)',
                  '&:hover': { backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 50%), url(../img/menu6.jpg)' },
                }}
              >
                <Grid>
                  <Typography variant="h2" sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '1.3em', p: '0 20px' }}>
                    <FormattedMessage id="Home_Join_Team" />
                  </Typography>
                  <Typography id="tag-font" sx={{ fontSize: '1.4em', lineHeight: '25px', color: 'rgba(199, 240, 11, 1)' }}>
                    {loadedSportTeam.data?.countSportTeamsByOptions >= 100 ? (
                      <>
                        <FormattedMessage id="Home_Over" />
                        &nbsp;
                      </>
                    ) : (
                      ''
                    )}
                    {loadedSportTeam.data?.countSportTeamsByOptions || 0}&nbsp;
                    {loadedSportTeam.data?.countSportTeamsByOptions > 1 ? <FormattedMessage id="Home_Teams" /> : <FormattedMessage id="Home_Team" />}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ borderRadius: '10px', p: '20px', m: '0px', height: { xs: '200px', md: '400px' } }}>
              <Grid
                component={Link}
                to={loggedPlayer?.id !== undefined ? `/tournaments` : `/signup`}
                sx={{
                  'borderRadius': '10px',
                  'backgroundImage': 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(../img/menu7.png)',
                  'backgroundSize': 'cover',
                  'backgroundPosition': { xs: '95% 0', sm: '95% 0', md: '95% 0' },
                  'display': 'inline-flex',
                  'justifyContent': 'center',
                  'alignItems': 'center',
                  'textAlign': 'center',
                  'width': '100%',
                  'height': '100%',
                  'textDecoration': 'none',
                  'color': '#FFF',
                  '&:hover': { backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 50%), url(../img/menu7.png)' },
                }}
              >
                <Grid>
                  <Typography variant="h2" sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '1.3em', p: '0 20px' }}>
                    <FormattedMessage id="Home_Prove" />
                  </Typography>
                  <Typography id="tag-font" sx={{ fontSize: '1.4em', lineHeight: '25px', color: 'rgba(199, 240, 11, 1)' }}>
                    {loadedTournaments.data?.countTournamentsByOptions > 0 ? <>{loadedTournaments.data?.countTournamentsByOptions}&nbsp;</> : ''}
                    {!loadedTournaments.data?.countTournamentsByOptions || loadedTournaments.data?.countTournamentsByOptions === 0 ? <FormattedMessage id="Home_Find_No_Tournament" /> : loadedTournaments.data?.countTournamentsByOptions > 1 ? <FormattedMessage id="Home_New_Tournaments" /> : <FormattedMessage id="Home_New_Tournament" />}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Tour steps={loggedPlayer?.id !== undefined ? STEPS : ANONYMOUS_STEPS[value]?.steps} showNavigation={loggedPlayer?.id !== undefined} showButtons={loggedPlayer?.id !== undefined} showNumber={loggedPlayer?.id !== undefined} rounded={80} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(!isTourOpen)} />
      </Grid>

      <Footer color={'#FFF'} />
    </Grid>
  );
};

export default Home;
