export const dutch_translations = {
  value: 'NL',
  content: {
    // GLOBAL
    Global_Meta_Title: 'Fivezer x Ander Dier, Zelfde Beest!',
    Global_Meta_Description: 'Fivezer is een next-generation Five League, waar de beste teams van over de hele wereld tegen lokale en overzeese teams strijden, overal en altijd. Fivezer is een gateway naar de grootste competitie/vriendschappelijke wedstrijden, waar elke speler/team hun prestaties en statistieken (individueel/collectief) kan bijhouden door gebruik te maken van onze verschillende ioT-tools (armband, ballen, netten en meer).',
    Global_Button_Pay_Now: 'Betaal nu',
    Global_Button_Pay_Now_Caption_1: 'reserveringen moeten worden betaald',
    Global_Button_Pay_Now_Caption_2: 'Je moet het inschrijvingsgeld betalen voor je toernooi',
    Global_Status: 'status',
    Global_Pictures_Label: 'afbeeldingen',
    Global_Pictures_Left: 'over',
    Global_Pictures_Max: 'Max',
    Global_Button_Add: 'Toevoegen',
    Global_Button_Add_Cover: 'Cover toevoegen',
    Global_Button_Add_Logo: 'Logo toevoegen',
    Global_Button_Add_PDF: 'PDF toevoegen',
    Global_Button_Add_IMG_File: 'IMG-bestand toevoegen',
    Global_Button_Resize: 'Klik op de afbeelding om te herschalen',
    Global_Button_Cancel: 'Annuleren',
    Global_Button_Crop: 'Bijsnijden',
    Global_Button_Close: 'Sluiten',
    Global_Button_Ratio: 'Afbeelding voldoet niet aan het gewenste aspectratio',
    Global_Button_Delete: 'Verwijderen',
    Global_Button_Save_Updates: 'Updates opslaan',
    Global_Button_Reset: 'Herstellen',
    Global_Button_Share: 'Delen',
    Global_Button_Share_Caption_1: 'Je kunt meer vinden over',
    Global_Button_Share_Caption_2: 'op',
    Global_Button_Save_Translation: 'Vertaling opslaan',
    Global_Button_Loading: 'laden...',
    Global_Button_Loading_Wait: 'laden, even geduld...',
    Global_Button_Load_More: 'meer laden',
    Global_Button_See_More: 'meer bekijken',
    Global_Button_See: 'bekijken',
    Global_Button_See_Less: 'minder bekijken',
    Global_Button_Follow: 'Volgen',
    Global_Button_Unfollow: 'Niet meer volgen',
    Global_Follows_You: 'volgt jou',
    Global_Button_Next: 'Volgende',
    Global_Button_Back: 'Vorige',
    Global_Button_Done: 'Klaar',
    Global_Button_View: 'Bekijken',
    Global_Button_Update: 'Bijwerken',
    Global_Address_Title_1: 'Adrestitel',
    Global_Address_Title_2: 'Hoofdkantoor van, Kantoor van, Adres van, enzovoort...',
    Global_Address_Street_Name_1: 'Straatnaam',
    Global_Address_Street_Name_2: '83 Vredesstraat',
    Global_Address_Complement_1: 'Straatnaam (aanvulling)',
    Global_Address_Complement_2: 'Aanvulling op straatnaam',
    Global_Address_Complement_3: 'Appartement, suite, gebouw, verdieping, enzovoort...',
    Global_Address_Postcode_1: 'Postcode',
    Global_Address_Postcode_2: '75000',
    Global_Address_City_1: 'Stad',
    Global_Address_City_2: 'Vredesstad',
    Global_Address_County_1: 'Graafschap',
    Global_Address_County_2: 'Graafschap van vrede',
    Global_Address_State_1: 'Staat',
    Global_Address_State_2: 'Staat van vrede',
    Global_Address_Country: 'Land',
    Global_Address_GPS_1: 'Gelieve het bovenstaande adres op de kaart te lokaliseren.',
    Global_Address_GPS_2: 'Klik op de markering om deze te verplaatsen en wees zo nauwkeurig mogelijk!',
    Global_No_Description: 'Geen beschrijvingen',
    Global_Sport_Team_Type_1: 'Competitie',
    Global_Sport_Team_Type_2: 'Vriendschappelijk',
    Global_Sport_1: 'Voetbal',
    Global_Sport_2: 'Basketbal',
    Global_Social_Media_Title: 'Sociale Media',
    Global_Social_Media_Button_1: 'Sociale media toevoegen',
    Global_Select_Translations: 'Vertalingen',
    Global_Stats_Games: 'Wedstrijden',
    Global_Stats_Games_Played: 'Gespeelde wedstrijden',
    Global_Stats_Wins: 'Winsten',
    Global_Stats_Draws: 'Gelijkspel',
    Global_Stats_Loss: 'Verlies',
    Global_Stats_Goals: 'Doelpunten',
    Global_Stats_Assists: 'Assists',
    Global_Stats_Conceded: 'Tegen doelpunten',
    Global_Stats_World: 'Rang',
    Global_Stats_World_Sign_1: 'st',
    Global_Stats_World_Sign_2: 'nd',
    Global_Stats_World_Sign_3: 'rd',
    Global_Stats_World_Sign: 'th',
    Global_Stats_Followers: 'Volgers',
    Global_Stats_Follower: 'Volger',
    Global_Stats_MOTM: 'Man van de wedstrijd',
    Global_Stats_Good_Passes: 'Geslaagde passes',
    Global_Stats_Shots: 'Schoten',
    Global_Stats_KM_Total: 'KM totaal',
    Global_Stats_KM_Average: 'KM per wedstrijd gemiddeld',
    Global_Stats_KMH_Max: 'Maximale snelheid KM/uur',
    Global_Stats_KMH_Average: 'Gemiddelde snelheid KM/uur',
    Global_Stats_Clean_Sheet_1: 'Schoon',
    Global_Stats_Clean_Sheet_2: 'Sheet',
    Global_Tabs_Overview: 'Overzicht',
    Global_Tabs_Notifications: 'Meldingen',
    Global_Tabs_Calendar: 'Kalender',
    Global_Tabs_Lineups: 'Opstellingen',
    Global_Tabs_Lineup: 'Opstelling',
    Global_Tabs_Statistics: 'Statistieken',
    Global_Button_Add_Player: 'Speler toevoegen',
    Global_Button_Add_Manager: 'Manager toevoegen',
    Global_Button_Delete_Player: 'Speler verwijderen',
    Global_Button_New_Lineup: 'Nieuwe opstelling',
    Global_Button_Edit_Lineup: 'Opstelling bewerken',
    Global_Button_New_Challenge: 'Nieuwe uitdaging',
    Global_Button_No_Notifications: 'Nog geen meldingen',
    Global_No_Challenges: 'Geen uitdagingen',
    Global_No_Players: 'Geen spelers',
    Global_No_Manager: 'Geen managers',
    Global_No_Notifications: 'Geen meldingen',
    Global_Sport_Type_1: 'Voetbal',
    Global_Sport_Type_2: 'Basketbal',
    Global_No_Trophies: 'Geen trofeeën',
    Global_Trophy_Gold: 'Goud',
    Global_Trophy_Silver: 'Zilver',
    Global_Trophy_Bronze: 'Brons',
    Global_Trophy_Iron: 'IJzer',
    Global_Trophy_Right: 'Rechts',
    Global_Trophy_Left: 'Links',
    Global_Trophy_Both: 'Beide',
    Global_Since: 'sinds',
    Global_Challenge_Type_Friendly: 'Vriendschappelijk',
    Global_Challenge_Type_League: 'Competitie',
    Global_Challenge_Type_Matchmaker: 'Matchmaker',
    Global_Dates_Start_Label: 'Startdatum',
    Global_Dates_End_Label: 'Einddatum',
    Global_Followers: 'Volgers',
    Global_Followings: 'Volgend',
    Global_Gender_Female: 'Vrouw',
    Global_Gender_Male: 'Man',
    Global_Gender_Other: 'Anders',
    Global_Position_Goalkeeper: 'Doelman',
    Global_Position_Defender: 'Verdediger',
    Global_Position_Midfielder: 'Middenvelder',
    Global_Position_Striker: 'Aanvaller',
    Global_Foots_Right: 'Rechts',
    Global_Foots_Left: 'Links',
    Global_Foots_Both: 'Beide',
    Global_ID_Types_None: 'Selecteer een ID-type',
    Global_ID_Types_Passport: 'Paspoort',
    Global_ID_Types_National: 'Nationaal',
    Global_ID_Types_Residential: 'Woonachtig (Tijdelijk)',
    Global_Permanent_Delete_Title: 'Permanent verwijderen',
    Global_Button_Desactivate: 'Deactiveren',
    Global_Button_Activate: 'Activeren',
    Global_Field_Types_1: 'Hybride',
    Global_Field_Types_2: 'Synthetisch',
    Global_Field_Types_3: 'Tweede generatie (2G)',
    Global_Field_Types_4: 'Derde generatie (3G)',
    Global_Field_Types_5: 'Vierde generatie (4G)',
    Global_Field_Types_6: 'Vijfde generatie (5G)',
    Global_Field_Types_7: 'Zesde generatie (6G)',
    Global_Field_Sizes_1: '1vs1',
    Global_Field_Sizes_2: '2vs2',
    Global_Field_Sizes_3: '3vs3',
    Global_Field_Sizes_4: '4vs4',
    Global_Field_Sizes_5: '5vs5',
    Global_Field_Sizes_6: '6vs6',
    Global_Field_Sizes_7: '7vs7',
    Global_Field_Sizes_8: '8vs8',
    Global_Field_Sizes_9: '9vs9',
    Global_Field_Sizes_10: '10vs10',
    Global_Field_Sizes_11: '11vs11',
    Global_Delete_Confirmation_Caption_1: 'Deze actie is onomkeerbaar',
    Global_Delete_Confirmation_Caption_2: 'Wil je echt verwijderen',
    Global_Delete_Confirmation_Caption_3: 'Bevestig door te schrijven',
    Global_Delete_Confirmation_Caption_4: 'in het onderstaande veld:',
    Global_Write_Username: 'Schrijf een gebruikersnaam',
    Global_Write_Reset_All: 'Alles resetten',
    Global_Filters_Title: 'Filters',
    Global_No_User_Found_Title: 'Geen gebruiker gevonden',
    Global_No_Team_Found_Title: 'Geen team gevonden',
    Global_See_Team: 'Bekijk team',
    Global_Write_Team: 'Schrijf een trefwoord/thema om te zoeken',
    Global_Search_Tab_Players: 'Spelers',
    Global_Search_Tab_Teams: 'Teams',
    Global_Pin_SMS_1: 'Hallo',
    Global_Pin_SMS_2: 'Je Fivezer-verificatiecode is:',
    Global_Activate_Email_Label_1: 'Je moet je e-mail activeren',
    Global_Activate_Email_Label_2: 'met behulp van de verificatie-e-mail die we je hebben gestuurd.',
    Global_Activate_Email_Button_1: 'E-mail verzenden',
    Global_Activate_Email_Button_2: 'VERZONDEN',
    Global_Activate_Email_Button_3: 'OPNIEUW VERZENDEN',
    Global_As_Footballer: 'Als',
    Global_No_Statistics: 'Geen statistieken',
    Global_No_History: 'Geen transfergeschiedenis',
    Global_NA: 'N.v.t.',
    Global_Dark_Mode: 'Donkere modus',
    Global_Light_Mode: 'Lichte modus',
    Global_Player: 'speler',
    Global_Manager: 'manager',
    Global_Missing_Footballer_Profile_Button_1: 'Voeg het nu toe',
    Global_Missing_Footballer_Profile_Label_1: 'Je mist een voetbalprofiel',
    Global_Button_Edit: 'Bewerken',
    Global_Manager_At: 'Manager van',
    Global_Not_Found: 'niet gevonden',
    Global_Page_Not_Found_Title: 'Pagina niet gevonden',
    Global_Page_Not_Found_1: 'Sorry daarvoor',
    Global_Page_Not_Found_2: 'We konden de informatie niet laden door een zwak netwerk',
    Global_Page_Not_Found_3: 'Probeer het opnieuw',
    Global_Page_Not_Found_4: 'Toegang geweigerd',
    Global_Page_Not_Found_5: 'U heeft geen toegang tot deze informatie',
    Global_Page_Not_Found_6: 'Ga naar de startpagina',
    Global_Page_Not_Found_7: 'niet gevonden',
    Global_Page_Not_Found_8: 'controleer de spelling van de naam of gebruik onze',
    Global_Page_Not_Found_9: 'zoekmachine',
    Global_Page_Not_Found_10: 'om een nieuwe zoekopdracht te proberen.',
    Global_Page_Not_Found_11: 'Laden mislukt',
    Global_Page_Not_Found_12: 'probeer de pagina opnieuw te laden.',
    Global_Button_New_Livestream: 'Nieuwe livestream',
    Global_Button_New_Team: 'Nieuw team',

    // NOTIFICATIONS MESSAGES
    View: 'Bekijken',
    Accept: 'Accepteren',
    Reject: 'Afwijzen',
    Decline: 'Weigeren',
    Notification_New_Tournament_Title: 'Nieuw toernooi!',
    Notification_New_Tournament_Content_1: 'is aangemaakt!',
    Notification_Join_Team_Title: 'wil lid worden van',
    Notification_Join_Team_Content_1: 'heeft je een verzoek gestuurd om lid te worden van',
    Notification_New_Team_Title: 'Een nieuw team is geboren!',
    Notification_New_Team_Content_1: 'Gefeliciteerd',
    Notification_New_Team_Content_2: 'Je team is nu actief.',
    Notification_New_Footballer_Title: 'Een nieuwe voetballer is geboren!',
    Notification_New_Footballer_Content_1: 'Gefeliciteerd',
    Notification_New_Footballer_Content_2: 'Je voetbalprofiel is nu actief.',
    Notification_New_Flex_Title: 'Samen een Flex publiceren',
    Notification_New_Flex_Content_1: 'wil samen een flex met je publiceren.',
    Notification_New_Challenge_Title: 'Nieuwe uitdaging!',
    Notification_New_Challenge_Content_1: 'Uitdaging',
    Notification_New_Challenge_Content_2: 'is geboekt, maak je team klaar!',
    Notification_New_Challenge_Content_3: 'De aanvoerder van de uitdaging moet de uitnodiging accepteren of afwijzen!',
    Notification_New_Challenge_Content_4: 'Elke speler moet betalen',
    Notification_New_Challenge_Content_5: 'tussen',
    Notification_New_Challenge_Content_6: 'niets omdat dit veld gratis is!',
    Notification_ScheduleTournamentModal_Title: 'Nieuwe boeking!',
    Notification_ScheduleTournamentModal_Content_1: 'is geboekt, bekijk je dashboard om het te zien.',
    Notification_Edit_Tournament_Playoff_Modal_Title: 'Nieuwe boeking!',
    Notification_Edit_Tournament_Playoff_Modal_Content_1: 'is geboekt, bekijk je dashboard om het te zien.',
    Notification_View_Team_Lineup_Title: 'wil lid worden van je team!',
    Notification_View_Team_Lineup_Content_1: 'heeft je een verzoek gestuurd om lid te worden van je team om te spelen',
    // APP COMPONENT
    App_User: 'Gebruiker',
    App_Home: 'Start',
    App_Teams: 'Teams',
    App_Feeds: 'Nieuws',
    App_Challenges: 'Uitdagingen',
    App_Tournaments: 'Toernooien',
    App_New_Challenge: 'Nieuwe uitdaging',
    App_Find_Challenge: 'Zoek een uitdaging',
    App_Find_Opponent: 'Zoek een tegenstander',
    App_Build_Team: 'Bouw een team',
    App_Join_Team: 'Sluit je aan bij een team',
    App_New_Tournament: 'Nieuw toernooi',
    App_Add_PWA_Content: 'Download Fivezer!',
    App_Add_PWA_Safari_Tap: 'Tik',
    App_Add_PWA_Safari_Content: 'en "Voeg toe aan startscherm"',
    App_Add_PWA: 'Download',
    App_New_Field: 'Voeg nieuw veld toe',
    App_Menu_Button_Signup: 'Aanmelden',
    App_Menu_Button_Login: 'Inloggen',
    App_New_Flex: 'Nieuwe flex',

    // TOPBAR COMPONENT
    TopBar_Login: 'Inloggen',
    // FOOTER COMPONENT
    Footer_All_Rights: 'Alle rechten voorbehouden.',
    Footer_Legal_Mentions: 'Juridische vermeldingen',
    Footer_Legal_Mentions_Content: `
**1. Inleiding:**
   - "Welkom bij Fivezer, jouw toegangspoort tot de wereld van internationale sport en competitie. Bij Fivezer zijn we toegewijd aan het bevorderen van een levendige sportgemeenschap waar consumenten sportsessies kunnen boeken, en complexe eigenaren boekingen efficiënt kunnen beheren en betalingen kunnen ontvangen via ons platform. Onze missie is om atleten en teams van over de hele wereld met elkaar te verbinden, hen de mogelijkheid te bieden om hun vaardigheden te tonen en hun prestaties te volgen met geavanceerde technologie. We nodigen je uit om met ons mee te gaan op deze opwindende reis terwijl we de wereld van sport transformeren."
    
**2. Visie en Missie:**
   - Visie van Fivezer:
     - "Wij streven naar een wereld waar atleten en sportteams, ongeacht hun locatie, kunnen concurreren, verbinden en uitblinken op een wereldwijd podium. Fivezer is de sleutel tot deze visie, waarbij we de sportgemeenschap verenigen en tools bieden voor groei en ontwikkeling."
   - Missieverklaring:
     - "Fivezer streeft ernaar een naadloos en transparant ecosysteem te creëren waar sportliefhebbers, teams en atleten zich kunnen engageren, concurreren en succesvol kunnen zijn."
    
**3. Probleemstelling:**
   - "Erkennend de behoefte aan een gestroomlijnd platform dat atleten, teams en sportcomplexen in staat stelt om op een efficiënte manier verbinding te maken, te concurreren en hun activiteiten te beheren, is Fivezer toegewijd aan het aanpakken van deze uitdaging."
    
**4. Bedrijfsinformatie:**
   - "Fivezer, beheerd door Africantum, is een geregistreerd bedrijf met het hoofdkantoor in Frankrijk. Je kunt contact met ons opnemen via contact@thebhub.io."
    
**5. Gebruiksvoorwaarden:**
   - Door gebruik te maken van de diensten van Fivezer, ga je akkoord met de volgende Gebruiksvoorwaarden, die een bindende overeenkomst vormen tussen jou en Africantum:
    
   **5.1. Accountcreatie en beheer:**
      - "Jouw Fivezer-account is jouw toegangspoort tot de wereld van sport. We bieden een uitgebreid scala aan tools en functies om je te helpen bij het beheren van je sportactiviteiten, het verbinden met mede-atleten en teams, en het verbeteren van je prestaties. Door je te registreren en je account te gebruiken, krijg je toegang tot een wereld van sportmogelijkheden."
    
   **5.2. Gebruikersgedrag:**
      - Gebruikers moeten zich houden aan alle toepasselijke wetten en voorschriften.
      - Verboden activiteiten kunnen onder meer intimidatie, bedrog, spam en alle illegale activiteiten omvatten.
    
   **5.3. Indienen van inhoud:**
      - Gebruikers zijn verantwoordelijk voor de inhoud die ze op het platform indienen.
      - Verboden inhoud kan auteursrechtelijk beschermd materiaal, haatzaaiende taal of schadelijke inhoud omvatten.
    
   **5.4. Privacy en gegevensgebruik:**
      - Informatie over hoe gebruikersgegevens worden verzameld, opgeslagen en gebruikt.
      - Toestemming voor gegevensverwerking en delen binnen het platform.
    
   **5.5. Intellectueel eigendom:**
      - Bescherming van intellectuele eigendomsrechten voor zowel gebruikers als het platform.
      - Meldingsmechanisme voor inbreuken op auteursrecht of handelsmerken.
    
   **5.6. Transacties en betalingen:**
      - Voorwaarden met betrekking tot de verwerking van betalingen, inclusief kosten, terugbetalingen en betaalmethoden.
      - Verkopersverantwoordelijkheden voor nauwkeurige servicebeschrijvingen en levering.
    
   **5.7. Geschillenbeslechting:**
      - Procedures voor geschillenbeslechting, inclusief bemiddeling of arbitrage.
      - Rapportage en oplossing van conflicten tussen gebruikers.
    
   **5.8. Beëindiging en schorsing:**
      - Voorwaarden waarin accounts kunnen worden beëindigd of geschorst.
      - Beroepsprocedure voor gebruikers die onderworpen zijn aan accountacties.
    
   **5.9. Aansprakelijkheid en garantie:**
      - Beperkingen van aansprakelijkheid voor het platform en zijn gebruikers.
      - Garantiedisclaimers voor diensten en producten.
    
   **5.10. Toepasselijk recht en rechtsgebied:**
      - Keuze van recht en rechtsgebied voor juridische zaken.
    
   **5.11. Wijzigingen in de voorwaarden:**
      - Het recht van het platform om de voorwaarden en condities bij te werken of te wijzigen.
      - Kennisgeving aan gebruikers over wijzigingen.
    
   **5.12. Vrijwaring:**
      - Gebruikers stemmen ermee in het platform schadeloos te stellen en te vrijwaren tegen juridische claims of aansprakelijkheden.
    
   **5.13. Communicatie en meldingen:**
      - Hoe het platform communiceert met gebruikers, inclusief e-mailmeldingen.
    
   **5.14. Gebruikersrecensies en feedback:**
      - Beleid met betrekking tot gebruikersrecensies en feedback op het platform.
    
   **5.15. Beveiligingsmaatregelen:**
      - Gebruikersverantwoordelijkheden voor het handhaven van de accountbeveiliging.
      - Platformmaatregelen om gebruikersgegevens te beschermen.
    
   **5.16. Toegankelijkheid en beschikbaarheid:**
      - Eisen voor platformtoegankelijkheid en verzekeringen van beschikbaarheid.
    
   **5.17. Beëindigingsrechten van gebruikers:**
      - Voorwaarden waaronder gebruikers hun accounts kunnen beëindigen.
    
   **5.18. Communityrichtlijnen:**
      - Platformspecifieke communityrichtlijnen en gedragsregels.
    
   **5.19. Naleving van wetten:**
      - Gebruikersverplichting om te voldoen aan alle toepasselijke wetten.
    
   **5.20. Diversen:**
      - Eventuele aanvullende juridische clausules of disclaimers specifiek voor de missie en gebruikersbasis van het platform.
    
**6. Privacybeleid:**
   - "Het Privacybeleid van Fivezer beschrijft hoe we gebruikersgegevens verzamelen, opslaan en verwerken in overeenstemming met de wetgeving inzake gegevensbescherming. Dit omvat het gebruik van geavanceerde technologieën om de sportervaring te verbeteren en gebruikersgegevens te beschermen. Gebruikers kunnen ons volledige Privacybeleid raadplegen voor meer details."
    
**7. Gebruikersverantwoordelijkheden:**
   - "Als gebruiker van Fivezer ben je verantwoordelijk voor het naleven van ethische en wettelijke normen in je interacties met andere gebruikers en binnen het platform. Dit omvat het zich onthouden van frauduleuze activiteiten, bedrog of wangedrag."
    
**8. Intellectueel eigendom:**
   - "Alle intellectuele eigendomsrechten op Fivezer, inclusief maar niet beperkt tot handelsmerken en auteursrechten, zijn eigendom van Africantum. Gebruikers moeten deze rechten respecteren, en eventuele gemelde schendingen zullen snel worden aangepakt."
    
**9. Geschillenbeslechting:**
   - "In geval van geschillen worden gebruikers aangemoedigd om problemen te melden via de aangewezen kanalen van Fivezer. Geschillen kunnen worden opgelost via bemiddeling of arbitrage, zoals uiteengezet in ons geschillenbeslechtingsproces."
    
**10. Rechtsgebied en toepasselijk recht:**
    - "Het rechtsgebied voor juridische zaken met betrekking tot Fivezer is Frankrijk. Alle juridische geschillen zullen worden beheerst door de wetten van Frankrijk."
    
**11. Handhaving en sancties:**
    - "Fivezer behoudt zich het recht voor om deze voorwaarden en beleidslijnen af te dwingen. Schendingen kunnen leiden tot sancties, waaronder accountopschorting of -beëindiging."
    
**12. Melden van wangedrag:**
    - "Als je ongepast gedrag, bedrog of frauduleuze activiteiten op Fivezer tegenkomt, meld deze dan onmiddellijk aan ons ondersteuningsteam. We nemen gebruikersbescherming serieus en zullen dergelijke kwesties snel onderzoeken en aanpakken."
    
**13. Juridische verwijzingen en naleving:**
    - "Fivezer verbindt zich ertoe om te voldoen aan alle toepasselijke nationale en internationale wetten, inclusief maar niet beperkt tot consumentenbescherming, e-commerce en regelgeving inzake gegevensbescherming."
    
**14. Gegevensbescherming en privacy:**
    - "Fivezer hecht waarde aan de privacy en gegevensbescherming van zijn gebruikers. We verbinden ons ertoe om te voldoen aan de wetgeving inzake gegevensbescherming, inclusief de Algemene Verordening Gegevensbescherming (AVG) en andere toepasselijke regelgeving."
    - "Gegevensverzameling van gebruikers: We verzamelen en verwerken gebruikersgegevens voor specifieke doeleinden, waaronder accountbeheer, communicatie, prestatieanalyse en beveiliging."
    - "Gegevensbeveiliging: We maken gebruik van robuuste beveiligingsmaatregelen, waaronder versleuteling, om gebruikersgegevens te beschermen tegen ongeoorloofde toegang of inbreuken."
    - "Delen met derden: Gebruikersgegevens kunnen worden gedeeld met vertrouwde derde partijen voor doeleinden zoals betalingsverwerking en analyse. We zorgen ervoor dat deze providers ook voldoen aan de regelgeving inzake gegevensbescherming."
    - "Gebruikersrechten: Gebruikers hebben het recht om hun persoonlijke gegevens die door Fivezer worden bewaard, in te zien, te corrigeren of te verwijderen. Verzoeken kunnen worden ingediend via onze aangewezen kanalen."
    - "Cookies: We gebruiken cookies om de gebruikerservaring te verbeteren. Gebruikers kunnen cookievoorkeuren beheren via de instellingen van hun browser."
    - "Door gebruik te maken van de diensten van Fivezer stem je in met de verzameling, verwerking en gebruik van je gegevens voor de gespecificeerde doeleinden zoals beschreven in ons Privacybeleid."
`,
    Footer_Complex_Listing: 'Registratie van sportcomplexen',

    // INPUTDESCRIPTION COMPONENT
    InputDescriptionsFeature_Descriptions_Label: 'Beschikbare talen',
    InputDescriptionsFeature_Descriptions_Placeholder: 'Selecteer een taal',
    InputDescriptionsFeature_Add_Language: 'Taal toevoegen',
    InputDescriptionsFeature_Selected_Description_Label_1: 'Huidige beschrijvingen',
    InputDescriptionsFeature_Selected_Description_Label_2: 'en',
    InputDescriptionsFeature_Selected_Description_Label_3: 'beschrijvingen',
    InputDescriptionsFeature_Selected_Description_Label_4: 'beschrijving',
    InputDescriptionsFeature_Selected_Description_Label_5: 'met lege inhoud',
    InputDescriptionsFeature_Selected_Description_Placeholder: 'Selecteer een beschrijving',
    InputDescriptionsFeature_Short_Descriptions_Placeholder: 'Voer een korte beschrijving in',
    InputDescriptionsFeature_Selected_Description_Caption: '(lege inhoud)',
    InputDescriptionsFeature_Selected_Description_Content: 'Beschrijving in',
    InputDescriptionsFeature_Selected_Short_Description_Content: 'Korte beschrijving in',
    InputDescriptionsFeature_Selected_Description_Content_Warning: 'Vergeet niet om je inhoud op te slaan na elke update',
    InputDescriptionsFeature_African_Languages: 'AFRIKAANSE TALEN',
    InputDescriptionsFeature_Other_Languages: 'ANDERE TALEN',
    // LOGINPAGE
    LoginPage_Hello: 'Hallo',
    LoginPage_Friend: 'Baller',
    LoginPage_Welcome_Back: 'Welkom terug!',
    // LOGIN FORM COMPONENT
    LoginForm_Login_Title: 'Inloggen',
    LoginForm_Identifier: 'Gebruikersnaam / E-mail',
    LoginForm_Password: 'Wachtwoord',
    LoginForm_Forgot_Password: 'Wachtwoord vergeten?',
    LoginForm_Turn_On_Location: 'Schakel je locatie in om je account volledig te beveiligen',
    LoginForm_Login: 'Inloggen',
    LoginForm_Logging: 'Aan het inloggen...',
    LoginForm_No_Member_Yet: 'Nog geen lid?',
    LoginForm_Signup: 'Meld je nu aan',
    LoginForm_Error_Login: 'Je hebt de verkeerde ID/wachtwoord, probeer het opnieuw of reset je wachtwoord',
    LoginForm_Reset_Password: 'Wachtwoord resetten',
    LoginForm_Remember_Password: 'Herinner je je wachtwoord nog?',
    LoginForm_Switch_User: 'Account wisselen',
    // FORGOTPASSWORDFORM COMPONENT
    ForgotPasswordForm_Identifier: 'Identificator',
    ForgotPasswordForm_Email_Mobile: 'E-mail / Mobiel',
    ForgotPasswordForm_Pin_Verification: 'PIN-verificatie',
    ForgotPasswordForm_Please_Wait: 'even geduld a.u.b....',
    ForgotPasswordForm_Sending_SMS: 'SMS verzenden...',
    ForgotPasswordForm_Send_Via_Email: 'PIN via e-mail verzenden',
    ForgotPasswordForm_Send_Via_SMS: 'PIN via SMS verzenden',
    ForgotPasswordForm_Validation: 'Validatie',
    ForgotPasswordForm_Is_Submitted: 'Je wachtwoord is bijgewerkt, probeer in te loggen!',
    ForgotPasswordForm_Login_Now: 'Log nu in',
    ForgotPasswordForm_Is_Updated: 'Je wachtwoord is bijgewerkt!',
    ForgotPasswordForm_New_Password: 'Nieuw Wachtwoord',
    ForgotPasswordForm_New_Password_Confirmation: 'Bevestig nieuw wachtwoord',
    ForgotPasswordForm_Reset_Password: 'Wachtwoord resetten',
    ForgotPasswordForm_Last_Step: 'Laatste stap',
    ForgotPasswordForm_Login_Button: 'Inloggen',
    ForgotPasswordForm_Back_Button: 'Terug',
    ForgotPasswordForm_PIN_Verification_Button: 'PIN-code verificatie',
    ForgotPasswordForm_Continue_Button: 'Doorgaan',
    ForgotPasswordForm_Turn_On_Location: 'Schakel je locatie in om je account volledig te beveiligen',
    ForgotPasswordForm_Is_Email_Submitted: 'Je e-mail is verzonden, controleer je inbox',
    ForgotPasswordForm_Resend_Email: 'Er is iets misgegaan, probeer het opnieuw',

    // SIGNUPFORM COMPONENT
    SignUpForm_Title: 'Aanmelden',
    SignUpForm_Account_Info: 'Accountinformatie',
    SignUpForm_Username: 'Gebruikersnaam',
    SignUpForm_Username_Taken: 'is al in gebruik',
    SignUpForm_Email_Already_Used: 'is al gebruikt bij een ander account',
    SignUpForm_Email_Incorrect: 'E-mail formaat onjuist',
    SignUpForm_Email: 'E-mail',
    SignUpForm_Gender: 'Geslacht',
    SignUpForm_DOB: 'Geboortedatum',
    SignUpForm_My_Gender: 'Mijn voornaamwoord is...',
    SignUpForm_Firstname: 'Voornaam',
    SignUpForm_Lastname: 'Achternaam',
    SignUpForm_New_Password: 'Nieuw wachtwoord',
    SignUpForm_Mobile_Verification: 'Mobiele verificatie',
    SignUpForm_Mobile: 'Mobiel',
    SignUpForm_Sending_SMS: 'SMS verzenden...',
    SignUpForm_Send_SMS: 'Verstuur',
    SignUpForm_SMS_Error: 'SMS kon niet worden verzonden naar',
    SignUpForm_Beta_Notice: 'BETA VERSIE: Hier is je PINCODE',
    SignUpForm_Use_International_Number: "Gebruik internationale notatie '+' of '00'",
    SignUpForm_Great: 'Geweldig!',
    SignUpForm_Next_Step: 'Volgende stap, we gaan je voetbalspeler aanpassen, laten we beginnen!',
    SignUpForm_We_Sent_Email: "Na het klikken op 'Speler aanmaken', sturen we je een e-mail om je account te verifiëren naar",
    SignUpForm_To_Verify: 'om je account te verifiëren.',
    SignUpForm_Create_Account_Button: 'Speler aanmaken',
    SignUpForm_Last_Step: 'Laatste stap',
    SignUpForm_Login_Button: 'Inloggen',
    SignUpForm_Back_Button: 'Terug',
    SignUpForm_PIN_Verification_Button: 'PIN verificatie',
    SignUpForm_Continue_Button: 'Doorgaan',
    SignUpForm_Turn_On_Location: 'Schakel je locatie in om je account volledig te beveiligen',
    SignUpForm_Already_Member: 'Al lid?',
    SignUpForm_All_Done: 'Alle stappen voltooid - je bent klaar',
    SignUpForm_Loading_message: 'Account aanmaken, even geduld...',

    // ONBOARDING COMPONENT
    Onboarding_Create: 'Aanmaken',
    Onboarding_Your_Team: 'Jouw',
    Onboarding_Team: 'Team',
    Onboarding_Create_Content: 'Maak je team en je spel aan. Selecteer je logo & teamnaam. Kies je strijders. Beheer je bende.',
    Onboarding_Book: 'Boek',
    Onboarding_Your_Arena: 'Je',
    Onboarding_Arena: 'Arena',
    Onboarding_Book_Content: 'Boek je veld over de hele wereld & daag andere teams uit.',
    Onboarding_Climb: 'Beklim de',
    Onboarding_Climb_The: 'De',
    Onboarding_Climb_Ranking: 'Ranglijst',
    Onboarding_Climb_Content: 'De ranglijst van Fivezer is gebaseerd op de resultaten van elk team in de wereld. Win je wedstrijden, verdien punten, wees de beste in je stad en bereik de top van de wereld.',
    Onboarding_Conquer: 'Verover',
    Onboarding_And_Become: 'En word',
    Onboarding_Legend: 'een legende',
    Onboarding_Conquer_Content: 'Speel instinctief. Speel om te winnen. Speel om de nummer één te worden.',
    Onboarding_Next: 'Volgende',
    Onboarding_Back: 'Terug',
    Onboarding_Lets_Ball: 'Laten we spelen',

    // HOME COMPONENT
    Home_Hello: 'Goede dag',
    Home_Street: 'Straat',
    Home_Culture: 'Cultuur',
    Home_Football: 'Voetbal',
    Home_Aka: 'AKA',
    Home_The_Biggest: 'DE GROOTSTE',
    Home_Sports_Community: 'iSPORTS GEMEENSCHAP',
    Home_Where: 'Waar de beste teams',
    Home_From: 'Van over de hele wereld',
    Home_Compete: 'Lokale en overzeese teams uitdagen',
    Home_Anywhere: 'Overal, altijd',
    Home_Hashtag: '#DifferentAnimalSameBeast',
    Home_New_Challenge: 'Nieuwe uitdaging',
    Home_Throne: 'pitches om te veroveren',
    Home_Find_Challenge: 'Vind een uitdaging',
    Home_Upcoming_Challenges: 'uitdagingen om aan te gaan',
    Home_Upcoming_Challenge: 'uitdaging om aan te gaan',
    Home_Find_Opponent: 'Daag een tegenstander uit',
    Home_Challengers: 'uitdagers ik wacht op jou',
    Home_Challenger: 'uitdager ik wacht op jou',
    Home_Build_Team: 'Bouw een team',
    Home_Become: 'Word een legende',
    Home_Join_Team: 'Word lid van een team',
    Home_Teams: 'teams (alleen goede vibes)',
    Home_Team: 'team (alleen goede vibes)',
    Home_Over: 'Meer dan',
    Home_New_Tournament: 'nieuw toernooi',
    Home_New_Tournaments: 'nieuwe toernooien',
    Home_Prove: 'Titels en reputatie, verdien ze!',
    Home_Upcoming_No_Challenges: 'Start de uitdaging eerst!',
    Home_Find_No_Opponent: 'Daag ze allemaal nu uit!',
    Home_Find_No_Tournament: 'Organiseer je eigen toernooi!',
    Home_View_Feeds: 'Lives, clips & nieuws',
    Home_Enjoy_Feeds: 'Bekijk wereldwijde wedstrijden',

    // PAYSPORTSESSIONMODAL COMPONENT
    PaySportSessionModal_Pay: 'Betalen',
    PaySportSessionModal_Title: 'Sport Sessie',
    PaySportSessionModal_Notification: 'Een bericht is naar je spelers gestuurd met het te betalen bedrag. Je ontvangt een melding voor elke gedane betaling. Je kunt ook voor iedereen betalen of een speler selecteren waarvoor je wilt betalen.',
    PaySportSessionModal_Squad: 'Selectie',
    PaySportSessionModal_Paid_By: 'Betaald door',
    PaySportSessionModal_Pay_For_Yourself: 'Betaal voor jezelf',
    PaySportSessionModal_Pay_For_Them: 'Betaal voor hen',
    PaySportSessionModal_Player_To_Pay: 'speler',
    PaySportSessionModal_Players_To_Pay: 'spelers',
    PaySportSessionModal_Total: 'Totaal',
    PaySportSessionModal_Price_per_Player: 'Prijs per speler',
    PaySportSessionModal_Proceed_Pay_Button: 'Betaling uitvoeren',
    PaySportSessionModal_Pay_Button: 'Betalen',
    PaySportSessionModal_Cancel_Button: 'Annuleren',
    PaySportSessionModal_Back_Button: 'Terug',
    PaySportSessionModal_Payment_Method: 'Betalingsmethode',
    PaySportSessionModal_Stepper_Back_Button: 'Terug',
    PaySportSessionModal_Stepper_Next_Button: 'Volgende',
    PaySportSessionModal_Stepper_Finish_Button: 'Voltooien',
    PaySportSessionModal_Snacks: 'Snacks',
    PaySportSessionModal_Per_Player: 'Per speler',
    PaySportSessionModal_Unpaid: 'Onbetaald',
    PaySportSessionModal_Paid: 'Betaald',
    PaySportSessionModal_Group_Snacks: 'Groepssnacks',
    PaySportSessionModal_Individual_Snacks: 'Individuele snacks',
    PaySportSessionModal_Unpaid_Group_Snacks: 'Onbetaalde groepssnacks',
    PaySportSessionModal_Fully_Paid_Group_Snacks: 'Groepssnacks volledig betaald',
    PaySportSessionModal_You_Paid: 'Je hebt betaald!',

    // SPENDINGCODEUPDATEMODAL COMPONENT
    SpendingCodeModal_Forgot_Spending_Code: 'Wachtwoord vergeten?',
    SpendingCodeModal_Email_Sent: 'E-mail verzonden!',
    SpendingCodeModal_Remember_Spending_Code: 'Herinner je je wachtwoord?',
    SpendingCodeModal_Identifier: 'Identificator',
    SpendingCodeModal_Email_Mobile: 'E-mail/Mobiel',
    SpendingCodeModal_Pin_Verification: 'PIN verificatie',
    SpendingCodeModal_Please_Wait: 'even geduld...',
    SpendingCodeModal_Sending_SMS: 'SMS verzenden...',
    SpendingCodeModal_Send_Via_Email: 'Verstuur PIN via e-mail',
    SpendingCodeModal_Send_Via_SMS: 'Verstuur PIN via SMS',
    SpendingCodeModal_Validation: 'Validatie',
    SpendingCodeModal_Is_Submitted: 'Je wachtwoord is bijgewerkt, probeer opnieuw in te loggen!',
    SpendingCodeModal_Is_Updated: 'Je uitgave wachtwoord is bijgewerkt!',
    SpendingCodeModal_New_Password: 'Nieuw wachtwoord',
    SpendingCodeModal_New_Password_Confirmation: 'Bevestiging nieuw wachtwoord',
    SpendingCodeModal_Reset_Password: 'Wachtwoord opnieuw instellen',
    SpendingCodeModal_Last_Step: 'Laatste stap',
    SpendingCodeModal_Login_Button: 'Inloggen',
    SpendingCodeModal_Back_Button: 'Terug',
    SpendingCodeModal_PIN_Verification_Button: 'PIN verificatie',
    SpendingCodeModal_Continue_Button: 'Doorgaan',

    // JOINCOMPLEXNETWORK COMPONENT
    JoinComplexNetwork_1: 'Revolutioneer je complex met Fivezer',
    JoinComplexNetwork_1_bis: 'Een wereldwijde speeltuin voor kampioenen!',
    JoinComplexNetwork_2: 'Oproep aan alle complex eigenaren – Fivezer is hier om je sportwereld te transformeren! Maak kennis met de volgende generatie Five League die de beste teams ter wereld naar je toe brengt, met dag en nacht spannende wedstrijden, waar je ook bent!',
    JoinComplexNetwork_3: 'Ontketen de kracht van geavanceerde ioT-tools – van slimme armbanden tot magische ballen – en zie in verwondering hoe de prestaties en verbluffende statistieken van je spelers naar nieuwe hoogten stijgen!',
    JoinComplexNetwork_4: 'Stap in de schijnwerpers, verblind de wereld en maak van je complex het epicentrum van sportexellentie. Omarm roem, glorie en talloze kansen terwijl sponsors, fans en de media zich verdringen om getuige te zijn van de geboorte van kampioenen!',
    JoinComplexNetwork_5: 'De tijd is nu. Het spel is Fivezer. Bereid je voor om de regels van de sport zoals je die kent te herschrijven. Sluit je aan bij de elite league waar grootsheid geen grenzen kent!',
    JoinComplexNetwork_6: 'Fivezer – Het voeden van de dromen van kampioenen, één complex tegelijk.',
    JoinComplexNetwork_6_bis: 'Omarm de toekomst. Verhef je nalatenschap!',

    // SENDRECOMMENDATIONMODAL COMPONENT
    SendRecommendationModal_Tell_Them: 'Laat ze weten over',
    SendRecommendationModal_Do_You_Want: 'Wil je dat je favoriete vijf meedoen met Fivezer?',
    SendRecommendationModal_We_Add_Them: 'Vertel het de eigenaar, zodat we ze kunnen toevoegen aan onze lijst met beschikbare velden.',
    SendRecommendationModal_Send_Button: 'Versturen',
    SendRecommendationModal_Email_Button: 'E-mail',
    SendRecommendationModal_Press_Enter: 'Druk op enter om meer toe te voegen',

    // TOURNAMENT COMPONENT
    Tournament_Finale_128: 'Ronde van 128',
    Tournament_Finale_64: 'Ronde van 64',
    Tournament_Finale_32: 'Ronde van 32',
    Tournament_Finale_16: 'Ronde van 16',
    Tournament_Finale_8: 'Kwartfinales',
    Tournament_Finale_4: 'Halve finales',
    Tournament_Finale_2: 'Finale',
    Tournament_By: 'georganiseerd door',

    // AVAILABILITIES COMPONENT
    InputSelectAvailabilitiesFeature_Title_1: 'Kies tijd beschikbaarheid',
    InputSelectAvailabilitiesFeature_Title_2: 'Je kunt je beschikbaarheden later bijwerken',
    InputSelectAvailabilitiesFeature_Title_3: 'beschikbaarheden',
    InputSelectAvailabilitiesFeature_Title_4: 'Geen beschikbaarheden',
    InputSelectAvailabilitiesFeature_Title_5: 'Wijzigen',
    InputSelectAvailabilitiesFeature_Title_6: 'beschikbaarheden',
    InputSelectAvailabilitiesFeature_Day_1: 'Maandag',
    InputSelectAvailabilitiesFeature_Day_2: 'Dinsdag',
    InputSelectAvailabilitiesFeature_Day_3: 'Woensdag',
    InputSelectAvailabilitiesFeature_Day_4: 'Donderdag',
    InputSelectAvailabilitiesFeature_Day_5: 'Vrijdag',
    InputSelectAvailabilitiesFeature_Day_6: 'Zaterdag',
    InputSelectAvailabilitiesFeature_Day_7: 'Zondag',

    // FORMNEWTEAM COMPONENT
    FormNewTeam_Title: 'Nieuw Team',
    FormNewTeam_Create_Profile: 'Voetballer profiel aanmaken',
    FormNewTeam_Sport_Type_Title: 'Sport',
    FormNewTeam_Sport_Type_Coming: 'Binnenkort beschikbaar',
    FormNewTeam_Category_Title: 'Categorie',
    FormNewTeam_Category_Subtitle_1: 'Alleen COMPETITIE teams kunnen punten verzamelen en gerangschikt worden',
    FormNewTeam_Category_Subtitle_2: 'Je hebt al',
    FormNewTeam_Category_Subtitle_3: 'Je speelt al voor',
    FormNewTeam_Category_Subtitle_4: 'in de competitie',
    FormNewTeam_Category_Subtitle_5: 'Je hebt al',
    FormNewTeam_Category_Subtitle_6: 'in de competitie',
    FormNewTeam_Name_Title: 'Naam van het team',
    FormNewTeam_Name_Placeholder: 'Paris Saint Germain',
    FormNewTeam_Name_1: 'Naam moet minstens 4 tekens bevatten',
    FormNewTeam_Name_2: 'Je mag ALLEEN letters, cijfers of underscores gebruiken',
    FormNewTeam_Name_3: 'is al bezet',
    FormNewTeam_Shortname_Title: 'Korte versie (3 letters)',
    FormNewTeam_Shortname_Placeholder: 'PSG',
    FormNewTeam_Shortname_1: 'Naam moet minstens 3 tekens bevatten',
    FormNewTeam_Shortname_2: 'Je mag ALLEEN letters, cijfers of underscores gebruiken',
    FormNewTeam_Privacy_Title: 'Privacy?',
    FormNewTeam_Privacy_1: 'Ja, het is een privéteam 🤐',
    FormNewTeam_Privacy_2: 'Nee, het is een openbaar team 🤗',
    FormNewTeam_Bio_Title: 'Biografie (meertalig)',
    FormNewTeam_Area_Title: 'Welke regio vertegenwoordig je?',
    FormNewTeam_Area_1: 'Naam van de regio',
    FormNewTeam_Area_2: 'São Paulo',
    FormNewTeam_Button_Create: 'Team aanmaken',
    FormNewTeam_Create_Profile_Label: 'Je moet minstens een voetballer profiel hebben om een team aan te maken',

    // FORMEDITTEAM COMPONENT
    FormEditTeam_Title: 'Team bewerken',
    FormEditTeam_See_Profile: 'Bekijk profiel',
    FormEditTeam_Sport_Type_Title: 'Sport',
    FormEditTeam_Sport_Type_Coming: 'Binnenkort beschikbaar',
    FormEditTeam_Name_Title: 'Naam van het team',
    FormEditTeam_Name_Placeholder: 'Paris Saint Germain',
    FormEditTeam_Name_1: 'Naam moet minstens 4 tekens bevatten',
    FormEditTeam_Name_2: 'Je mag ALLEEN letters, cijfers of underscores gebruiken',
    FormEditTeam_Name_3: 'is al bezet',
    FormEditTeam_Shortname_Title: 'Korte versie (3 letters)',
    FormEditTeam_Shortname_Placeholder: 'PSG',
    FormEditTeam_Shortname_1: 'Naam moet minstens 3 tekens bevatten',
    FormEditTeam_Shortname_2: 'Je mag ALLEEN letters, cijfers of underscores gebruiken',
    FormEditTeam_Privacy_Title: 'Privacy?',
    FormEditTeam_Privacy_1: 'Ja, het is een privéteam 🤐',
    FormEditTeam_Privacy_2: 'Nee, het is een openbaar team 🤗',
    FormEditTeam_Bio_Title: 'Biografie (meertalig)',
    FormEditTeam_Area_Title: 'Welke regio vertegenwoordig je?',
    FormEditTeam_Area_1: 'Naam van de regio',
    FormEditTeam_Area_2: 'São Paulo',
    FormEditTeam_View_Inactive_1: 'Bekijk',
    FormEditTeam_View_Inactive_2: 'verleden',
    FormEditTeam_View_Inactive_3: 'spelers',
    FormEditTeam_View_Inactive_4: 'speler',

    // FOOTBALLTEAM COMPONENT
    FootballTeam_Error_1: 'Sorry, maar het lijkt erop dat we dit team niet kunnen vinden',
    FootballTeam_Alert_1: 'Je team heeft minstens één kapitein nodig, anders kun je geen wedstrijden spelen',
    FootballTeam_Alert_2: 'Je team heeft minstens één speler en één kapitein nodig, anders kun je geen wedstrijden spelen',
    FootballTeam_Button_Edit: 'Team bewerken',
    FootballTeam_Squad_Title: 'Selectie',
    FootballTeam_Remove_Captain: 'verwijder kapitein',
    FootballTeam_Promote_Captain: 'Promoveer kapitein',
    FootballTeam_Choose_Number: 'Kies nummer',
    FootballTeam_Next_Challenges_Title: 'Volgende uitdagingen',
    FootballTeam_No_Challenges: 'Nog geen uitdagingen',
    FootballTeam_Start_New_Challenge: 'Start er nu een',
    FootballTeam_Last_Challenges: 'Laatste uitdagingen',
    FootballTeam_No_Players: 'Nog geen spelers',
    FootballTeam_Add_them: 'Voeg ze nu toe',
    FootballTeam_No_Lineups: 'Nog geen opstellingen',
    FootballTeam_Create_Lineup: 'Maak er nu een aan',
    FootballTeam_Managers_Title: 'Managers',
    FootballTeam_Start_New_Manager_Quote: `aka Voetbal Manager ${new Date().getFullYear()}`,
    FootballTeam_New_Player_Quote: 'Carrière Modus',

    // PROFILE COMPONENT
    Profile_Share_1: 'Profiel delen',
    Profile_Share_2: 'Fivezer profiel',
    Profile_Share_3: 'Vind',
    Profile_Share_4: "'s Fivezer profiel",
    Profile_Button_Edit: 'Profiel bewerken',
    Profile_Button_New_Footballer: 'Nieuwe Voetballer',
    Profile_Button_New_Team: 'Nieuw Team',
    Profile_Button_New_Field: 'Nieuw Veld',
    Profile_Button_New_Tournament: 'Nieuw toernooi',
    Profile_Button_Settings: 'Instellingen',
    Profile_View_Card_1: 'Bekijk',
    Profile_View_Card_2: 'FZ kaart',
    Profile_Tab_Overview: 'Overzicht',
    Profile_Tab_Team: 'Teams',
    Profile_Tab_Calendar: 'Kalender',
    Profile_Tab_Statistics: 'Statistieken',
    Profile_Tab_History: 'Overdrachten',
    Profile_Tab_Fields: 'Gepubliceerde velden',
    Profile_Tab_Flex: 'Flexs',
    Profile_Create_Team_1: 'Om een team aan te maken moet je je e-mail verifiëren',
    Profile_Create_Team_2: 'Maak je team aan',
    Profile_Pick_Profile: 'Kies een profiel',
    Profile_No_Teams: 'Geen teams',
    Profile_Missing_Profile: 'heeft nog geen sportprofiel',

    // FOOTBALLCARD COMPONENT
    FootballCard_Button_Share: 'Deel kaart',
    FootballCard_Button_Back: 'Terug',
    FootballCard_Button_Jubilee: 'Maak je Jubileum',
    FootballCard_Button_Edit: 'Voetbalprofiel bewerken',
    FootballCard_Button_Share_Profile_1: 'Profiel delen',
    FootballCard_Profile_Status: 'Profiel is',

    // FORMNEWTOURNAMENT COMPONENT
    FormNewTournament_Title: 'Nieuw toernooi',
    FormNewTournament_Name: 'Naam van het toernooi',
    FormNewTournament_Name_Placeholder: 'Mamba 5 Toernooi',
    FormNewTournament_Name_Helper_1: 'De naam moet minstens 4 tekens bevatten',
    FormNewTournament_Name_Helper_2: 'Je mag ALLEEN letters, cijfers of underscores gebruiken',
    FormNewTournament_Sport_Type_Coming: 'Binnenkort beschikbaar',
    FormNewTournament_Descriptions: 'Beschrijving',
    FormNewTournament_Cost_Title: 'Kosten per team',
    FormNewTournament_Cost_Value: 'Kosten',
    FormNewTournament_Cost_Currency: 'Valuta',
    FormNewTournament_Trophy: 'Trofee',
    FormNewTournament_Trophies: 'Trofeeën',
    FormNewTournament_Privacy_Title: 'Privacy?',
    FormNewTournament_Privacy_Content_1: 'Ja, het is een privé evenement 🤐',
    FormNewTournament_Privacy_Content_2: 'Nee, het is open voor het publiek 🤗',
    FormNewTournament_Organisator_Title: 'Hoofdorganisator',
    FormNewTournament_Organisator_Label: 'Hoofdorganisator',
    FormNewTournament_Organisator_Placeholder: 'Organisator...',
    FormNewTournament_Organisators_Title: 'Organisatoren',
    FormNewTournament_Organisators_Label: 'Organisatoren',
    FormNewTournament_Organisators_Placeholder: 'Organisatoren...',
    FormNewTournament_Managers_Title: 'Managers',
    FormNewTournament_Managers_Label: 'Managers',
    FormNewTournament_Managers_Placeholder: 'Managers...',
    FormNewTournament_Settings_Title: 'Toernooi-instellingen',
    FormNewTournament_Settings_Groups_1: 'Ja, het heeft een groepsfase 🤐',
    FormNewTournament_Settings_Groups_2: 'Nee, het heeft geen groepsfase 🤗',
    FormNewTournament_Settings_Playoff_Label: 'Playoff-fase',
    FormNewTournament_Settings_Playoff_Content_1: 'Je toernooi zal hebben',
    FormNewTournament_Settings_Playoff_Content_2: 'teams',
    FormNewTournament_Settings_Playoff_Content_3: 'met',
    FormNewTournament_Settings_Playoff_Content_4: 'groepen van 4 teams waar de eerste 2 teams zich kwalificeren voor de eindfase.',
    FormNewTournament_Dates_Title: 'Data',
    FormNewTournament_Location_Title: 'Hoofdlocatie',
    FormNewTournament_Button_New_Tournament: 'Toernooi Aanmaken',
    // FORMEDITTOURNAMENT
    FormEditTournament_Title: 'Toernooi Bewerken',
    // TOURNAMENTS COMPONENT
    Tournaments_No_Tournaments_Found: 'Geen toernooien gevonden, probeer het opnieuw',
    Tournaments_No_Tournament_In: 'Geen toernooien in',
    Tournaments_No_Tournament_Yet: 'nog',
    Tournaments_Meta_Title: 'Toernooien',
    Tournaments_Candidate: 'kandidaat',
    Tournaments_Candidates: 'kandidaten',

    // TOURNAMENT COMPONENT
    Tournament_Meta_Title: 'Toernooi',
    Tournament_Not_Found: 'Toernooi niet gevonden',
    Tournament_Not_Found_Caption: 'Het spijt ons, maar het lijkt erop dat dit toernooi niet beschikbaar is. Controleer de spelling van de naam.',
    Tournament_Button_Edit: 'Toernooi Bewerken',
    Tournament_Button_New_Sponsorship: 'Nieuwe sponsoring',
    Tournament_Team_Candidates: 'Teamkandidaten',
    Tournament_Team_Selected: 'Geselecteerd team',
    Tournament_Register_Unregister: 'Registreer/Deregistreer je team',
    Tournament_Select_Unselect: 'Selecteer/Deselecteer een team',
    Tournament_Validate_Teams: 'Valideer teamselectie',
    Tournament_Confirm_Teams: 'Bevestig je de geselecteerde teams?',
    Tournament_Confirm_Teams_Yes: 'Ja',
    Tournament_Confirm_Teams_No: 'Nee',
    Tournament_Kick_Off_Group_Title: 'Start groepsfase toernooi',
    Tournament_Kick_Off_Group_Caption_1: 'Bevestig je de start van de groepsfase van je toernooi?',
    Tournament_Kick_Off_Group_Caption_2: 'Deze actie is onomkeerbaar, zorg ervoor dat je ALLE WEDSTRIJDEN hebt ingepland',
    Tournament_Kick_Off_Group_Button_Go: 'Laten we gaan',
    Tournament_Kick_Off_Group_Button_Wait: 'Nee, wacht',
    Tournament_Kick_Off_Finals_Title: 'Start finale wedstrijden toernooi',
    Tournament_Kick_Off_Finals_Caption_1: 'Bevestig je de start van de finale wedstrijden van je toernooi?',
    Tournament_Kick_Off_Finals_Caption_2: 'Deze actie is onomkeerbaar, zorg ervoor dat je ALLE WEDSTRIJDEN SCORES hebt toegevoegd',
    Tournament_Kick_Off_Finals_Button_Go: 'Laten we gaan',
    Tournament_Kick_Off_Finals_Button_Wait: 'Nee, wacht',
    Tournament_Cost_Per_Team: 'Team',
    Tournament_Trophies_Title: 'Trofeeën',
    Tournament_Trophies_Assign: 'Toewijzen trofee',
    Tournament_Overview_Tab: 'Overzicht',
    Tournament_Boards_Tab: 'Borden',
    Tournament_Sponsors_Tab: 'Sponsors',
    Tournament_Sponsor_For: 'Sponsor voor',
    Tournament_Button_Schedule_Game: 'Plan een wedstrijd',
    Tournament_Button_No_Schedule_Game: 'Geen wedstrijd gepland',

    ViewTournamentPlayoffGameModal_Button_Add_Score: 'Voeg eindscore toe',
    ViewTournamentPlayoffGameModal_Score_Added: 'Score toegevoegd',
    ViewTournamentPlayoffGameModal_Game_Result: 'Wedstrijdresultaten',
    ViewTournamentPlayoffGameModal_Private: 'Dit is een privé-evenement',

    // TOURNAMENTPLAYOFF COMPONENT
    TournamentPlayoff_Click_Schedule: 'klik om te plannen',
    TournamentPlayoff_Team: 'Team',
    TournamentPlayoff_Match: 'Wedstrijd',
    // TOURNAMENTGROUP COMPONENT
    Tournament_Groups_No_Games_Yet: 'Nog geen wedstrijden ingepland',
    Tournament_Groups_No_Teams: 'Geen teams',
    Tournament_Groups_Button_Schedule: 'Plan wedstrijd',
    Tournament_Groups_Button_Add_Team: 'Team toevoegen',
    Tournament_Groups_Games: 's wedstrijden',

    // INPUTTROPHIESFEATURE COMPONENT
    InputTrophiesFeature_Rank_Label: 'Trofee rang',
    InputTrophiesFeature_Name_Label: 'Naam van de trofee',
    InputTrophiesFeature_Name_Placeholder: '1e plaats van FZ Wereld Toernooi',
    InputTrophiesFeature_Name_Helper_1: 'De naam moet minstens 4 tekens bevatten',
    InputTrophiesFeature_Name_Helper_2: 'Je mag ALLEEN letters, cijfers of underscores gebruiken',
    InputTrophiesFeature_Note_Label: 'Opmerking trofee',
    InputTrophiesFeature_Note_Placeholder: 'Een trofee gegeven door sponsors X Y Z',
    InputTrophiesFeature_Add_Trophy: 'Trofee toevoegen',
    // GPSMAPFEATURE COMPONENT
    GPSMapFeature_1: 'Lokaliseer het bovenstaande adres op de kaart',
    GPSMapFeature_2: 'Klik op de marker om deze te verplaatsen en wees zo nauwkeurig mogelijk!',
    // CHALLENGEFILTERSMODAL COMPONENT
    ChallengeFiltersModal_When_Title: 'Wanneer',
    ChallengeFiltersModal_Where_Title: 'Waar',
    ChallengeFiltersModal_No_Complex_Selected: 'Geen complex geselecteerd',
    ChallengeFiltersModal_Field_Label: 'Veld',
    ChallengeFiltersModal_Search_Complex_Label: 'Zoek Complex',
    ChallengeFiltersModal_Search_Complex_Placeholder: 'Schrijf een complex naam',
    ChallengeFiltersModal_Button_Results: 'Bekijk resultaten',
    // SETTINGS COMPONENT
    Settings_Title: 'Instellingen',
    Settings_Account: 'Account',
    Settings_Wallets: 'Portefeuilles',
    Settings_Languages: 'Talen',
    Settings_Helpdesk: 'Helpdesk',
    Settings_Legal_Mentions: 'Juridische vermeldingen',
    Settings_Logout: 'Uitloggen',
    Settings_Logging_Out: 'Uitloggen...',
    Settings_Blocked_Accounts: 'Geblokkeerde accounts',
    Settings_Accounts_Title: 'Account',
    Settings_Contact_Us_Title: 'Contacteer ons',
    Settings_Contact_Us_Content: `
        Bij Fivezer staan we voor je klaar om je te helpen met vragen, zorgen of feedback met betrekking tot ons sportcommunityplatform. Je kunt contact met ons opnemen via e-mail en ons team zal snel reageren op je vragen.

        Klantenondersteuning E-mail:
        Voor klantenondersteuning, inclusief accountgerelateerde problemen, technische assistentie of algemene vragen, kunt u ons e-mailen op:
        E-mail: contact@thebhub.io
        
        Zakelijke vragen E-mail:
        Als u zakelijke vragen heeft, partnerschapsmogelijkheden of samenwerkingsvoorstellen met betrekking tot Fivezer, kunt u contact opnemen met ons business development team op:
        E-mail: contact@thebhub.io
        
        Algemene vragen E-mail:
        Voor algemene vragen over Fivezer, ons platform of om te beginnen als sportliefhebber of team, kunt u ons algemene vragen team e-mailen op:
        E-mail: contact@thebhub.io
        
        Feedback en suggesties E-mail:
        We waarderen uw feedback en suggesties voor het verbeteren van de Fivezer-ervaring. Als je ideeën of aanbevelingen hebt, deel deze dan met ons op:
        E-mail: contact@thebhub.io
        
        Reactietijd:
        We streven ernaar om uw e-mails zo snel mogelijk te beantwoorden. Onze toegewijde teams staan klaar om u de ondersteuning en informatie te bieden die u nodig heeft om het meeste uit uw Fivezer-ervaring te halen.
        
        Bedankt dat u Fivezer hebt gekozen als uw go-to bestemming in de wereld van internationale sport en competitie. We kijken ernaar uit om u via e-mail te helpen.
      `,
    Settings_Legal_Mentions_Title: 'Juridische vermeldingen',

    // FORMNEWFOOTBALLER
    FormNewFootballer_Title: 'Nieuwe Voetballer',
    FormNewFootballer_Existing_Footballer: 'Je hebt al een voetballerprofiel',
    FormNewFootballer_See_Existing_Footballer: 'Bekijk profiel',
    FormNewFootballer_Alias_Label: 'Voetballersnaam',
    FormNewFootballer_Alias_Placeholder: 'Pelé',
    FormNewFootballer_Nickname_Label: 'Bijnaam',
    FormNewFootballer_Nickname_Placeholder: 'O Rei',
    FormNewFootballer_Bio_Title: 'Bio (meertalig)',
    FormNewFootballer_Which_Area: 'Welke regio vertegenwoordig je?',
    FormNewFootballer_Area_Title_Label: 'Naam van de regio',
    FormNewFootballer_Area_Title_Placeholder: 'São Paulo',
    FormNewFootballer_Position_Title: 'Positie',
    FormNewFootballer_Jersey_Title: 'Rugnummers',
    FormNewFootballer_Foots_Title: 'Voorkeursvoet',
    FormNewFootballer_Button_Create: 'Voetballer Aanmaken',
    // FORMEDITFOOTBALLER
    FormEditFootballer_Title: 'Voetballer Bewerken',
    // FORMEDITPROFILE
    FormEditProfile_Title: 'Profiel Bewerken',
    FormEditProfile_Basic_Title: 'Basisinformatie',
    FormEditProfile_Username_Label: 'Gebruikersnaam',
    FormEditProfile_Username_1: 'is al in gebruik',
    FormEditProfile_Username_2: 'Alleen letters, cijfers en _ zijn toegestaan',
    FormEditProfile_Email_Label: 'E-mail',
    FormEditProfile_Email_1: 'is al in gebruik bij een ander account',
    FormEditProfile_Firstname_Label: 'Voornaam',
    FormEditProfile_Lastname_Label: 'Achternaam',
    FormEditProfile_DOB_Label: 'Geboortedatum',
    FormEditProfile_Bio_Label: 'Bio',
    FormEditProfile_Bio_1: 'Je moet verwijderen',
    FormEditProfile_Bio_2: 'karakters',
    FormEditProfile_Bio_3: 'karakter',
    FormEditProfile_Mobile_Label: 'Mobiel',
    FormEditProfile_Mobile_1: 'Verifieer PIN-code',
    FormEditProfile_Mobile_2: 'Mobiel succesvol geverifieerd!',
    FormEditProfile_Passwords_Title: 'Wachtwoorden',
    FormEditProfile_Passwords_Label: 'Nieuw Wachtwoord',
    FormEditProfile_Passwords_1: 'Schrijf een nieuw accountwachtwoord',
    FormEditProfile_Passwords_2: 'Nieuw bestedingswachtwoord',
    FormEditProfile_Passwords_3: 'Bestedingswachtwoord bijgewerkt!',
    FormEditProfile_KYC_Title: 'Persoonlijke Identiteitsverificatie',
    FormEditProfile_ID_Type_Label: 'Type ID',
    FormEditProfile_ID_Number_Label: 'ID-nummer',
    FormEditProfile_New_KYC_Title: 'Een nieuwe ID-verificatie doen?',
    FormEditProfile_New_KYC_Label_1: 'Ja!',
    FormEditProfile_New_KYC_Label_2: 'Nee!',
    FormEditProfile_New_KYC_Exp_Label: 'Vervaldatum ID',
    FormEditProfile_New_KYC_Issuer_Country_Label: 'Land van uitgifte ID',
    FormEditProfile_New_KYC_Issuer_Label: 'Uitgevende instantie',
    FormEditProfile_New_KYC_Issuer_Placeholder: 'Vul de naam van de uitgevende instantie in',
    FormEditProfile_Preferenced_Sport_Title: 'Voorkeursporten',
    FormEditProfile_Preferenced_Sport_Label: 'Sporten',
    FormEditProfile_Preferenced_Sport_1: 'Geen sport geselecteerd',
    FormEditProfile_Locations_Title: 'Locaties',
    FormEditProfile_Button_Add_Location: 'Adres toevoegen',
    // TOURNAMENTS
    Tournaments_New_Tournaments_Tab: 'Aankomende toernooien',
    Tournaments_Old_Tournaments_Tab: 'Afgelopen toernooien',

    // FORMNEWCHALLENGE COMPONENT
    FormNewChallenge_Title: 'Nieuwe uitdaging',
    FormNewChallenge_No_Team_Caption_1: 'Vriendschappelijk of niet, ALLEEN teamcaptains kunnen een uitdaging aanmaken.',
    FormNewChallenge_No_Team_Caption_2: 'Promoveer een captain in je team en kom terug',
    FormNewChallenge_No_Team_Caption_3: 'Maak je geen zorgen, zelfs als je alleen bent, er zijn genoeg spelers om met je mee te doen!',
    FormNewChallenge_No_Team_Button_New_Team: 'Maak je team nu aan',
    FormNewChallenge_Challenger_Label_1: 'Als',
    FormNewChallenge_Challenger_Label_2: 'wie?',
    FormNewChallenge_Challenger_Placeholder: 'Selecteer je team',
    FormNewChallenge_Category_Title: 'Categorie',
    FormNewChallenge_Category_Caption_League: 'Een competitieve wedstrijd is simpel, jij tegen hen, winnaars krijgen de punten en verliezers worden gepoort',
    FormNewChallenge_Category_Caption_Friendly: 'Een vriendschappelijke wedstrijd is gewoon een spelletje voor de lol, geen druk, geen punten, gewoon plezier en poorten',
    FormNewChallenge_Category_Caption_Matchmaker: 'Matchmaker is een vriendschappelijke wedstrijd die huurlingen oproept om je teams te vullen en samen te spelen, een leuke manier om nieuwe vrienden te maken',
    FormNewChallenge_Name_Label: 'Naam van de uitdaging',
    FormNewChallenge_Name_Placeholder: 'Je team',
    FormNewChallenge_Name_1: 'Naam moet minstens 4 tekens bevatten',
    FormNewChallenge_Name_2: 'Gebruik ALLEEN letters, cijfers of underscores',
    FormNewChallenge_Your_Lineup_Title: 'Je opstelling',
    FormNewChallenge_Your_Lineup_Label_1: 'Maak een nieuwe opstelling',
    FormNewChallenge_Your_Lineup_Label_2: 'Selecteer een bestaande opstelling',
    FormNewChallenge_Team_Selected: 'Geen teamgenoot geselecteerd',
    FormNewChallenge_New_Lineup_Label: 'Stel je nieuwe opstelling samen',
    FormNewChallenge_New_Lineup_Placeholder: 'Schrijf een teamalias',
    FormNewChallenge_Edit_Lineup_Label: 'Bestaande opstellingen',
    FormNewChallenge_Edit_Lineup_Placeholder: 'Selecteer een veld',
    FormNewChallenge_Against_Title: 'Tegen ?',
    FormNewChallenge_Against_Label: 'Geen tegenstander geselecteerd',
    FormNewChallenge_Opponent_Title: 'Tegenstander',
    FormNewChallenge_Opponent_Placeholder: 'Schrijf een teamnaam of ID',
    FormNewChallenge_Privacy_Title: 'Privacy ?',
    FormNewChallenge_Privacy_Caption_1: 'Ja, het is privé 🤐',
    FormNewChallenge_Privacy_Caption_2: 'Nee, het is open voor het publiek 🤗',
    FormNewChallenge_How_Long_Title: 'Hoe lang ?',
    FormNewChallenge_How_Long_Hour: 'uur',
    FormNewChallenge_How_Long_Hours: 'uren',
    FormNewChallenge_When_Title: 'Wanneer ?',
    FormNewChallenge_Recurring_Label_1: 'Plan het meerdere keren 🖐',
    FormNewChallenge_Recurring_Label_2: 'Plan het een keer 👆',
    FormNewChallenge_Recurring_Label_3: 'Elke',
    FormNewChallenge_Start_Date_1: 'Eerste dag (start)',
    FormNewChallenge_Start_Date_2: 'Startdatum',
    FormNewChallenge_End_Date_1: 'Eerste dag (einde)',
    FormNewChallenge_End_Date_2: 'Einddatum',
    FormNewChallenge_Last_Day: 'Laatste dag',
    FormNewChallenge_Last_Day_Label_1: 'Je wilt spelen',
    FormNewChallenge_Last_Day_Label_2: 'keer',
    FormNewChallenge_Last_Day_Label_3: 'keer',
    FormNewChallenge_Last_Day_Label_4: 'per week tot',
    FormNewChallenge_Where_Title: 'Waar ?',
    FormNewChallenge_Complexes_Label: 'Zoek Complex',
    FormNewChallenge_Complexes_Placeholder: 'Schrijf een complexnaam',
    FormNewChallenge_Complexes_No_Selected: 'Geen complex geselecteerd',
    FormNewChallenge_Field_Label: 'Veld',
    FormNewChallenge_Field_Placeholder: 'Selecteer een veld',
    FormNewChallenge_Fields_Already_Booked: 'AL GERESERVEERD',
    FormNewChallenge_Fields_Current_Booking: 'HUIDIGE BOEKING',
    FormNewChallenge_Fields_Hour: 'uur',
    FormNewChallenge_Fields_Free: 'VRIJ',
    FormNewChallenge_Fields_Manual_Booking: 'Handmatige boeking',
    FormNewChallenge_Fields_Manual_Booking_Caption: 'is niet geregistreerd op Fivezer, dus je moet ze bellen om je sessie te boeken.',
    FormNewChallenge_Button_Create: 'Creëer uitdaging',
    // FORMEDITCHALLENGE COMPONENT
    FormEditChallenge_Title: 'Uitdaging bewerken',
    // CHALLENGE COMPONENT
    Challenge_Meta_Title: 'Uitdaging',
    Challenge_Payment_Caption_1: 'Je hebt je plek in deze uitdaging nog niet betaald',
    Challenge_Button_Accept: 'Accepteren',
    Challenge_Button_Decline: 'Afwijzen',
    Challenge_Accept_Challenge: 'Accepteer je deze uitdaging?',
    Challenge_Button_Edit: 'Uitdaging bewerken',
    Challenge_Private_Event: 'Dit is een privé-evenement',
    Challenge_Button_Results: 'Wedstrijdresultaten',
    Challenge_Group_Order_Label: 'Groepsvolgorde',
    Challenge_Button_Retry: 'herhaal betaling',
    Challenge_Personal_Order_Label: 'Persoonlijke volgorde',
    Challenge_Partcially_Paid_1: 'Wacht op bevestiging van de tegenstander',
    Challenge_Partcially_Paid_2: 'Voeg eindscore toe',
    Challenge_Button_Rematch: 'Herkansing',
    // CHALLENGES COMPONENT
    Challenges_Meta_Title: 'Uitdagingen',
    Challenges_Tab_Challenges: 'Uitdagingen',
    Challenges_Tab_Opponents: 'Tegenstanders',
    Challenges_No_Challenges_Found: 'Geen uitdagingen gevonden, probeer het opnieuw',
    Challenges_No_Challenges_In: 'Geen uitdagingen in',
    Challenges_No_Challenges_Yet: 'nog',
    // FIELD COMPONENT
    Field_Meta_Title: 'Veld',
    Field_Not_Found: 'Veld niet gevonden',
    Field_Not_Found_Caption: 'Sorry, het lijkt erop dat dit veld niet beschikbaar is, controleer de spelling van de naam.',
    Field_Button_Edit: 'Veld bewerken',
    Field_Sport_Type_Title: 'Sport',
    Field_Type_Label: 'Type',
    Field_Size_Label: 'Grootte',
    Field_Cost_Label: 'Kosten',
    Field_Equipments_Title: 'Uitrusting',
    Field_No_Equipments: 'Geen uitrusting',
    Field_No_Pictures: "Geen foto's",
    // FORMNEWFIELD MODAL
    FormNewField_Title: 'Nieuw veld',
    FormNewField_Location: 'Locatie',
    FormNewField_Descriptions: 'Beschrijvingen (meertalig)',
    FormNewField_Button_Add_Descriptions: 'Beschrijving toevoegen',
    FormNewField_No_Description: 'Geen beschrijving',
    FormNewField_Pictures_Title: "Foto's",
    FormNewField_Name_Label: 'Naam',
    FormNewField_Name_Placeholder: 'Schrijf de naam van het veld',
    FormNewField_Button_Create: 'Veld toevoegen',
    // FORMEDITFIELD MODAL
    FormEditField_Title: 'Veld bewerken',
    // REQUESTMATCHMAKING COMPONENT
    RequestMatchMaking_Meta_Title: 'Matchmaking',
    RequestMatchMaking_Not_Found_1: 'Matchmaking',
    RequestMatchMaking_Not_Found_2: 'niet gevonden',
    RequestMatchMaking_Not_Found_3: 'Sorry, het lijkt erop dat dit verzoek niet beschikbaar is, controleer de spelling van de naam of gebruik onze',
    RequestMatchMaking_Not_Found_4: 'zoek',
    RequestMatchMaking_Not_Found_5: 'machine.',
    RequestMatchMaking_Type_Label: 'Type',
    RequestMatchMaking_Button_Leave_Game: 'Verlaat spel',
    RequestMatchMaking_Button_Join_Game: 'Deelnemen aan spel',
    RequestMatchMaking_Players: 'spelers',
    RequestMatchMaking_Player: 'speler',
    RequestMatchMaking_Joined_Players: 'zijn toegetreden',
    RequestMatchMaking_Joined_Player: 'is teruggekeerd',
    // MANAGESCORE MODAL
    ManageScore_Title: 'Eindresultaten',
    ManageScore_Intro_1: 'Nu je uitdaging voorbij is, kun je de scores en ook de statistieken van je spelers toevoegen.',
    ManageScore_Intro_2: 'Ter herinnering, beide captains moeten de score bevestigen om de statistieken te valideren.',
    ManageScore_BG_Title_1: 'Eind',
    ManageScore_BG_Title_2: 'Resultaten',
    ManageScore_Button_Add_Scores: 'Scores toevoegen',
    ManageScore_Button_Unconfirm: 'Deconfirmeren',
    ManageScore_Button_Confirm: 'Eindscore bevestigen',
    ManageScore_Button_Save_Continue: 'Opslaan en later doorgaan',
    ManageScore_Button_Continue: 'Doorgaan',
    ManageScore_Confirm_Score_Content: 'Bevestig je het eindresultaat van de uitdaging?',
    ManageScore_Confirm_Score_Button_Yes: 'Ja',
    ManageScore_Confirm_Score_Button_No: 'Nee',
    ManageScore_Added_Score_Info_1: 'Je hebt alle goals en assists van',
    ManageScore_Added_Score_Info_2: 'Je kunt nu de resultaten bevestigen',
    ManageScore_Added_Score_Info_3: 'of',
    ManageScore_Added_Score_Info_4: 'je kunt opslaan en later doorgaan.',
    ManageScore_Scorer_Label: 'Scorer',
    ManageScore_NB_Scorer_Label_1: 'Goals gescoord door',
    ManageScore_NB_Scorer_Label_2: 'speler',
    ManageScore_Selected_Passer_Label_1: 'Een speler',
    ManageScore_Selected_Passer_Label_2: 'goals',
    ManageScore_Selected_Passer_Label_3: 'goal',
    ManageScore_Selected_Passer_Label_4: 'ontvangen',
    ManageScore_Selected_Passer_Label_5: 'assists',
    ManageScore_Selected_Passer_Label_6: 'assist',
    ManageScore_Selected_Scorer_Caption_1: 'Je kunt alle assists',
    ManageScore_Selected_Scorer_Caption_2: 'een speler',
    ManageScore_Selected_Scorer_Caption_3: 'ontvangen',
    ManageScore_Selected_Scorer_Label: 'Passer',
    ManageScore_NB_Assist_Label_1: 'Assists door',
    ManageScore_NB_Assist_Label_2: 'speler',
    ManageScore_Button_Confirm_Scorer: 'Scorer bevestigen',
    ManageScore_Button_Add_Assist: 'Assist toevoegen',
    ManageScore_MOTM_Caption_1: 'Mannen van de wedstrijd',
    ManageScore_MOTM_Caption_2: 'Man van de wedstrijd',
    ManageScore_MOTM_Intro_1: 'Als een speler van',
    ManageScore_MOTM_Intro_2: 'het andere team',
    ManageScore_MOTM_Intro_3: "je heeft geïmponeerd, noem hem/haar dan de 'Man van de wedstrijd'.",
    ManageScore_MOTM_Intro_4: 'Je tegenstander zal hetzelfde doen voor jouw team',
    ManageScore_Selected_MOTM_Caption_1: 'Je hebt',
    ManageScore_Selected_MOTM_Label: 'Man van de wedstrijd genoemd',
    ManageScore_No_MOTM: 'NIEMAND',
    // SWITCHLANGUAGEBUTTON COMPONENT
    SwitchLanguageButton_Title: 'Selecteer een taal',
    SwitchLanguageButton_Current_Lang: 'Huidige taal',
    SwitchLanguageButton_Button_Title: 'Talen',
    // NEWCONVERSATION MODAL
    New_Conversation_Title: 'Nieuw gesprek',
    New_Conversation_Title_Label: 'Titel',
    New_Conversation_Description_Label: 'Onderwerp (optioneel)',
    New_Conversation_Description_Placeholder: 'Wat is het onderwerp/doel van het gesprek',
    New_Conversation_Description_Helper_1: 'Opmerking moet minstens 0 tekens bevatten',
    New_Conversation_Description_Helper_2: 'Er is een ongeoorloofd teken in',
    New_Conversation_Author_Label: 'Deelnemen als',
    New_Conversation_Participants_Label: 'Deelnemers',
    New_Conversation_Participants_Placeholder: 'Selecteer een deelnemer',
    New_Conversation_No_Participants: 'Geen deelnemer geselecteerd',
    New_Conversation_Create: 'Gesprek aanmaken',

    // CONVERSATIONS COMPONENT
    Conversations_Meta_Title: 'Gesprekken',
    Conversations_Conversations_Title: 'Gesprekken',
    Conversations_Conversation_Title: 'Gesprek',
    Conversations_Button_New_Conversation: 'Nieuw gesprek',
    Conversations_Button_Conversations: 'Gesprekken',
    Conversations_Button_Add_Contact: 'Contact toevoegen',
    Conversations_Button_Contacts: 'Contacten',
    Conversations_Start_Now_Title: 'Begin nu een gesprek!',
    Conversations_Disabled_1: 'Deze functie is uitgeschakeld',
    Conversations_Disabled_2: 'Komt binnenkort terug',
    // CONVERSATION COMPONENT
    Conversation_Sender_As: 'Als',
    Conversation_Button_Edit: 'Gesprek bewerken',
    Conversation_First_Messager: 'Wees de eerste om een bericht te schrijven',
    Conversation_Loding_Messages_1: 'Laden...',
    Conversation_Loding_Messages_2: 'Vorige berichten laden',
    Conversation_Message_Removed: 'Bericht verwijderd',
    Conversation_Message_Edited: '(bewerkt)',
    Conversation_Content_Placeholder: 'Schrijf een bericht...',
    // NEWS COMPONENT
    News_Meta_Title: 'Laatste nieuws en actualiteiten',
    News_No_News_Title: 'Geen nieuws',
    // MAKEPAYMENT MODAL
    MakePayment_Transactions: 'Transacties',
    MakePayment_Transaction: 'Transactie',
    MakePayment_To_Sign: 'te ondertekenen',
    MakePayment_Wallets: 'wallets',
    MakePayment_Wallet: 'wallet',
    MakePayment_Need_PM: 'Je hebt een betaalmethode nodig om deze functie te gebruiken',
    MakePayment_Create_Wallet: 'Maak je wallet aan',
    MakePayment_Need_Wallet_1: 'Je hebt een wallet nodig',
    MakePayment_Need_Wallet_2: 'en een betaalmethode',
    MakePayment_Need_Wallet_3: 'om deze functie te gebruiken',
    // SCANQRCODE FEATURE
    ScanQRCode_Scanned: 'Gescannd!',
    ScanQRCode_Scan_QRCode: 'Scan een QRCode',
    ScanQRCode_Scan_Off: 'Scanner momenteel niet beschikbaar',
    // SHOWQRCODE FEATURE
    ShowQRCode_Source: 'Betaalbron',
    // WALLETS COMPONENT
    Wallets_Meta_Title: 'Wallets',
    Wallets_SC_Updated: 'Uitgavecode bijgewerkt!',
    Wallets_How_To: 'Hoe gebruik ik mijn wallets?',
    Wallets_Edit_SC: 'Wijzig uitgavecode',
    Wallets_New_Wallet: 'Nieuwe wallet',
    Wallets_Exchanges: 'Uitwisselingen',
    Wallets_Wallets_Title: 'wallets',
    Wallets_Wallet_Title: 'wallet',
    Wallets_Own_By: 'eigendom van',
    Wallets_Tab_Personal: 'Persoonlijk',
    Wallets_NB_Payment_Method: 'Betaalmethode',
    // WALLET COMPONENT
    Wallet_Meta_Title: 'Wallet',
    Wallet_Meta_Recommened: '(zeer aanbevolen)',
    Wallet_How_To: 'Hoe gebruik ik mijn wallet?',
    Wallet_Edit_Button: 'Wallet bewerken',
    Wallet_Delete_Button: 'Wallet verwijderen',
    Wallet_Lock_Button: 'Wallet tijdelijk vergrendelen',
    Wallet_Own_By: 'eigendom van',
    Wallet_Default: '(standaard)',
    Wallet_Lipay_Info: 'We raden sterk aan om een Lipay-account aan te maken, omdat het betaalbare wereldwijde betalingsopties en extra functies biedt.',
    Wallet_Unavailable_Title: 'Tijdelijk niet beschikbaar',
    Wallet_Unavailable_Label: 'Je wallet is niet beschikbaar, wacht totdat je een e-mail/melding van ons ontvangt.',
    Wallet_KYC_Wait_Title: 'KYC-verificatie',
    Wallet_KYC_Wait_Label_1: 'Je wallet is in ons goedkeuringsproces,',
    Wallet_KYC_Wait_Label_2: 'Wacht totdat je een e-mail/melding van ons ontvangt.',
    Wallet_KYC_Wait_Label_3: 'aangemaakt',
    Wallet_KYC_Failed_Title: 'KYC-verificatie mislukt',
    Wallet_KYC_Failed_Label_1: 'De verificatie van je wallet is niet succesvol geweest,',
    Wallet_KYC_Failed_Label_2: 'Controleer je gegevens voordat je een nieuwe verificatie uitvoert. Als je verificatie meer dan 3 keer mislukt, neem dan contact met ons op via contact@thebhub.io.',
    Wallet_Button_New_KYC: 'Nieuwe wallet-verificatie',
    Wallet_New_KYC_Title: 'nieuwe verificatie',
    Wallet_New_BC: 'Bankkaart toevoegen',
    Wallet_New_BA: 'Nieuw Lipay-account',
    Wallet_New_PM: 'Nieuwe betaalmethode',
    // PAYMENTMETHOD COMPONENT
    PaymentMethod_Meta_Title: 'Betaalmethode',
    PaymentMethod_All_Tx: 'Alle TXs',
    PaymentMethod_Tx_Me: 'Tx Door mij',
    PaymentMethod_Tx_Others: 'Tx Door anderen',
    PaymentMethod_Lock: 'Betaalmethode tijdelijk vergrendelen',
    PaymentMethod_Lock_Label: 'Wil je echt vergrendelen',
    PaymentMethod_Unlock: 'Betaalmethode ontgrendelen',
    PaymentMethod_Unlock_Label: 'Wil je echt ontgrendelen',
    PaymentMethod_Delete: 'Betaalmethode verwijderen',
    PaymentMethod_Select_Default: 'Selecteer als standaard',
    PaymentMethod_Copied: 'gekopieerd!',
    PaymentMethod_Title: '(Betaalmethode)',
    PaymentMethod_Default: '(standaard betaalmethode)',
    PaymentMethod_Button_Unlock: 'Ontgrendelen',
    PaymentMethod_Button_Lock: 'Vergrendelen',
    PaymentMethod_Is_Lock: 'is vergrendeld.',
    PaymentMethod_Is_Unlock: 'is ontgrendeld.',
    // ALLTRANSACTIONS COMPONENT
    AllTransactions_Received: 'ontvangen',
    AllTransactions_Sent: 'verzonden',
    AllTransactions_No_Tx: 'Geen transacties',
    // JOINTEAM PAGE
    Join_Team_Meta_Title: 'Doe mee aan een team',
    Join_Team_Title: 'Doe mee aan een team',
    Join_Team_Button_Join: 'Deelnemen',
    Join_Team_Button_Sent: 'verzoek verzonden',
    Join_Team_Button_New_Team: 'Nieuw team',
    // VIEWTEAMLINEUP MODAL
    ViewTeamLineupModal_Request_Sent: 'Verzoek verzonden!',
    ViewTeamLineupModal_Request_Already_Sent: 'Je hebt al een verzoek gestuurd',
    ViewTeamLineupModal_Button_Join_Team: 'Word lid van het team',
    ViewTeamLineupModal_Button_Sending_Request: 'Verzoek verzenden...',
    // ADDTEAMLINEUP MODAL
    AddTeamLineupModal_Title: 'Nieuwe teamopstelling',
    AddTeamLineupModal_Name_Label: 'Naam',
    AddTeamLineupModal_No_Teammate_Selected: 'Geen teamgenoot geselecteerd',
    AddTeamLineupModal_Players_Label: 'Stel uw nieuwe opstelling samen',
    AddTeamLineupModal_Players_Placeholder: 'Schrijf een teamalias',
    AddTeamLineupModal_Captains_Label: 'Selecteer uw aanvoerder(s)',
    AddTeamLineupModal_Captains_Placeholder: 'Schrijf een speleralias',
    AddTeamLineupModal_Button_Create: 'Opstelling maken',
    // ADDTEAMPLAYER MODAL
    AddTeamPlayerModal_Title: 'Nieuwe teamspeler toevoegen',
    AddTeamPlayerModal_Name_Label: 'Naam',
    AddTeamPlayerModal_No_Player_Selected: 'Geen speler geselecteerd',
    AddTeamPlayerModal_Players_Label: 'Spelers',
    AddTeamPlayerModal_Players_Placeholder: 'Schrijf een speleralias',
    AddTeamPlayerModal_Button_Create: 'Speler toevoegen',
    // LISTCONVERSATIONSMESSENGER COMPONENT
    ListConversationsMessenger_All_Contacts_Label: 'Contacten',
    ListConversationsMessenger_All_Contacts_Placeholder: 'Schrijf een naam',
    ListConversationsMessenger_Search_Conversations_Placeholder: 'Zoek in alle gesprekken',
    ListConversationsMessenger_No_Conversations: 'Geen gesprekken',
    // NEWCONVERSATIONMESSENGER COMPONENT
    NewConversationMessenger_Title: 'Nieuw gesprek',
    NewConversationMessenger_Title_Label: 'Titel',
    NewConversationMessenger_Description_Label: 'Onderwerp (optioneel)',
    NewConversationMessenger_Description_Placeholder: 'Wat is het onderwerp/doel van het gesprek',
    NewConversationMessenger_Description_Helper_1: 'Opmerking moet minstens 0 tekens bevatten',
    NewConversationMessenger_Description_Helper_2: 'Er is een ongeautoriseerd teken in',
    NewConversationMessenger_Author_Label: 'Deelnemen als',
    NewConversationMessenger_All_Participants_Label: 'Deelnemers',
    NewConversationMessenger_All_Participants_Placeholder: 'Selecteer een deelnemer',
    NewConversationMessenger_No_Participants: 'Geen deelnemer geselecteerd',
    NewConversationMessenger_Button_View_Conversation: 'Bekijk bestaand gesprek',
    NewConversationMessenger_Button_Start_Conversation: 'Start gesprek',
    // CONVERSATIONMESSENGER COMPONENT
    ConversationMessenger_Sender_As: 'Als',
    ConversationMessenger_Edited_Msg: '(bewerkt)',
    ConversationMessenger_Removed_Msg: 'Bericht verwijderd',
    ConversationMessenger_Write_Msg: 'Schrijf een bericht...',
    // ADDNEWTEAMMANAGER MODAL
    AddTeamManagerModal_Title: 'Manager toevoegen',
    AddTeamManagerModal_No_Manager_Selected: 'Geen manager geselecteerd',
    AddTeamManagerModal_Players_Label: 'Managers',
    AddTeamManagerModal_Players_Placeholder: 'Schrijf een spelersgebruikersnaam',
    AddTeamManagerModal_Button_Create: 'Manager toevoegen',
    // TEAMS PAGE
    Teams_Meta_Title: 'Teams',
    Teams_New_Team: 'Maak je team aan',
    Teams_Button_New_Team: 'Nieuw team',
    Teams_Verify_Email: 'Om een team aan te maken, moet je je e-mail verifiëren',
    // NEWFLEX MODAL
    NewFlexModal_Button_Create: 'Flex nu',
    NewFlexModal_No_Flexs: 'Geen flexen',
    NewFlexModal_Caption_Label: 'Bijschrift',
    NewFlexModal_Caption_Helper_1: 'Je inhoud moet zijn',
    NewFlexModal_Flexer_Label: 'Flex als',
    NewFlexModal_CoFlexer_Label: 'Co-uitgevers',
    NewFlexModal_CoFlexer_Placeholder: 'Voer een gebruikersnaam in',
    NewFlexModal_Flexed_Item_Label: 'Flex items',
    NewFlexModal_Flexed_Item_Placeholder: 'Voer een itemnaam in',
    // FLEX COMPONENT
    Flex_Meta_Title: '',
    Flex_Not_Found_1: 'Flex',
    Flex_Not_Found_2: 'niet gevonden',
    Flex_Not_Found_3: 'Het spijt ons, maar het lijkt erop dat deze flex niet beschikbaar is, controleer de spelling van de naam of gebruik onze',
    Flex_Not_Found_4: 'zoekmachine',
    Flex_Not_Found_5: '.',
    Flex_Not_Found_6: 'Flex',
    Flex_Not_Found_7: 'binnenkort beschikbaar',
    Flex_Not_Found_8: 'Nog even geduld, ze zijn bezig met de details, maar in de tussentijd kunt u het laatste nieuws op de',
    Flex_Not_Found_9: 'Startpagina',
    Flex_Not_Found_10: 'bekijken.',
    Flex_Button_Edit: 'Flex bewerken',
    Flex_Button_Accept: 'Accepteren',
    Flex_Waiting_Confirmation_1: 'Wachten op bevestiging van',
    Flex_Waiting_Confirmation_2: 'om je flex openbaar te maken.',
    Flex_Share_Title: 'Fivezer Flex',
    Flex_Share_Text: 'Vind meer flex op',
    Flex_Flexer_As: 'Als',
    Flex_Likes: 'likes',
    Flex_Like: 'like',
    Flex_Comment_Placeholder: 'Schrijf een reactie...',
    Flex_First_Comment: 'Wees de eerste om een reactie te schrijven',

    // NUTSVOORZIENINGEN
    Utilities_Affirmation: 'Bevestiging',
    Utilities_Affirmation_1: 'Ik ben tevreden met mijn aankoop!',
    Utilities_Affirmation_2: 'Geweldige ervaring in het algemeen.',
    Utilities_Affirmation_3: 'Bedankt voor je uitstekende service!',

    Utilities_Anger: 'Woede',
    Utilities_Anger_1: 'Ik ben gefrustreerd over de service.',
    Utilities_Anger_2: 'Teleurgesteld in mijn ervaring.',
    Utilities_Anger_3: 'Ontevreden over de productkwaliteit.',

    Utilities_Anxiety: 'Angst',
    Utilities_Anxiety_1: 'Onrustig over de aankoop.',
    Utilities_Anxiety_2: 'Bezorgd over de service.',
    Utilities_Anxiety_3: 'Angst door een slechte ervaring.',

    Utilities_Blushing: 'Blozen',
    Utilities_Blushing_1: 'Gegeneerd over de aankoop.',
    Utilities_Blushing_2: 'Blozen door een ongemakkelijke situatie.',
    Utilities_Blushing_3: 'Gegeneerd over de ontvangen service.',

    Utilities_Contempt: 'Minachting',
    Utilities_Contempt_1: 'Minachting voor de aankoop.',
    Utilities_Contempt_2: 'Ontevreden over de service.',
    Utilities_Contempt_3: 'Minachting voor de productkwaliteit.',

    Utilities_Despair: 'Wanhoop',
    Utilities_Despair_1: 'Wanhoop over de aankoop.',
    Utilities_Despair_2: 'Wanhoop door een slechte ervaring.',
    Utilities_Despair_3: 'Wanhoop over de ontvangen service.',

    Utilities_Determination: 'Vastberadenheid',
    Utilities_Determination_1: 'Vastbesloten om een probleem op te lossen.',
    Utilities_Determination_2: 'Gemotiveerd om uitdagingen te overwinnen.',
    Utilities_Determination_3: 'Vastbesloten om de service te verbeteren.',

    Utilities_Devotion: 'Toewijding',
    Utilities_Devotion_1: 'Toegewijd aan het merk en zijn producten.',
    Utilities_Devotion_2: 'Loyaal en toegewijd aan voortdurende steun.',
    Utilities_Devotion_3: 'Toegewijd aan een positieve klantervaring.',

    Utilities_Disdain: 'Minachting',
    Utilities_Disdain_1: 'Minachting voor de aankoop.',
    Utilities_Disdain_2: 'Minachting voor de geleverde service.',
    Utilities_Disdain_3: 'Ontevreden over de algehele ervaring.',

    Utilities_Disgust: 'Walging',
    Utilities_Disgust_1: 'Walgelijk over de aankoop.',
    Utilities_Disgust_2: 'Walging over de ontvangen service.',
    Utilities_Disgust_3: 'Onaangenaam verrast door de productkwaliteit.',

    Utilities_Fear: 'Angst',
    Utilities_Fear_1: 'Angstig over de uitkomst van de aankoop.',
    Utilities_Fear_2: 'Onrustig over de servicekwaliteit.',
    Utilities_Fear_3: 'Bang om problemen te ondervinden met het product.',

    Utilities_Grief: 'Verdriet',
    Utilities_Grief_1: 'Verdrietig over de aankoop.',
    Utilities_Grief_2: 'Verdriet door een teleurstellende ervaring.',
    Utilities_Grief_3: 'Verdrietig over de slechte service ontvangen.',

    Utilities_Guilt: 'Schuldgevoel',
    Utilities_Guilt_1: 'Schuldgevoel over de aankoopbeslissing.',
    Utilities_Guilt_2: 'Spijt over de gekozen service.',
    Utilities_Guilt_3: 'Schuldgevoel over de negatieve impact op de ervaring.',

    Utilities_Hatred: 'Haat',
    Utilities_Hatred_1: 'Intense haat over de aankoop.',
    Utilities_Hatred_2: 'Haat tegen de dienstverlener.',
    Utilities_Hatred_3: 'Sterke afkeer van de algehele ervaring.',

    Utilities_Helplessness: 'Hulpeloosheid',
    Utilities_Helplessness_1: 'Hulpeloos over de aankoop.',
    Utilities_Helplessness_2: 'Hulpeloos in het omgaan met serviceproblemen.',
    Utilities_Helplessness_3: 'Overweldigd door een gevoel van hulpeloosheid.',

    Utilities_HighSpirits: 'Hoogspanning',
    Utilities_HighSpirits_1: 'In hoogspanning door de positieve aankoop.',
    Utilities_HighSpirits_2: 'Energiek en enthousiast over de service.',
    Utilities_HighSpirits_3: 'Vrolijk en opgewonden over de algehele ervaring.',

    Utilities_IllTemper: 'Slecht humeur',
    Utilities_IllTemper_1: 'Slecht humeur door de aankoop.',
    Utilities_IllTemper_2: 'Geïrriteerd door de geleverde service.',
    Utilities_IllTemper_3: 'In een slecht humeur door de algehele ervaring.',

    Utilities_Joy: 'Vreugde',
    Utilities_Joy_1: 'Vreugde en geluk met de aankoop.',
    Utilities_Joy_2: 'Vreugdevol en tevreden over de service.',
    Utilities_Joy_3: 'Blij en tevreden met de algehele ervaring.',

    Utilities_Love: 'Liefde',
    Utilities_Love_1: 'Liefde en waardering voor de aankoop.',
    Utilities_Love_2: 'Liefde en bewondering voor de ontvangen service.',
    Utilities_Love_3: 'Diepe genegenheid en liefde voor de algehele ervaring.',

    Utilities_LowSpirits: 'Lage stemming',
    Utilities_LowSpirits_1: 'Bedroefd over de aankoop.',
    Utilities_LowSpirits_2: 'Ontmoedigd door de servicekwaliteit.',
    Utilities_LowSpirits_3: 'Lage stemming door de algehele ervaring.',

    Utilities_Mediation: 'Bemiddeling',
    Utilities_Mediation_1: 'Open voor bemiddeling bij het oplossen van problemen.',
    Utilities_Mediation_2: 'Bereidheid om zorgen te bespreken door bemiddeling.',
    Utilities_Mediation_3: 'Op zoek naar een bemiddelde oplossing voor serviceproblemen.',

    Utilities_Modesty: 'Bescheidenheid',
    Utilities_Modesty_1: 'Bescheiden tevredenheid met de aankoop.',
    Utilities_Modesty_2: 'Nederige erkenning van de service.',
    Utilities_Modesty_3: 'Waardering voor de ervaring met bescheidenheid.',

    Utilities_Negation: 'Negatie',
    Utilities_Negation_1: 'Ontkennen van tevredenheid met de aankoop.',
    Utilities_Negation_2: 'Oneens met de servicekwaliteit.',
    Utilities_Negation_3: 'Uitdrukken van ontkenning over de algehele ervaring.',

    Utilities_Neutral: 'Neutraal',
    Utilities_Neutral_1: 'Neutraal over de aankoop.',
    Utilities_Neutral_2: 'Een neutrale houding tegenover de service.',
    Utilities_Neutral_3: 'Geen sterke emotie ten opzichte van de algehele ervaring.',

    Utilities_Patience: 'Geduld',
    Utilities_Patience_1: 'Geduld in het omgaan met problemen.',
    Utilities_Patience_2: 'Geduldig wachten op serviceoplossing.',
    Utilities_Patience_3: 'Geduld behouden tijdens de algehele ervaring.',

    Utilities_Pride: 'Trots',
    Utilities_Pride_1: 'Trots op de aankoopbeslissing.',
    Utilities_Pride_2: 'Trots op de ontvangen service.',
    Utilities_Pride_3: 'Een gevoel van trots op de algehele ervaring.',

    Utilities_Reflection: 'Reflectie',
    Utilities_Reflection_1: 'Reflectie op de aankoopkeuzes.',
    Utilities_Reflection_2: 'Zelfreflectie na de service.',
    Utilities_Reflection_3: 'Zorgvuldige reflectie op de algehele ervaring.',

    Utilities_SelfAttention: 'Zelfaandacht',
    Utilities_SelfAttention_1: 'Zelfaandacht voor feedback en zorgen.',
    Utilities_SelfAttention_2: 'Focus op zelfverbetering na de service.',
    Utilities_SelfAttention_3: 'Zelfaandacht waarderen in de algehele ervaring.',

    Utilities_Shyness: 'Verlegenheid',
    Utilities_Shyness_1: 'Verlegenheid bij het uiten van feedback.',
    Utilities_Shyness_2: 'Verlegen maar waarderend over de service.',
    Utilities_Shyness_3: 'Feedback uiten met een vleugje verlegenheid.',

    Utilities_Suffering: 'Lijden',
    Utilities_Suffering_1: 'Lijden door de aankoopervaring.',
    Utilities_Suffering_2: 'Lijden door de ontvangen service.',
    Utilities_Suffering_3: 'Lijden door de algehele ervaring.',

    Utilities_Sulkiness: 'Sikkeneurigheid',
    Utilities_Sulkiness_1: 'Sikkeneurig over het aankoopresultaat.',
    Utilities_Sulkiness_2: 'Sikkeneurig door ontevredenheid over de service.',
    Utilities_Sulkiness_3: 'Sikkeneurig over de algehele ervaring.',

    Utilities_Surprise: 'Verrassing',
    Utilities_Surprise_1: 'Verrast door de onverwachte aspecten van de aankoop.',
    Utilities_Surprise_2: 'Positieve verrassing met de service.',
    Utilities_Surprise_3: 'Verrassing uiten over de algehele positieve ervaring.',

    Utilities_TenderFeelings: 'Tedere gevoelens',
    Utilities_TenderFeelings_1: 'Tedere gevoelens voor de aankoop.',
    Utilities_TenderFeelings_2: 'Delicate emoties over de service.',
    Utilities_TenderFeelings_3: 'Tedere gevoelens koesteren in de algehele ervaring.',

    Utilities_Weeping: 'Huilen',
    Utilities_Weeping_1: 'Huilen vanwege de aankoopervaring.',
    Utilities_Weeping_2: 'Huilen over een teleurstellende service.',
    Utilities_Weeping_3: 'Verdriet uiten door te huilen in de algehele ervaring.',

    SelectTeamTournamentModal_Title: 'Selecteer je team',
    RegistrationTournamentModal_Title: 'Selecteer teamopstelling',
    RegistrationTournamentModal_Teams_Label: 'Bestaande teams',
    RegistrationTournamentModal_Teams_Label_2: 'geregistreerd',
    RegistrationTournamentModal_Button_1: 'Toernooi betreden...',
    RegistrationTournamentModal_Button_2: 'Registreren',
    RegistrationTournamentModal_Button_3: 'Toernooi verlaten...',
    RegistrationTournamentModal_Button_4: 'Uitschrijven',
    RegistrationTournamentModal_New_Team_Label: 'Nieuwe opstelling',
    RegistrationTournamentModal_New_Team_Caption: 'Maak een nieuwe opstelling of gebruik een bestaande',

    NewSponsorshipModal_Descriptions_Title: 'Beschrijvingen',
    NewSponsorshipModal_Price_Title: 'Sponsoring prijs',
    NewSponsorshipModal_Price_Value_1: 'Prijs',
    NewSponsorshipModal_Price_Currency: 'Valuta',
    NewSponsorshipModal_Button_Create: 'Sponsoring indienen',

    Company_Translations: 'Vertalingen',

    ViewSponsorshipProposalModal_Proposed_Sponsorship: 'Voorgesteld sponsorship',
    ViewSponsorshipProposalModal_Payment_Plan_1: 'U ontvangt',
    ViewSponsorshipProposalModal_Payment_Plan_2: 'waarvan de eerste zal zijn op',
    ViewSponsorshipProposalModal_Payment_Plan_3: 'en de laatste zal zijn op',
    ViewSponsorshipProposalModal_Button_Accept: 'Accepteren',
    ViewSponsorshipProposalModal_Button_Reject: 'Afwijzen',
    ViewSponsorshipProposalModal_Response_1: 'Wilt u dit voorstel echt accepteren?',
    ViewSponsorshipProposalModal_Response_2: 'Wilt u dit voorstel echt afwijzen?',

    AddSponsorshipNoteModal_Title: 'Eindnotitie toevoegen',
    AddSponsorshipNoteModal_Button_Create: 'Notitie indienen',
  },
};

{
  /* <FormattedMessage id="" />&nbsp; */
}
