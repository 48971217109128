export const french_translations = {
  value: 'FR',
  content: {
    // GLOBAL
    Global_Meta_Title: 'Fivezer x Différent Animal, Même Bête !',
    Global_Meta_Description: "Fivezer est une ligue de football à cinq de nouvelle génération, où les meilleures équipes du monde entier affrontent des équipes locales et étrangères, n'importe où, n'importe quand. Fivezer est une porte d'entrée vers les plus grandes compétitions/amicales, où chaque joueur/équipe peut suivre ses performances et statistiques (individuelles/collectives) en utilisant nos divers outils IoT (bracelet, ballons, filets et plus encore).",
    Global_Button_Pay_Now: 'Payer maintenant',
    Global_Button_Pay_Now_Caption_1: 'les réservations doivent être payées',
    Global_Button_Pay_Now_Caption_2: 'Vous devez payer les frais de votre tournoi',
    Global_Status: 'statut',
    Global_Pictures_Label: 'photos',
    Global_Pictures_Left: 'restant',
    Global_Pictures_Max: 'Max',
    Global_Button_Add: 'Ajouter',
    Global_Button_Add_Cover: 'Ajouter une couverture',
    Global_Button_Add_Logo: 'Ajouter un logo',
    Global_Button_Add_PDF: 'Ajouter un PDF',
    Global_Button_Add_IMG_File: 'Ajouter un fichier IMG',
    Global_Button_Resize: "Cliquez sur l'image pour redimensionner",
    Global_Button_Cancel: 'Annuler',
    Global_Button_Crop: 'Recadrer',
    Global_Button_Close: 'Fermer',
    Global_Button_Ratio: "L'image ne respecte pas le rapport hauteur/largeur souhaité",
    Global_Button_Delete: 'Supprimer',
    Global_Button_Save_Updates: 'Enregistrer',
    Global_Button_Reset: 'Réinitialiser',
    Global_Button_Share: 'Partager',
    Global_Button_Share_Caption_1: 'Vous pouvez en savoir plus sur',
    Global_Button_Share_Caption_2: 'sur',
    Global_Button_Save_Translation: 'Enregistrer la traduction',
    Global_Button_Loading: 'chargement...',
    Global_Button_Loading_Wait: 'chargement, veuillez patienter...',
    Global_Button_Load_More: 'voir plus',
    Global_Button_See_More: 'voir plus',
    Global_Button_See: 'voir',
    Global_Button_See_Less: 'voir moins',
    Global_Button_View: 'voir',
    Global_Button_Follow: 'Suivre',
    Global_Button_Unfollow: 'Ne plus suivre',
    Global_Follows_You: 'vous suit',
    Global_Button_Next: 'Suivant',
    Global_Button_Back: 'Précédent',
    Global_Button_Done: 'Terminé',
    Global_Button_Update: 'Modifier',
    Global_Address_Title_1: "Titre de l'adresse",
    Global_Address_Title_2: 'Siège social de, Bureau de, Adresse de, etc...',
    Global_Address_Street_Name_1: 'Nom de la rue',
    Global_Address_Street_Name_2: '83 rue de la paix',
    Global_Address_Complement_1: 'Nom de la rue (complément)',
    Global_Address_Complement_2: 'Complément du nom de la rue',
    Global_Address_Complement_3: 'Appartement, suite, bâtiment, étage, etc...',
    Global_Address_Postcode_1: 'Code postal',
    Global_Address_Postcode_2: '75000',
    Global_Address_City_1: 'Ville',
    Global_Address_City_2: 'Ville de la paix',
    Global_Address_County_1: 'Comté',
    Global_Address_County_2: 'Comté de la paix',
    Global_Address_State_1: 'État',
    Global_Address_State_2: 'État de la paix',
    Global_Address_Country: 'Pays',
    Global_Address_GPS_1: "Veuillez localiser l'adresse ci-dessus sur la carte.",
    Global_Address_GPS_2: 'Cliquez sur le marqueur pour le déplacer et soyez aussi précis que possible !',
    Global_No_Description: 'Aucune description',
    Global_Sport_Team_Type_1: 'Ligue',
    Global_Sport_Team_Type_2: 'Amical',
    Global_Sport_1: 'Football',
    Global_Sport_2: 'Basketball',
    Global_Social_Media_Title: 'Réseaux sociaux',
    Global_Social_Media_Button_1: 'Ajouter réseau social',
    Global_Select_Translations: 'Traductions',
    Global_Stats_Games: 'Matchs',
    Global_Stats_Games_Played: 'Matchs joués',
    Global_Stats_Wins: 'Victoires',
    Global_Stats_Draws: 'Nuls',
    Global_Stats_Loss: 'Défaites',
    Global_Stats_Goals: 'Buts',
    Global_Stats_Assists: 'Passes D',
    Global_Stats_Conceded: 'Encaissés',
    Global_Stats_World: 'Classement',
    Global_Stats_World_Sign_1: 'er',
    Global_Stats_World_Sign_2: 'ème',
    Global_Stats_World_Sign_3: 'ème',
    Global_Stats_World_Sign: 'ème',
    Global_Stats_Followers: 'Abonnés',
    Global_Stats_Follower: 'Abonné',
    Global_Stats_MOTM: 'Homme du match',
    Global_Stats_Good_Passes: 'Passes réussies',
    Global_Stats_Shots: 'Tirs',
    Global_Stats_KM_Total: 'KM au total',
    Global_Stats_KM_Average: 'KM/match en moyenne',
    Global_Stats_KMH_Max: 'KM/heure vitesse maximale',
    Global_Stats_KMH_Average: 'KM/heure en moyenne',
    Global_Stats_Clean_Sheet_1: 'Feuille',
    Global_Stats_Clean_Sheet_2: 'Propre',
    Global_Tabs_Overview: "Vue d'ensemble",
    Global_Tabs_Notifications: 'Notifications',
    Global_Tabs_Calendar: 'Calendrier',
    Global_Tabs_Lineups: 'Alignements',
    Global_Tabs_Lineup: 'Alignement',
    Global_Tabs_Statistics: 'Statistiques',
    Global_Button_Add_Player: 'Ajouter joueur',
    Global_Button_Add_Manager: 'Ajouter manager',
    Global_Button_Delete_Player: 'Supprimer joueur',
    Global_Button_New_Lineup: 'Nouvel alignement',
    Global_Button_Edit_Lineup: 'Modifier alignement',
    Global_Button_New_Challenge: 'Nouveau défi',
    Global_Button_No_Notifications: 'Pas encore de notifications',
    Global_No_Challenges: 'Aucun défi',
    Global_No_Players: 'Aucun joueur',
    Global_No_Manager: 'Aucun manager',
    Global_No_Notifications: 'Aucune notification',
    Global_Sport_Type_1: 'Football',
    Global_Sport_Type_2: 'Basketball',
    Global_No_Trophies: 'Aucun trophée',
    Global_Trophy_Gold: 'Or',
    Global_Trophy_Silver: 'Argent',
    Global_Trophy_Bronze: 'Bronze',
    Global_Trophy_Iron: 'Fer',
    Global_Trophy_Right: 'Droite',
    Global_Trophy_Left: 'Gauche',
    Global_Trophy_Both: 'Les deux',
    Global_Since: 'depuis',
    Global_Challenge_Type_Friendly: 'Amical',
    Global_Challenge_Type_League: 'Ligue',
    Global_Challenge_Type_Matchmaker: 'Matchmaker',
    Global_Dates_Start_Label: 'Date de début',
    Global_Dates_End_Label: 'Date de fin',
    Global_Followers: 'Abonnés',
    Global_Followings: 'Abonnements',
    Global_Gender_Female: 'Femme',
    Global_Gender_Male: 'Homme',
    Global_Gender_Other: 'Autre',
    Global_Position_Goalkeeper: 'Gardien de but',
    Global_Position_Defender: 'Défenseur',
    Global_Position_Midfielder: 'Milieu de terrain',
    Global_Position_Striker: 'Attaquant',
    Global_Foots_Right: 'Droit',
    Global_Foots_Left: 'Gauche',
    Global_Foots_Both: 'Les deux',
    Global_ID_Types_None: "Sélectionnez un type de pièce d'identité",
    Global_ID_Types_Passport: 'Passeport',
    Global_ID_Types_National: 'National',
    Global_ID_Types_Residential: 'Résidentiel (Temporairement)',
    Global_Permanent_Delete_Title: 'Supprimer définitivement',
    Global_Button_Desactivate: 'Désactiver',
    Global_Button_Activate: 'Activer',
    Global_Field_Types_1: 'Hybride',
    Global_Field_Types_2: 'Synthétique',
    Global_Field_Types_3: 'Deuxième Génération (2G)',
    Global_Field_Types_4: 'Troisième Génération (3G)',
    Global_Field_Types_5: 'Quatrième Génération (4G)',
    Global_Field_Types_6: 'Cinquième Génération (5G)',
    Global_Field_Types_7: 'Sixième Génération (6G)',
    Global_Field_Sizes_1: '1 contre 1',
    Global_Field_Sizes_2: '2 contre 2',
    Global_Field_Sizes_3: '3 contre 3',
    Global_Field_Sizes_4: '4 contre 4',
    Global_Field_Sizes_5: '5 contre 5',
    Global_Field_Sizes_6: '6 contre 6',
    Global_Field_Sizes_7: '7 contre 7',
    Global_Field_Sizes_8: '8 contre 8',
    Global_Field_Sizes_9: '9 contre 9',
    Global_Field_Sizes_10: '10 contre 10',
    Global_Field_Sizes_11: '11 contre 11',
    Global_Delete_Confirmation_Caption_1: 'Cette action est irréversible',
    Global_Delete_Confirmation_Caption_2: 'Voulez-vous vraiment supprimer',
    Global_Delete_Confirmation_Caption_3: 'Confirmez en écrivant',
    Global_Delete_Confirmation_Caption_4: 'dans le champ ci-dessous :',
    Global_Write_Username: "Écrire un nom d'utilisateur",
    Global_Write_Reset_All: 'Réinitialiser tout',
    Global_Filters_Title: 'Filtres',
    Global_No_User_Found_Title: 'Aucun utilisateur trouvé',
    Global_No_Team_Found_Title: 'Aucune équipe trouvée',
    Global_See_Team: "Voir l'équipe",
    Global_Write_Team: 'Écrire un mot-clé/thème pour rechercher',
    Global_Search_Tab_Players: 'Joueurs',
    Global_Search_Tab_Teams: 'Équipes',
    Global_Pin_SMS_1: 'Bonjour',
    Global_Pin_SMS_2: 'Votre code de vérification Fivezer est :',
    Global_Activate_Email_Label_1: 'Vous devez activer votre email',
    Global_Activate_Email_Label_2: "en utilisant l'email de vérification que nous vous avons envoyé.",
    Global_Activate_Email_Button_1: "Envoi de l'email",
    Global_Activate_Email_Button_2: 'ENVOYÉ',
    Global_Activate_Email_Button_3: 'RENVOYER',
    Global_As_Footballer: 'Le',
    Global_No_Statistics: 'Aucune statistique',
    Global_No_History: 'Aucun historique de transfert',
    Global_NA: 'N/A',
    Global_Dark_Mode: 'Mode sombre',
    Global_Light_Mode: 'Mode clair',
    Global_Player: 'joueur',
    Global_Manager: 'manager',
    Global_Missing_Footballer_Profile_Button_1: 'Ajoutez-le maintenant',
    Global_Missing_Footballer_Profile_Label_1: 'Votre profil de footballeur est manquant',
    Global_Button_Edit: 'Modifier',
    Global_Manager_At: 'Manager de',
    Global_Not_Found: 'introuvable',
    Global_Page_Not_Found_Title: 'Page non trouvée',
    Global_Page_Not_Found_1: 'Désolé pour cela',
    Global_Page_Not_Found_2: "Nous n'avons pas pu charger les informations en raison d'un réseau faible",
    Global_Page_Not_Found_3: 'Veuillez réessayer',
    Global_Page_Not_Found_4: 'Accès refusé',
    Global_Page_Not_Found_5: "Vous n'avez pas accès à cette information",
    Global_Page_Not_Found_6: "Retour à l'accueil",
    Global_Page_Not_Found_7: 'non trouvé',
    Global_Page_Not_Found_8: "veuillez vérifier l'orthographe du nom ou utiliser notre",
    Global_Page_Not_Found_9: 'moteur de recherche',
    Global_Page_Not_Found_10: 'pour essayer une nouvelle recherche.',
    Global_Page_Not_Found_11: 'Échec du chargement',
    Global_Page_Not_Found_12: 'veuillez essayer de recharger la page.',
    Global_Button_New_Livestream: 'Nouveau livestream',
    Global_Button_New_Team: 'Nouvelle équipe',

    // MESSAGES DE NOTIFICATIONS
    View: 'Voir',
    Accept: 'Accepter',
    Reject: 'Rejeter',
    Decline: 'Décliner',
    Notification_New_Tournament_Title: 'Nouveau Tournoi!',
    Notification_New_Tournament_Content_1: 'a été créé!',
    Notification_Join_Team_Title: 'veut rejoindre',
    Notification_Join_Team_Content_1: 'vous a envoyé une demande pour rejoindre',
    Notification_New_Team_Title: 'Une Nouvelle Équipe est née!',
    Notification_New_Team_Content_1: 'Félicitations',
    Notification_New_Team_Content_2: 'Votre équipe est en ligne.',
    Notification_New_Footballer_Title: 'Un Nouveau Footballeur est né!',
    Notification_New_Footballer_Content_1: 'Félicitations',
    Notification_New_Footballer_Content_2: 'Votre profil de footballeur est en ligne.',
    Notification_New_Flex_Title: 'Co-publier Flex',
    Notification_New_Flex_Content_1: 'veut co-publier un flex avec vous.',
    Notification_New_Challenge_Title: 'Nouveau Défi!',
    Notification_New_Challenge_Content_1: 'Défi',
    Notification_New_Challenge_Content_2: 'est réservé, préparez votre équipe!',
    Notification_New_Challenge_Content_3: 'Le capitaine doit accepter ou refuser le défi!',
    Notification_New_Challenge_Content_4: 'Chaque joueur doit payer',
    Notification_New_Challenge_Content_5: 'entre',
    Notification_New_Challenge_Content_6: 'rien car ce terrain est gratuit!',
    Notification_ScheduleTournamentModal_Title: 'Nouvelle réservation!',
    Notification_ScheduleTournamentModal_Content_1: 'a été réservé, consultez votre tableau de bord pour le voir.',
    Notification_Edit_Tournament_Playoff_Modal_Title: 'Nouvelle réservation!',
    Notification_Edit_Tournament_Playoff_Modal_Content_1: 'a été réservé, consultez votre tableau de bord pour le voir.',
    Notification_View_Team_Lineup_Title: 'veut rejoindre votre équipe!',
    Notification_View_Team_Lineup_Content_1: 'vous a envoyé une demande pour rejoindre votre équipe pour jouer',
    // APP COMPONENT
    App_User: 'Utilisateur',
    App_Home: 'Accueil',
    App_Teams: 'Équipes',
    App_Feeds: 'Actualités',
    App_Challenges: 'Défis',
    App_Tournaments: 'Tournois',
    App_New_Challenge: 'Nouveau défi',
    App_Find_Challenge: 'Trouver un défi',
    App_Find_Opponent: 'Trouver un adversaire',
    App_Build_Team: 'Créer une équipe',
    App_Join_Team: 'Rejoindre une équipe',
    App_New_Tournament: 'Nouveau tournoi',
    App_Add_PWA_Content: 'Télécharger Fivezer!',
    App_Add_PWA_Safari_Tap: 'Appuyez',
    App_Add_PWA_Safari_Content: "et 'Ajouter à l'écran d'accueil'",
    App_Add_PWA: 'Télécharger',
    App_New_Field: 'Ajouter un terrain',
    App_Menu_Button_Signup: 'Inscription',
    App_Menu_Button_Login: 'Connexion',
    App_New_Flex: 'Nouveau flex',

    // TOPBAR COMPONENT
    TopBar_Login: 'Connexion',
    // FOOTER COMPONENT
    Footer_All_Rights: 'Tous droits réservés.',
    Footer_Complex_Listing: 'Inscription complexe sportif',
    Footer_Legal_Mentions: 'Mentions légales',
    Footer_Legal_Mentions_Content: `
    **1. Introduction :**
       - "Bienvenue sur Fivezer, votre passerelle vers le monde du sport international et de la compétition. Chez Fivezer, nous sommes dédiés à promouvoir une communauté sportive dynamique où les consommateurs peuvent réserver des sessions sportives et où les propriétaires de complexes peuvent gérer efficacement les réservations et recevoir des paiements grâce à notre plateforme. Notre mission est de connecter les athlètes et les équipes du monde entier, en leur offrant des opportunités de mettre en avant leurs compétences et de suivre leurs performances grâce à des technologies de pointe. Nous vous invitons à nous rejoindre dans cette aventure passionnante alors que nous transformons le monde du sport."
    
    **2. Vision et Mission :**
       - Vision de Fivezer :
         - "Nous imaginons un monde où les athlètes et les équipes sportives, quel que soit leur lieu de résidence, peuvent concourir, se connecter et exceller sur la scène mondiale. Fivezer est le vecteur de cette vision, réunissant la communauté sportive et fournissant des outils pour la croissance et le développement."
       - Déclaration de mission : 
         - "Fivezer s'engage à créer un écosystème fluide et transparent où les passionnés de sport, les équipes et les athlètes peuvent s'engager, concourir et réussir."
    
    **3. Déclaration de problème :**
       - "Conscients de la nécessité d'une plateforme rationalisée qui permet aux athlètes, aux équipes et aux complexes sportifs de se connecter, de concourir et de gérer efficacement leurs activités, Fivezer s'engage à relever ce défi."
    
    **4. Informations sur l'entreprise :**
       - "Fivezer, exploité par Africantum, est une entreprise enregistrée dont le siège principal est situé en France. Vous pouvez nous contacter à contact@thebhub.io."
    
    **5. Conditions d'utilisation :**
       - En utilisant les services de Fivezer, vous acceptez de respecter les conditions d'utilisation suivantes, qui constituent un accord légalement contraignant entre vous et Africantum :
    
       **5.1. Création et gestion du compte :**
          - "Votre compte Fivezer est votre passerelle vers le monde du sport. Nous proposons une gamme complète d'outils et de fonctionnalités pour vous aider à gérer vos activités sportives, à vous connecter avec d'autres athlètes et équipes, et à améliorer vos performances. En vous inscrivant et en utilisant votre compte, vous accédez à un monde d'opportunités sportives."
    
       **5.2. Comportement des utilisateurs :**
          - Les utilisateurs doivent se conformer à toutes les lois et réglementations applicables.
          - Les activités interdites peuvent inclure le harcèlement, la tricherie, le spam et toutes les activités illégales.
    
       **5.3. Soumission de contenu :**
          - Les utilisateurs sont responsables du contenu qu'ils soumettent à la plateforme.
          - Le contenu interdit peut inclure du matériel protégé par le droit d'auteur, des discours haineux ou du contenu préjudiciable.
    
       **5.4. Confidentialité et utilisation des données :**
          - Informations sur la collecte, le stockage et l'utilisation des données utilisateur.
          - Consentement pour le traitement et le partage des données au sein de la plateforme.
    
       **5.5. Propriété intellectuelle :**
          - Protection des droits de propriété intellectuelle tant pour les utilisateurs que pour la plateforme.
          - Mécanisme de signalement des violations des droits d'auteur ou des marques de commerce.
    
       **5.6. Transactions et paiements :**
          - Conditions relatives au traitement des paiements, y compris les frais, les remboursements et les modes de paiement.
          - Responsabilités du vendeur pour des descriptions de services précises et la livraison.
    
       **5.7. Résolution des litiges :**
          - Procédures de résolution des litiges, y compris la médiation ou l'arbitrage.
          - Signalement et résolution des conflits entre les utilisateurs.
    
       **5.8. Résiliation et suspension :**
          - Conditions dans lesquelles les comptes peuvent être résiliés ou suspendus.
          - Processus d'appel pour les utilisateurs soumis à des mesures de compte.
    
       **5.9. Responsabilité et garantie :**
          - Limitations de responsabilité pour la plateforme et ses utilisateurs.
          - Clause de non-garantie pour les services et les produits.
    
       **5.10. Droit applicable et juridiction :**
          - Choix de la loi et de la juridiction pour les affaires légales.
    
       **5.11. Modification des conditions :**
          - Le droit de la plateforme de mettre à jour ou de modifier les termes et conditions.
          - Notification aux utilisateurs des changements.
    
       **5.12. Indemnisation :**
          - Les utilisateurs acceptent d'indemniser et de dégager la plateforme de toute réclamation ou responsabilité légale.
    
       **5.13. Communication et notifications :**
          - Comment la plateforme communique avec les utilisateurs, y compris les notifications par e-mail.
    
       **5.14. Avis et rétroaction des utilisateurs :**
          - Politiques relatives aux avis et à la rétroaction des utilisateurs sur la plateforme.
    
       **5.15. Mesures de sécurité :**
          - Responsabilités de l'utilisateur en matière de sécurité des comptes.
          - Mesures de la plateforme pour protéger les données des utilisateurs.
    
       **5.16. Accessibilité et disponibilité :**
          - Exigences en matière d'accessibilité de la plateforme et garanties de disponibilité.
    
       **5.17. Droits de résiliation des utilisateurs :**
          - Conditions dans lesquelles les utilisateurs peuvent résilier leurs comptes.
    
       **5.18. Directives de la communauté :**
          - Directives spécifiques à la communauté de la plateforme et règles de conduite.
    
       **5.19. Conformité aux lois :**
          - Engagement des utilisateurs à respecter toutes les lois applicables.
    
       **5.20. Divers :**
          - Toute clause légale supplémentaire ou tout avertissement spécifique à la mission de la plateforme et à sa base d'utilisateurs.
    
    **6. Politique de confidentialité :**
       - "La politique de confidentialité de Fivezer décrit comment nous collectons, stock
    
    ons et gérons les données utilisateur conformément aux lois sur la protection des données. Cela inclut l'utilisation de technologies avancées pour améliorer l'expérience sportive et protéger les données utilisateur. Les utilisateurs peuvent accéder à notre politique de confidentialité complète pour plus de détails."
    
    **7. Responsabilités de l'utilisateur :**
       - "En tant qu'utilisateur de Fivezer, vous êtes responsable de respecter les normes éthiques et légales dans vos interactions avec d'autres utilisateurs et au sein de la plateforme. Cela inclut l'abstention de toute activité frauduleuse, tricherie ou inconduite."
    
    **8. Propriété intellectuelle :**
       - "Tous les droits de propriété intellectuelle sur Fivezer, y compris, sans s'y limiter, les marques de commerce et les droits d'auteur, sont détenus par Africantum. Les utilisateurs doivent respecter ces droits, et toute violation signalée sera traitée rapidement."
    
    **9. Résolution des litiges :**
       - "En cas de litiges, les utilisateurs sont encouragés à signaler les problèmes via les canaux désignés par Fivezer. Les litiges peuvent être résolus par médiation ou arbitrage, comme indiqué dans notre processus de résolution des litiges."
    
    **10. Juridiction et loi applicable :**
        - "La juridiction pour les affaires légales liées à Fivezer est la France. Tout litige légal sera régi par les lois de la France."
    
    **11. Application et sanctions :**
        - "Fivezer se réserve le droit d'appliquer ces conditions et politiques. Les violations peuvent entraîner des sanctions, y compris la suspension ou la résiliation du compte."
    
    **12. Signalement de conduite répréhensible :**
        - "Si vous rencontrez une conduite répréhensible, une tricherie ou des activités frauduleuses sur Fivezer, veuillez les signaler immédiatement à notre équipe de support. Nous prenons la protection des utilisateurs très au sérieux et enquêterons et résoudrons rapidement de tels problèmes."
    
    **13. Références légales et conformité :**
        - "Fivezer s'engage à respecter toutes les lois nationales et internationales applicables, notamment en matière de protection des consommateurs, de commerce électronique et de protection des données."
    
    **14. Protection des données et confidentialité :**
        - "Fivezer accorde de l'importance à la protection de la vie privée et des données de ses utilisateurs. Nous nous engageons à respecter les lois sur la protection des données, notamment le Règlement général sur la protection des données (RGPD) et toutes les autres réglementations applicables."
        - "Collecte des données utilisateur : Nous collectons et traitons les données utilisateur à des fins spécifiques, notamment la gestion du compte, la communication, l'analyse des performances, la sécurité et la prévention de la fraude."
        - "Sécurité des données : Nous utilisons des mesures de sécurité robustes, notamment le chiffrement, pour protéger les données utilisateur contre tout accès non autorisé ou violation."
        - "Partage avec des tiers : Les données utilisateur peuvent être partagées avec des fournisseurs de services tiers de confiance à des fins telles que le traitement des paiements et l'analyse. Nous nous assurons que ces fournisseurs respectent également les réglementations sur la protection des données."
        - "Droits de l'utilisateur : Les utilisateurs ont le droit d'accéder, de rectifier ou de supprimer leurs données personnelles détenues par Fivezer. Les demandes peuvent être effectuées via nos canaux désignés."
        - "Cookies : Nous utilisons des cookies pour améliorer l'expérience utilisateur. Les utilisateurs peuvent gérer les préférences en matière de cookies via les paramètres de leur navigateur."
        - "En utilisant les services de Fivezer, vous consentez à la collecte, au traitement et à l'utilisation de vos données aux fins spécifiées telles que décrites dans notre Politique de confidentialité."
    
    `,
    // INPUTDESCRIPTION COMPONENT
    InputDescriptionsFeature_Descriptions_Label: 'Langues disponibles',
    InputDescriptionsFeature_Descriptions_Placeholder: 'Sélectionnez une langue',
    InputDescriptionsFeature_Add_Language: 'Ajouter la langue',
    InputDescriptionsFeature_Selected_Description_Label_1: 'Descriptions actuelles',
    InputDescriptionsFeature_Selected_Description_Label_2: 'et',
    InputDescriptionsFeature_Selected_Description_Label_3: 'descriptions',
    InputDescriptionsFeature_Selected_Description_Label_4: 'description',
    InputDescriptionsFeature_Selected_Description_Label_5: 'avec un contenu vide',
    InputDescriptionsFeature_Selected_Description_Placeholder: 'Sélectionnez une description',
    InputDescriptionsFeature_Short_Descriptions_Placeholder: 'Ajouter une courte description...',
    InputDescriptionsFeature_Selected_Description_Caption: '(contenu vide)',
    InputDescriptionsFeature_Selected_Description_Content: 'Description en',
    InputDescriptionsFeature_Selected_Short_Description_Content: 'Courte description en',
    InputDescriptionsFeature_Selected_Description_Content_Warning: "N'oubliez pas de sauvegarder votre contenu après chaque mise à jour",
    InputDescriptionsFeature_African_Languages: 'LANGUES AFRICAINES',
    InputDescriptionsFeature_Other_Languages: 'AUTRES LANGUES',
    // LOGINPAGE
    LoginPage_Hello: 'Yo',
    LoginPage_Friend: 'Baller',
    LoginPage_Welcome_Back: 'Cool de te revoir!',
    // LOGINFORM COMPONENT
    LoginForm_Login_Title: 'Connexion',
    LoginForm_Identifier: "Nom d'utilisateur / Email",
    LoginForm_Password: 'Mot de passe',
    LoginForm_Forgot_Password: 'Mot de passe oublié?',
    LoginForm_Turn_On_Location: 'Activez votre localisation pour entièrement sécuriser votre compte',
    LoginForm_Login: 'Connexion',
    LoginForm_Logging: 'Connexion en cours',
    LoginForm_No_Member_Yet: 'Pas encore membre?',
    LoginForm_Signup: "S'inscrire maintenant",
    LoginForm_Error_Login: 'Vous avez le mauvais identifiant/mot de passe, veuillez réessayer ou réinitialiser votre mot de passe',
    LoginForm_Reset_Password: 'Réinitialiser votre mot de passe',
    LoginForm_Remember_Password: 'Vous vous rappelez votre mot de passe?',
    LoginForm_Switch_User: 'Changer de compte',
    // FORGOTPASSWORDFORM COMPONENT
    ForgotPasswordForm_Identifier: 'Identifiant',
    ForgotPasswordForm_Email_Mobile: 'Email/Mobile',
    ForgotPasswordForm_Pin_Verification: 'Vérification du code PIN',
    ForgotPasswordForm_Please_Wait: "attendez s'il vous plaît...",
    ForgotPasswordForm_Sending_SMS: 'Envoi de SMS...',
    ForgotPasswordForm_Send_Via_Email: 'Envoyer le code PIN par e-mail',
    ForgotPasswordForm_Send_Via_SMS: 'Envoyer le code PIN par SMS',
    ForgotPasswordForm_Validation: 'Validation',
    ForgotPasswordForm_Is_Submitted: 'Votre mot de passe a été mis à jour, veuillez essayer de vous connecter!',
    ForgotPasswordForm_Login_Now: 'Connecte-toi',
    ForgotPasswordForm_Is_Updated: 'Votre mot de passe a été mis à jour!',
    ForgotPasswordForm_New_Password: 'Nouveau mot de passe',
    ForgotPasswordForm_New_Password_Confirmation: 'Confirmation du nouveau mot de passe',
    ForgotPasswordForm_Reset_Password: 'Réinitialiser le mot de passe',
    ForgotPasswordForm_Last_Step: 'Dernière étape',
    ForgotPasswordForm_Login_Button: 'Connexion',
    ForgotPasswordForm_Back_Button: 'Retour',
    ForgotPasswordForm_PIN_Verification_Button: 'Vérification du code PIN',
    ForgotPasswordForm_Continue_Button: 'Continuer',
    ForgotPasswordForm_Turn_On_Location: 'Activez votre localisation pour sécuriser entièrement votre compte',
    ForgotPasswordForm_Is_Email_Submitted: 'Votre email a été envoyé, veuillez vérifier votre boîte de réception',
    ForgotPasswordForm_Resend_Email: "Une erreur s'est produite. Veuillez réessayer",
    // SIGNUPFORM COMPONENT
    SignUpForm_Title: 'Inscription',
    SignUpForm_Account_Info: 'Infos du compte',
    SignUpForm_Username: "Nom d'utilisateur",
    SignUpForm_Username_Taken: 'est déjà pris',
    SignUpForm_Email_Already_Used: 'est déjà utilisé sur un autre compte',
    SignUpForm_Email_Incorrect: "Format de l'email incorrect",
    SignUpForm_Email: 'Email',
    SignUpForm_Gender: 'Genre',
    SignUpForm_DOB: 'Date de naissance',
    SignUpForm_My_Gender: 'Mon pronom est...',
    SignUpForm_Firstname: 'Prénom',
    SignUpForm_Lastname: 'Nom de famille',
    SignUpForm_New_Password: 'Nouveau mot de passe',
    SignUpForm_Mobile_Verification: 'Vérification mobile',
    SignUpForm_Mobile: 'Mobile',
    SignUpForm_Sending_SMS: 'Envoi du SMS...',
    SignUpForm_Send_SMS: 'Envoyer',
    SignUpForm_SMS_Error: "Le SMS n'a pas pu être envoyé à",
    SignUpForm_Beta_Notice: 'VERSION BETA : Voici votre CODE PIN',
    SignUpForm_Use_International_Number: "Veuillez utiliser le format international '+' ou '00'",
    SignUpForm_Great: 'Super !',
    SignUpForm_Next_Step: 'Étape suivante, la personnalisation de votre joueur de football, allons-y !',
    SignUpForm_We_Sent_Email: "Après avoir appuyé sur 'Créer joueur', nous vous enverrons un email pour vérifier votre compte à",
    SignUpForm_To_Verify: 'pour vérifier votre compte.',
    SignUpForm_Create_Account_Button: 'Créer joueur',
    SignUpForm_Last_Step: 'Dernière étape',
    SignUpForm_Login_Button: 'Connexion',
    SignUpForm_Back_Button: 'Retour',
    SignUpForm_PIN_Verification_Button: 'Vérification PIN',
    SignUpForm_Continue_Button: 'Continuer',
    SignUpForm_Turn_On_Location: 'Activez votre localisation pour sécuriser pleinement votre compte',
    SignUpForm_Already_Member: 'Déjà membre ?',
    SignUpForm_All_Done: 'Toutes les étapes sont terminées - vous avez terminé',
    SignUpForm_Loading_message: 'Création de votre compte, veuillez patienter...',

    // ONBOARDING COMPONENT
    Onboarding_Create: 'Créer',
    Onboarding_Your_Team: 'Votre',
    Onboarding_Team: 'Équipe',
    Onboarding_Create_Content: 'Créez votre équipe et votre jeu. Sélectionnez votre logo et le nom de votre équipe. Choisissez vos guerriers. Gérez votre gang.',
    Onboarding_Book: 'Réserver',
    Onboarding_Your_Arena: 'Votre',
    Onboarding_Arena: 'Arène',
    Onboarding_Book_Content: "Réservez votre terrain partout dans le monde et défiez d'autres équipes.",
    Onboarding_Climb: 'Grimpez dans',
    Onboarding_Climb_The: 'le',
    Onboarding_Climb_Ranking: 'Classement',
    Onboarding_Climb_Content: 'Le classement de Fivezer est basé sur le résultat de chaque équipe dans le monde. Gagnez vos matchs, gagnez des points, soyez le meilleur dans votre ville et atteignez le sommet du monde.',
    Onboarding_Conquer: 'Conquérez',
    Onboarding_And_Become: 'Et devenez',
    Onboarding_Legend: 'une légende',
    Onboarding_Conquer_Content: 'Jouez instinctivement. Jouez pour gagner. Jouez pour être le numéro un.',
    Onboarding_Next: 'Suivant',
    Onboarding_Back: 'Précédent',
    Onboarding_Lets_Ball: 'Go!',
    // HOME COMPONENT
    Home_Hello: 'Bonjour',
    Home_Street: 'Rue',
    Home_Culture: 'Culture',
    Home_Football: 'Football',
    Home_Aka: 'AKA',
    Home_The_Biggest: 'LA PLUS GRANDE',
    Home_Sports_Community: 'COMMUNAUTÉ iSPORTS',
    Home_Where: 'Où les meilleures équipes',
    Home_From: 'Du monde entier',
    Home_Compete: 'Défient des équipes locales et étrangères',
    Home_Anywhere: 'Partout, à tout moment',
    Home_Hashtag: '#DifferentAnimalSameBeast',
    Home_New_Challenge: 'Nouveau défi',
    Home_Throne: 'terrains à conquérir',
    Home_Find_Challenge: 'Trouvez un défi',
    Home_Upcoming_Challenges: 'défis à relever',
    Home_Upcoming_Challenge: 'défi à relever',
    Home_Find_Opponent: 'Défier un adversaire',
    Home_Challengers: 'challengers vous attendent',
    Home_Challenger: 'challenger vous attend',
    Home_Build_Team: 'Construisez votre équipe',
    Home_Become: 'Devenez une légende',
    Home_Join_Team: 'Rejoignez une équipe',
    Home_Teams: 'équipes (bons délires uniquement)',
    Home_Team: 'équipe (bons délires uniquement)',
    Home_Over: 'Plus de',
    Home_New_Tournament: 'nouveau tournoi',
    Home_New_Tournaments: 'nouveaux tournois',
    Home_Prove: 'Titres et réputation, gagnez-les tous!',
    Home_Upcoming_No_Challenges: 'Lancez le défi en premier!',
    Home_Find_No_Opponent: 'Battez-les tous!',
    Home_Find_No_Tournament: 'Organisez votre propre tournoi!',
    Home_View_Feeds: 'Lives, clips & actualités',
    Home_Enjoy_Feeds: 'Regardez des matchs du monde entier',

    // PAYSPORTSESSIONMODAL
    PaySportSessionModal_Pay: 'Payer',
    PaySportSessionModal_Title: 'Session de sport',
    PaySportSessionModal_Notification: 'Un message a été envoyé à vos joueurs avec le montant à payer. Vous recevrez une notification pour chaque paiement effectué. Vous pouvez également payer pour tout le monde ou sélectionner un joueur pour lequel vous souhaitez payer.',
    PaySportSessionModal_Squad: 'Équipe',
    PaySportSessionModal_Paid_By: 'Payé par',
    PaySportSessionModal_Pay_For_Yourself: 'Payer pour vous-même',
    PaySportSessionModal_Pay_For_Them: 'Payer pour eux',
    PaySportSessionModal_Player_To_Pay: 'joueur',
    PaySportSessionModal_Players_To_Pay: 'joueurs',
    PaySportSessionModal_Total: 'Total',
    PaySportSessionModal_Price_per_Player: 'Prix par joueur',
    PaySportSessionModal_Proceed_Pay_Button: 'Procéder au paiement',
    PaySportSessionModal_Pay_Button: 'Payer',
    PaySportSessionModal_Cancel_Button: 'Annuler',
    PaySportSessionModal_Back_Button: 'Retour',
    PaySportSessionModal_Payment_Method: 'Moyen de paiement',
    PaySportSessionModal_Stepper_Back_Button: 'Retour',
    PaySportSessionModal_Stepper_Next_Button: 'Suivant',
    PaySportSessionModal_Stepper_Finish_Button: 'Terminer',
    PaySportSessionModal_Snacks: 'Collations',
    PaySportSessionModal_Per_Player: 'Par joueur',
    PaySportSessionModal_Unpaid: 'Impayé',
    PaySportSessionModal_Paid: 'Payé',
    PaySportSessionModal_Group_Snacks: 'Collations en groupe',
    PaySportSessionModal_Individual_Snacks: 'Collations individuelles',
    PaySportSessionModal_Unpaid_Group_Snacks: 'Collations en groupe impayées',
    PaySportSessionModal_Fully_Paid_Group_Snacks: 'Collations en groupe entièrement payées',
    PaySportSessionModal_You_Paid: 'Vous avez payé !',
    // SPENDINGCODEUPDATEMODAL COMPONENT
    SpendingCodeModal_Forgot_Spending_Code: 'Code de dépense oublié ?',
    SpendingCodeModal_Email_Sent: 'Email envoyé !',
    SpendingCodeModal_Remember_Spending_Code: 'Vous souvenez-vous de votre code de dépense ?',
    SpendingCodeModal_Identifier: 'Identifiant',
    SpendingCodeModal_Email_Mobile: 'Email/Mobile',
    SpendingCodeModal_Pin_Verification: 'Vérification du code PIN',
    SpendingCodeModal_Please_Wait: 'Veuillez patienter...',
    SpendingCodeModal_Sending_SMS: 'Envoi de SMS...',
    SpendingCodeModal_Send_Via_Email: 'Envoyer le code PIN par email',
    SpendingCodeModal_Send_Via_SMS: 'Envoyer le code PIN par SMS',
    SpendingCodeModal_Validation: 'Validation',
    SpendingCodeModal_Is_Submitted: 'Votre mot de passe a été mis à jour, veuillez essayer de vous connecter !',
    SpendingCodeModal_Is_Updated: 'Votre code de dépense a été mis à jour !',
    SpendingCodeModal_New_Password: 'Nouveau code de dépense',
    SpendingCodeModal_New_Password_Confirmation: 'Confirmation du nouveau code de dépense',
    SpendingCodeModal_Reset_Password: 'Réinitialiser le code de dépense',
    SpendingCodeModal_Last_Step: 'Dernière étape',
    SpendingCodeModal_Login_Button: 'Connexion',
    SpendingCodeModal_Back_Button: 'Retour',
    SpendingCodeModal_PIN_Verification_Button: 'Vérification du code PIN',
    SpendingCodeModal_Continue_Button: 'Continuer',
    // JOINCOMPLEXNETWORK COMPONENT
    JoinComplexNetwork_1: 'Révolutionnez votre complexe avec Fivezer',
    JoinComplexNetwork_1_bis: 'Une cour de jeu mondiale des champions!',
    JoinComplexNetwork_2: 'Appel à tous les propriétaires de complexes – Fivezer est là pour transformer votre univers sportif! Dites bonjour à la Five League nouvelle génération qui amène les meilleures équipes du monde à votre porte, enflammer des compétitions électrisantes jour et nuit, où que vous soyez!',
    JoinComplexNetwork_3: 'Libérez la puissance des outils ioT de pointe – des bracelets intelligents aux ballons ensorcelants – et observez avec émerveillement les performances de vos joueurs et des statistiques à couper le souffle qui atteignent de nouveaux sommets!',
    JoinComplexNetwork_4: "Montez sur le devant de la scène, éblouissez le monde et faites de votre complexe l'épicentre de l'excellence sportive. Embrassez la renommée, la gloire et d'innombrables opportunités alors que les sponsors, les fans et les médias affluent pour assister à la naissance des champions!",
    JoinComplexNetwork_5: "Le moment est venu. Le jeu est Fivezer. Préparez-vous à réécrire les règles du sport tel que vous le connaissez. Rejoignez la ligue d'élite où la grandeur ne connaît pas de limites!",
    JoinComplexNetwork_6: 'Fivezer – Nourrir les rêves des champions, un complexe à la fois.',
    JoinComplexNetwork_6_bis: "Embrassez l'avenir. Élevez votre héritage!",
    // SENDRECOMMENDATIONMODAL COMPONENT
    SendRecommendationModal_Tell_Them: 'Parle-leur de',
    SendRecommendationModal_Do_You_Want: 'Vous voulez que votre Five préféré soit disponible sur Fivezer?',
    SendRecommendationModal_We_Add_Them: 'Dites-le au propriétaire, afin que nous puissions les ajouter à notre liste de terrains disponibles.',
    SendRecommendationModal_Send_Button: 'Envoyer',
    SendRecommendationModal_Email_Button: 'E-mail',
    SendRecommendationModal_Press_Enter: 'Appuyez sur Entrée pour en ajouter davantage.',
    // TOURNAMENT COMPONENT
    Tournament_Finale_128: 'Soixante-quatrième de finale',
    Tournament_Finale_64: 'Trente-deuxième de finale',
    Tournament_Finale_32: 'Seizième de finale',
    Tournament_Finale_16: 'Huitièmes de finale',
    Tournament_Finale_8: 'Quarts de finale',
    Tournament_Finale_4: 'Demi-finales',
    Tournament_Finale_2: 'Finale',
    Tournament_By: 'organisé par',
    // AVAILABILITIES COMPONENT
    InputSelectAvailabilitiesFeature_Title_1: 'Choisissez les disponibilités horaires',
    InputSelectAvailabilitiesFeature_Title_2: 'Vous pouvez mettre à jour vos disponibilités plus tard',
    InputSelectAvailabilitiesFeature_Title_3: 'disponibilités',
    InputSelectAvailabilitiesFeature_Title_4: 'Aucune disponibilité',
    InputSelectAvailabilitiesFeature_Title_5: 'Changer',
    InputSelectAvailabilitiesFeature_Title_6: 'disponibilités',
    InputSelectAvailabilitiesFeature_Day_1: 'Lundi',
    InputSelectAvailabilitiesFeature_Day_2: 'Mardi',
    InputSelectAvailabilitiesFeature_Day_3: 'Mercredi',
    InputSelectAvailabilitiesFeature_Day_4: 'Jeudi',
    InputSelectAvailabilitiesFeature_Day_5: 'Vendredi',
    InputSelectAvailabilitiesFeature_Day_6: 'Samedi',
    InputSelectAvailabilitiesFeature_Day_7: 'Dimanche',
    // FORMNEWTEAM COMPONENT
    FormNewTeam_Title: 'Nouvelle Équipe',
    FormNewTeam_Create_Profile: 'Créer un profil de footballeur',
    FormNewTeam_Sport_Type_Title: 'Sport',
    FormNewTeam_Sport_Type_Coming: 'Bientôt disponible',
    FormNewTeam_Category_Title: 'Catégorie',
    FormNewTeam_Category_Subtitle_1: 'Seules les équipes de LIGUE peuvent accumuler des points et être classées',
    FormNewTeam_Category_Subtitle_2: 'Vous avez déjà',
    FormNewTeam_Category_Subtitle_3: 'Vous jouez déjà pour',
    FormNewTeam_Category_Subtitle_4: 'dans la ligue',
    FormNewTeam_Category_Subtitle_5: 'Vous avez déjà',
    FormNewTeam_Category_Subtitle_6: 'dans la ligue',
    FormNewTeam_Name_Title: "Nom de l'équipe",
    FormNewTeam_Name_Placeholder: 'Paris Saint Germain',
    FormNewTeam_Name_1: 'Le nom doit comporter au moins 4 caractères',
    FormNewTeam_Name_2: 'Vous ne devez utiliser que des lettres, des chiffres ou des underscores',
    FormNewTeam_Name_3: 'est déjà pris',
    FormNewTeam_Shortname_Title: 'Version courte (3 lettres)',
    FormNewTeam_Shortname_Placeholder: 'PSG',
    FormNewTeam_Shortname_1: 'Le nom doit comporter au moins 3 caractères',
    FormNewTeam_Shortname_2: 'Vous ne devez utiliser que des lettres, des chiffres ou des underscores',
    FormNewTeam_Privacy_Title: 'Confidentialité ?',
    FormNewTeam_Privacy_1: "Oui, c'est une équipe privée 🤐",
    FormNewTeam_Privacy_2: "Non, c'est une équipe ouverte au public 🤗",
    FormNewTeam_Bio_Title: 'Bio (multi-langues)',
    FormNewTeam_Area_Title: 'Quelle zone représentez-vous ?',
    FormNewTeam_Area_1: 'Nom de la zone',
    FormNewTeam_Area_2: 'São Paulo',
    FormNewTeam_Button_Create: "Créer l'équipe",
    FormNewTeam_Create_Profile_Label: 'Vous devez avoir au moins un profil de footballeur pour créer une équipe',

    // FORMEDITTEAM COMPONENT
    FormEditTeam_Title: "Modifier l'équipe",
    FormEditTeam_See_Profile: 'Voir le profil',
    FormEditTeam_Sport_Type_Title: 'Sport',
    FormEditTeam_Sport_Type_Coming: 'Bientôt disponible',
    FormEditTeam_Name_Title: "Nom de l'équipe",
    FormEditTeam_Name_Placeholder: 'Paris Saint Germain',
    FormEditTeam_Name_1: 'Le nom doit comporter au moins 4 caractères',
    FormEditTeam_Name_2: 'Vous devez utiliser uniquement des lettres, des chiffres ou des underscores',
    FormEditTeam_Name_3: 'est déjà pris',
    FormEditTeam_Shortname_Title: 'Version courte (3 lettres)',
    FormEditTeam_Shortname_Placeholder: 'PSG',
    FormEditTeam_Shortname_1: 'Le nom doit comporter au moins 3 caractères',
    FormEditTeam_Shortname_2: 'Vous devez utiliser uniquement des lettres, des chiffres ou des underscores',
    FormEditTeam_Privacy_Title: 'Confidentialité ?',
    FormEditTeam_Privacy_1: "Oui, c'est une équipe privée 🤐",
    FormEditTeam_Privacy_2: "Non, c'est une équipe ouverte au public 🤗",
    FormEditTeam_Bio_Title: 'Bio (multi-langues)',
    FormEditTeam_Area_Title: 'Quelle zone représentez-vous ?',
    FormEditTeam_Area_1: 'Nom de la zone',
    FormEditTeam_Area_2: 'São Paulo',
    FormEditTeam_View_Inactive_1: 'Voir',
    FormEditTeam_View_Inactive_2: 'ancien',
    FormEditTeam_View_Inactive_3: 'joueurs',
    FormEditTeam_View_Inactive_4: 'joueur',

    // FOOTBALLTEAM COMPONENT
    FootballTeam_Error_1: 'Nous sommes désolés, mais il semble que nous ne pouvons pas trouver cette équipe',
    FootballTeam_Alert_1: 'Votre équipe doit avoir au moins un capitaine, sinon vous ne pouvez pas jouer de matchs',
    FootballTeam_Alert_2: 'Votre équipe doit avoir au moins un joueur et un capitaine, sinon vous ne pouvez pas jouer de matchs',
    FootballTeam_Button_Edit: "Modifier l'équipe",
    FootballTeam_Squad_Title: 'Équipe',
    FootballTeam_Remove_Captain: 'retirer le capitaine',
    FootballTeam_Promote_Captain: 'Promouvoir capitaine',
    FootballTeam_Choose_Number: 'Choisir le numéro',
    FootballTeam_Next_Challenges_Title: 'Prochains Défis',
    FootballTeam_No_Challenges: "Aucun défi pour l'instant",
    FootballTeam_Start_New_Challenge: 'Commencez-en un maintenant',
    FootballTeam_Last_Challenges: 'Derniers Défis',
    FootballTeam_No_Players: "Aucun joueur pour l'instant",
    FootballTeam_Add_them: 'Ajoutez-les maintenant',
    FootballTeam_No_Lineups: "Aucune composition pour l'instant",
    FootballTeam_Create_Lineup: 'Créez-en une maintenant',
    FootballTeam_Managers_Title: 'Managers',
    FootballTeam_Start_New_Manager_Quote: `aka Football Manager ${new Date().getFullYear()}`,
    FootballTeam_New_Player_Quote: 'Mode Carrière',

    // PROFILE COMPONENT
    Profile_Share_1: 'Partager le profil',
    Profile_Share_2: 'Profil Fivezer',
    Profile_Share_3: 'Trouver',
    Profile_Share_4: 'le profil Fivezer de',
    Profile_Button_Edit: 'Modifier le profil',
    Profile_Button_New_Footballer: 'Nouveau footballeur',
    Profile_Button_New_Team: 'Nouvelle équipe',
    Profile_Button_New_Field: 'Nouveau terrain',
    Profile_Button_New_Tournament: 'Nouveau tournoi',
    Profile_Button_Settings: 'Paramètres',
    Profile_View_Card_1: 'Voir',
    Profile_View_Card_2: 'Carte FZ',
    Profile_Tab_Overview: 'Notifications',
    Profile_Tab_Team: 'Équipes',
    Profile_Tab_Calendar: 'Calendrier',
    Profile_Tab_Statistics: 'Statistiques',
    Profile_Tab_History: 'Transféres',
    Profile_Tab_Fields: 'Terrains publiés',
    Profile_Tab_Flex: 'Flexs',
    Profile_Create_Team_1: 'Pour créer une équipe, vous devez vérifier votre email',
    Profile_Create_Team_2: 'Créez votre équipe',
    Profile_Pick_Profile: 'Choisir un profil',
    Profile_No_Teams: 'Aucune équipe',
    Profile_Missing_Profile: "n'a pas encore de profil sportif",

    // FOOTBALLCARD COMPONENT
    FootballCard_Button_Share: 'Partager la carte',
    FootballCard_Button_Back: 'Retour',
    FootballCard_Button_Jubilee: 'Faire votre jubilé',
    FootballCard_Button_Edit: 'Modifier le profil de football',
    FootballCard_Button_Share_Profile_1: 'Partager le profil',
    FootballCard_Profile_Status: 'Le profil est',
    // FORMNEWTOURNAMENT COMPONENT
    FormNewTournament_Title: 'Nouveau tournoi',
    FormNewTournament_Name: 'Nom du tournoi',
    FormNewTournament_Name_Placeholder: 'Tournoi Mamba 5',
    FormNewTournament_Name_Helper_1: 'Le nom doit comporter au moins 4 caractères',
    FormNewTournament_Name_Helper_2: 'Vous ne devez utiliser que des lettres, des chiffres ou des underscores',
    FormNewTournament_Sport_Type_Coming: 'Bientôt disponible',
    FormNewTournament_Descriptions: 'Descriptions',
    FormNewTournament_Cost_Title: 'Coût par équipe',
    FormNewTournament_Cost_Value: 'Coût',
    FormNewTournament_Cost_Currency: 'Devise',
    FormNewTournament_Trophy: 'Trophée',
    FormNewTournament_Trophies: 'Trophées',
    FormNewTournament_Privacy_Title: 'Confidentialité ?',
    FormNewTournament_Privacy_Content_1: "Oui, c'est un événement privé 🤐",
    FormNewTournament_Privacy_Content_2: "Non, c'est ouvert au public 🤗",
    FormNewTournament_Organisator_Title: 'Organisateur principal',
    FormNewTournament_Organisator_Label: 'Organisateur principal',
    FormNewTournament_Organisator_Placeholder: 'Organisateur...',
    FormNewTournament_Organisators_Title: 'Organisateurs',
    FormNewTournament_Organisators_Label: 'Organisateurs',
    FormNewTournament_Organisators_Placeholder: 'Organisateurs...',
    FormNewTournament_Managers_Title: 'Responsables',
    FormNewTournament_Managers_Label: 'Responsables',
    FormNewTournament_Managers_Placeholder: 'Responsables...',
    FormNewTournament_Settings_Title: 'Paramètres du tournoi',
    FormNewTournament_Settings_Groups_1: 'Oui, il y a une phase de groupe 🤐',
    FormNewTournament_Settings_Groups_2: "Non, il n'y a pas de phase de groupe 🤗",
    FormNewTournament_Settings_Playoff_Label: 'Phase éliminatoire',
    FormNewTournament_Settings_Playoff_Content_1: 'Votre tournoi aura',
    FormNewTournament_Settings_Playoff_Content_2: 'équipes',
    FormNewTournament_Settings_Playoff_Content_3: 'avec',
    FormNewTournament_Settings_Playoff_Content_4: 'groupes de 4 équipes où les 2 premières équipes se qualifieront pour la phase finale.',
    FormNewTournament_Dates_Title: 'Dates',
    FormNewTournament_Location_Title: 'Lieu principal',
    FormNewTournament_Button_New_Tournament: 'Créer le tournoi',
    // FORMEDITTOURNAMENT
    FormEditTournament_Title: 'Modifier le tournoi',
    // TOURNAMENTS COMPONENT
    Tournaments_No_Tournaments_Found: 'Aucun tournoi trouvé, veuillez réessayer',
    Tournaments_No_Tournament_In: 'Pas de tournois en',
    Tournaments_No_Tournament_Yet: "pour l'instant",
    Tournaments_Meta_Title: 'Tournois',
    Tournaments_Candidate: 'candidat',
    Tournaments_Candidates: 'candidats',

    // TOURNAMENT COMPONENT
    Tournament_Meta_Title: 'Tournoi',
    Tournament_Not_Found: 'Tournoi non trouvé',
    Tournament_Not_Found_Caption: "Nous sommes désolés, mais il semble que ce tournoi ne soit pas disponible, veuillez vérifier l'orthographe du nom.",
    Tournament_Button_Edit: 'Modifier le tournoi',
    Tournament_Button_New_Sponsorship: 'Nouveau parrainage',
    Tournament_Team_Candidates: 'Équipes candidates',
    Tournament_Team_Selected: 'Équipe sélectionnée',
    Tournament_Register_Unregister: 'Inscrire/Désinscrire votre équipe',
    Tournament_Select_Unselect: 'Sélectionner/Désélectionner une équipe',
    Tournament_Validate_Teams: 'Valider la sélection des équipes',
    Tournament_Confirm_Teams: 'Confirmez-vous les équipes sélectionnées?',
    Tournament_Confirm_Teams_Yes: 'Oui',
    Tournament_Confirm_Teams_No: 'Non',
    Tournament_Kick_Off_Group_Title: 'Lancer les matchs de groupe du tournoi',
    Tournament_Kick_Off_Group_Caption_1: 'Confirmez-vous le lancement des matchs de groupe de votre tournoi?',
    Tournament_Kick_Off_Group_Caption_2: "Cette action est irréversible, assurez-vous d'avoir programmé TOUS LES MATCHS",
    Tournament_Kick_Off_Group_Button_Go: 'Allons-y',
    Tournament_Kick_Off_Group_Button_Wait: 'Non, attendez',
    Tournament_Kick_Off_Finals_Title: 'Lancer les matchs de finale du tournoi',
    Tournament_Kick_Off_Finals_Caption_1: 'Confirmez-vous le lancement des matchs de finale de votre tournoi?',
    Tournament_Kick_Off_Finals_Caption_2: "Cette action est irréversible, assurez-vous d'avoir ajouté TOUS LES SCORES DES MATCHS",
    Tournament_Kick_Off_Finals_Button_Go: 'Allons-y',
    Tournament_Kick_Off_Finals_Button_Wait: 'Non, attendez',
    Tournament_Cost_Per_Team: 'Équipe',
    Tournament_Trophies_Title: 'Trophées',
    Tournament_Trophies_Assign: 'Attribuer un trophée',
    Tournament_Overview_Tab: "Vue d'ensemble",
    Tournament_Boards_Tab: 'Tableaux',
    Tournament_Sponsors_Tab: 'Sponsors',
    Tournament_Sponsor_For: 'Sponsor pour',
    Tournament_Button_Schedule_Game: 'Programmer un match',
    Tournament_Button_No_Schedule_Game: 'Aucun match programmé',

    ViewTournamentPlayoffGameModal_Button_Add_Score: 'Ajouter le score final',
    ViewTournamentPlayoffGameModal_Score_Added: 'Score ajouté',
    ViewTournamentPlayoffGameModal_Game_Result: 'Résultats du match',
    ViewTournamentPlayoffGameModal_Private: 'Ceci est un événement privé',

    // TOURNAMENTPLAYOFF COMPONENT
    TournamentPlayoff_Click_Schedule: 'cliquez pour programmer',
    TournamentPlayoff_Team: 'Équipe',
    TournamentPlayoff_Match: 'Match',
    // TOURNAMENTGROUPS COMPONENT
    Tournament_Groups_No_Games_Yet: "Aucun match programmé pour l'instant",
    Tournament_Groups_No_Teams: "Pas d'équipes",
    Tournament_Groups_Button_Schedule: 'Programmer un match',
    Tournament_Groups_Button_Add_Team: 'Ajouter une équipe',
    Tournament_Groups_Games: 'Matchs de',
    // INPUTTROPHIESFEATURE COMPONENT
    InputTrophiesFeature_Rank_Label: 'Rang du trophée',
    InputTrophiesFeature_Name_Label: 'Nom du trophée',
    InputTrophiesFeature_Name_Placeholder: '1ère place du Tournoi FZ World',
    InputTrophiesFeature_Name_Helper_1: 'Le nom doit comporter au moins 4 caractères',
    InputTrophiesFeature_Name_Helper_2: 'Vous ne devez utiliser que des lettres, des chiffres ou des underscores',
    InputTrophiesFeature_Note_Label: 'Note du trophée',
    InputTrophiesFeature_Note_Placeholder: 'Un trophée offert par les sponsors X Y Z',
    InputTrophiesFeature_Add_Trophy: 'Ajouter un trophée',
    // GPSMAPFEATURE COMPONENT
    GPSMapFeature_1: "Veuillez localiser l'adresse ci-dessus sur la carte",
    GPSMapFeature_2: 'Cliquez sur le marqueur pour le déplacer et soyez aussi précis que possible !',
    // CHALLENGEFILTERSMODAL COMPONENT
    ChallengeFiltersModal_When_Title: 'Quand',
    ChallengeFiltersModal_Where_Title: 'Où',
    ChallengeFiltersModal_No_Complex_Selected: 'Aucun complexe sélectionné',
    ChallengeFiltersModal_Field_Label: 'Terrain',
    ChallengeFiltersModal_Search_Complex_Label: 'Rechercher un complexe',
    ChallengeFiltersModal_Search_Complex_Placeholder: 'Écrire un nom de complexe',
    ChallengeFiltersModal_Button_Results: 'Voir les résultats',
    // SETTINGS COMPONENT
    Settings_Title: 'Configurations',
    Settings_Account: 'Compte',
    Settings_Wallets: 'Portefeuilles',
    Settings_Languages: 'Langues',
    Settings_Helpdesk: 'Support',
    Settings_Legal_Mentions: 'Mentions légales',
    Settings_Logout: 'Déconnexion',
    Settings_Logging_Out: 'Déconnexion en cours...',
    Settings_Blocked_Accounts: 'Comptes bloqués',
    Settings_Accounts_Title: 'Compte',
    Settings_Contact_Us_Title: 'Contactez-nous',
    Settings_Contact_Us_Content: `
            Chez Fivezer, nous sommes là pour vous aider avec toutes les questions, préoccupations ou commentaires que vous pourriez avoir concernant notre plateforme communautaire sportive. Vous pouvez nous contacter par e-mail, et notre équipe répondra rapidement à vos demandes.

            E-mail du support client :
            Pour le support client, y compris les problèmes liés aux comptes, l'assistance technique ou les demandes générales, veuillez nous envoyer un e-mail à :
            Email : contact@thebhub.io
            
            E-mail des demandes commerciales :
            Si vous avez des demandes commerciales, des opportunités de partenariat ou des propositions de collaboration liées à Fivezer, veuillez contacter notre équipe de développement commercial à :
            Email : contact@thebhub.io
            
            E-mail des demandes générales :
            Pour des questions générales sur Fivezer, notre plateforme ou pour commencer en tant qu'enthousiaste sportif ou équipe, vous pouvez envoyer un e-mail à notre équipe des demandes générales à :
            Email : contact@thebhub.io
            
            E-mail des commentaires et suggestions :
            Nous apprécions vos commentaires et suggestions pour améliorer l'expérience Fivezer. Si vous avez des idées ou des recommandations, veuillez les partager avec nous à :
            Email : contact@thebhub.io
            
            Délai de réponse :
            Nous nous efforçons de répondre à vos e-mails le plus rapidement possible. Nos équipes dédiées sont là pour vous fournir le soutien et les informations dont vous avez besoin pour profiter au mieux de votre expérience Fivezer.
            
            Merci de choisir Fivezer comme votre destination privilégiée dans le monde du sport et de la compétition internationale. Nous sommes impatients de vous aider par e-mail.
          `,
    Settings_Legal_Mentions_Title: 'Mentions légales',

    // FORMNEWFOOTBALLER
    FormNewFootballer_Title: 'Nouveau Footballeur',
    FormNewFootballer_Existing_Footballer: 'Vous avez déjà un profil de footballeur',
    FormNewFootballer_See_Existing_Footballer: 'Voir le profil',
    FormNewFootballer_Alias_Label: 'Nom du footballeur',
    FormNewFootballer_Alias_Placeholder: 'Pelé',
    FormNewFootballer_Nickname_Label: 'Surnom',
    FormNewFootballer_Nickname_Placeholder: 'O Rei',
    FormNewFootballer_Bio_Title: 'Bio (multi-langues)',
    FormNewFootballer_Which_Area: 'Quelle quartier représentez-vous ?',
    FormNewFootballer_Area_Title_Label: 'Nom du quartier',
    FormNewFootballer_Area_Title_Placeholder: 'São Paulo',
    FormNewFootballer_Position_Title: 'Position',
    FormNewFootballer_Jersey_Title: 'Numéro de maillot',
    FormNewFootballer_Foots_Title: 'Pied préféré',
    FormNewFootballer_Button_Create: 'Créer le Footballeur',
    // FORMEDITFOOTBALLER
    FormEditFootballer_Title: 'Modifier le footballeur',
    // FORMEDITPROFILE
    FormEditProfile_Title: 'Modifier le profil',
    FormEditProfile_Basic_Title: 'Informations de base',
    FormEditProfile_Username_Label: "Nom d'utilisateur",
    FormEditProfile_Username_1: 'est déjà pris',
    FormEditProfile_Username_2: 'Seules les lettres, les chiffres et _ sont autorisés',
    FormEditProfile_Email_Label: 'Email',
    FormEditProfile_Email_1: 'est déjà utilisé sur un autre compte',
    FormEditProfile_Firstname_Label: 'Prénom',
    FormEditProfile_Lastname_Label: 'Nom de famille',
    FormEditProfile_DOB_Label: 'Date de naissance',
    FormEditProfile_Bio_Label: 'Bio',
    FormEditProfile_Bio_1: 'Vous devez supprimer',
    FormEditProfile_Bio_2: 'caractères',
    FormEditProfile_Bio_3: 'caractère',
    FormEditProfile_Mobile_Label: 'Mobile',
    FormEditProfile_Mobile_1: 'Vérifier le code PIN',
    FormEditProfile_Mobile_2: 'Mobile vérifié avec succès !',
    FormEditProfile_Passwords_Title: 'Mots de passe',
    FormEditProfile_Passwords_Label: 'Nouveau mot de passe',
    FormEditProfile_Passwords_1: 'Écrire un nouveau mot de passe de compte',
    FormEditProfile_Passwords_2: 'Nouveau mot de passe de dépense',
    FormEditProfile_Passwords_3: 'Mot de passe de dépense mis à jour !',
    FormEditProfile_KYC_Title: "Vérification de l'identité personnelle",
    FormEditProfile_ID_Type_Label: "Type d'identité",
    FormEditProfile_ID_Number_Label: "Numéro d'identité",
    FormEditProfile_New_KYC_Title: 'Faire une nouvelle vérification d’identité ?',
    FormEditProfile_New_KYC_Label_1: 'Oui !',
    FormEditProfile_New_KYC_Label_2: 'Non !',
    FormEditProfile_New_KYC_Exp_Label: "Expiration de l'identité",
    FormEditProfile_New_KYC_Issuer_Country_Label: "Pays émetteur de l'identité",
    FormEditProfile_New_KYC_Issuer_Label: 'Autorité émettrice',
    FormEditProfile_New_KYC_Issuer_Placeholder: "Saisir le nom de l'autorité émettrice",
    FormEditProfile_Preferenced_Sport_Title: 'Sports préférés',
    FormEditProfile_Preferenced_Sport_Label: 'Sports',
    FormEditProfile_Preferenced_Sport_1: 'Aucun sport sélectionné',
    FormEditProfile_Locations_Title: 'Lieux',
    FormEditProfile_Button_Add_Location: 'Ajouter une adresse',
    // TOURNAMENTS
    Tournaments_New_Tournaments_Tab: 'Tournois à venir',
    Tournaments_Old_Tournaments_Tab: 'Tournois passés',
    // FORMNEWCHALLENGE COMPONENT
    FormNewChallenge_Title: 'Nouveau défi',
    FormNewChallenge_No_Team_Caption_1: "Amical ou non, SEUL le capitaine de l'équipe peut créer un défi.",
    FormNewChallenge_No_Team_Caption_2: 'Alors, nommez un capitaine dans votre équipe et revenez',
    FormNewChallenge_No_Team_Caption_3: 'Et ne vous inquiétez pas, même si vous êtes seul, il y a plein de joueurs autour qui vous rejoindront!',
    FormNewChallenge_No_Team_Button_New_Team: 'Créez votre équipe maintenant',
    FormNewChallenge_Challenger_Label_1: 'En tant que',
    FormNewChallenge_Challenger_Label_2: 'qui?',
    FormNewChallenge_Challenger_Placeholder: 'Sélectionnez votre équipe',
    FormNewChallenge_Category_Title: 'Catégorie',
    FormNewChallenge_Category_Caption_League: "Un match de ligue est simple, c'est vous contre eux, les gagnants obtiennent les points et les perdants les coups de tacle",
    FormNewChallenge_Category_Caption_Friendly: "Un match amical est juste un jeu pour s'amuser, pas de pression, pas de points, juste du plaisir et des coups de tacle",
    FormNewChallenge_Category_Caption_Matchmaker: 'Le Matchmaker est un match amical qui convoque des joueurs mercenaires pour compléter vos équipes et jouer ensemble, un moyen cool de se faire de nouveaux amis',
    FormNewChallenge_Name_Label: 'Nom du défi',
    FormNewChallenge_Name_Placeholder: 'Votre équipe',
    FormNewChallenge_Name_1: 'Le nom doit comporter au moins 4 caractères',
    FormNewChallenge_Name_2: 'Vous devez utiliser UNIQUEMENT des lettres, des chiffres ou des underscores',
    FormNewChallenge_Your_Lineup_Title: 'Votre composition',
    FormNewChallenge_Your_Lineup_Label_1: 'Créer une nouvelle composition',
    FormNewChallenge_Your_Lineup_Label_2: 'Sélectionner une composition existante',
    FormNewChallenge_Team_Selected: 'Aucun coéquipier sélectionné',
    FormNewChallenge_New_Lineup_Label: 'Composez votre nouvelle composition',
    FormNewChallenge_New_Lineup_Placeholder: "Écrire un alias d'équipe",
    FormNewChallenge_Edit_Lineup_Label: 'Compositions existantes',
    FormNewChallenge_Edit_Lineup_Placeholder: 'Sélectionner un terrain',
    FormNewChallenge_Against_Title: 'Contre ?',
    FormNewChallenge_Against_Label: 'Aucun adversaire sélectionné',
    FormNewChallenge_Opponent_Title: 'Adversaire',
    FormNewChallenge_Opponent_Placeholder: "Écrire un nom ou un ID d'équipe",
    FormNewChallenge_Privacy_Title: 'Confidentialité ?',
    FormNewChallenge_Privacy_Caption_1: "Oui, c'est privé 🤐",
    FormNewChallenge_Privacy_Caption_2: "Non, c'est ouvert au public 🤗",
    FormNewChallenge_How_Long_Title: 'Combien de temps ?',
    FormNewChallenge_How_Long_Hour: 'heure',
    FormNewChallenge_How_Long_Hours: 'heures',
    FormNewChallenge_When_Title: 'Quand ?',
    FormNewChallenge_Recurring_Label_1: 'Planifiez-le plusieurs fois 🖐',
    FormNewChallenge_Recurring_Label_2: 'Planifiez-le une fois 👆',
    FormNewChallenge_Recurring_Label_3: 'Tous les',
    FormNewChallenge_Start_Date_1: 'Premier jour (début)',
    FormNewChallenge_Start_Date_2: 'Date de début',
    FormNewChallenge_End_Date_1: 'Premier jour (fin)',
    FormNewChallenge_End_Date_2: 'Date de fin',
    FormNewChallenge_Last_Day: 'Dernier jour',
    FormNewChallenge_Last_Day_Label_1: 'Vous voulez jouer',
    FormNewChallenge_Last_Day_Label_2: 'fois',
    FormNewChallenge_Last_Day_Label_3: 'fois',
    FormNewChallenge_Last_Day_Label_4: "par semaine jusqu'à",
    FormNewChallenge_Where_Title: 'Où ?',
    FormNewChallenge_Complexes_Label: 'Rechercher un complexe',
    FormNewChallenge_Complexes_Placeholder: 'Écrire un nom de complexe',
    FormNewChallenge_Complexes_No_Selected: 'Aucun complexe sélectionné',
    FormNewChallenge_Field_Label: 'Terrain',
    FormNewChallenge_Field_Placeholder: 'Sélectionnez un terrain',
    FormNewChallenge_Fields_Already_Booked: 'DÉJÀ RÉSERVÉ',
    FormNewChallenge_Fields_Current_Booking: 'RÉSERVATION ACTUELLE',
    FormNewChallenge_Fields_Hour: 'heure',
    FormNewChallenge_Fields_Free: 'LIBRE',
    FormNewChallenge_Fields_Manual_Booking: 'Réservation manuelle',
    FormNewChallenge_Fields_Manual_Booking_Caption: "n'est pas inscrit sur Fivezer, vous devez donc les appeler pour réserver votre session.",
    FormNewChallenge_Button_Create: 'Créer un défi',
    // FORMEDITCHALLENGE COMPONENT
    FormEditChallenge_Title: 'Modifier le défi',
    // CHALLENGE COMPONENT
    Challenge_Meta_Title: 'Défi',
    Challenge_Payment_Caption_1: "Vous n'avez pas encore payé votre place dans ce défi",
    Challenge_Button_Accept: 'Accepter',
    Challenge_Button_Decline: 'Refuser',
    Challenge_Accept_Challenge: 'Acceptez-vous ce défi?',
    Challenge_Button_Edit: 'Modifier le défi',
    Challenge_Private_Event: 'Ceci est un événement privé',
    Challenge_Button_Results: 'Résultats du jeu',
    Challenge_Group_Order_Label: 'Ordre de groupe',
    Challenge_Button_Retry: 'Réessayer le paiement',
    Challenge_Personal_Order_Label: 'Commande personnelle',
    Challenge_Partcially_Paid_1: "En attente de la confirmation de l'adversaire",
    Challenge_Partcially_Paid_2: 'Ajouter le score final',
    Challenge_Button_Rematch: 'Revanche',
    // CHALLENGES COMPONENT
    Challenges_Meta_Title: 'Défis',
    Challenges_Tab_Challenges: 'Défis',
    Challenges_Tab_Opponents: 'Adversaires',
    Challenges_No_Challenges_Found: 'Aucun défi trouvé, veuillez réessayer',
    Challenges_No_Challenges_In: 'Aucun défi en',
    Challenges_No_Challenges_Yet: "pour l'instant",
    // FIELD COMPONENT
    Field_Meta_Title: 'Terrain',
    Field_Not_Found: 'Terrain non trouvé',
    Field_Not_Found_Caption: "Nous sommes désolés, mais il semble que ce terrain n'est pas disponible, veuillez vérifier l'orthographe du nom.",
    Field_Button_Edit: 'Modifier le terrain',
    Field_Sport_Type_Title: 'Sport',
    Field_Type_Label: 'Type',
    Field_Size_Label: 'Taille',
    Field_Cost_Label: 'Coût',
    Field_Equipments_Title: 'Équipements',
    Field_No_Equipments: "Pas d'équipements",
    Field_No_Pictures: 'Pas de photos',
    // FORMNEWFIELD MODAL
    FormNewField_Title: 'Nouveau terrain',
    FormNewField_Location: 'Emplacement',
    FormNewField_Descriptions: 'Descriptions (multilingues)',
    FormNewField_Button_Add_Descriptions: 'Ajouter une description',
    FormNewField_No_Description: 'Pas de description',
    FormNewField_Pictures_Title: 'Photos',
    FormNewField_Name_Label: 'Nom',
    FormNewField_Name_Placeholder: 'Écrire le nom du terrain',
    FormNewField_Button_Create: 'Ajouter terrain',
    // FORMEDITFIELD MODAL
    FormEditField_Title: 'Modifier le terrain',
    // REQUESTMATCHMAKING COMPONENT
    RequestMatchMaking_Meta_Title: 'Recherche de match',
    RequestMatchMaking_Not_Found_1: 'Recherche de match',
    RequestMatchMaking_Not_Found_2: 'non trouvé',
    RequestMatchMaking_Not_Found_3: "Nous sommes désolés mais il semble que cette demande ne soit pas disponible, veuillez vérifier l'orthographe du nom ou utiliser notre",
    RequestMatchMaking_Not_Found_4: 'moteur de recherche',
    RequestMatchMaking_Not_Found_5: '.',
    RequestMatchMaking_Type_Label: 'Type',
    RequestMatchMaking_Button_Leave_Game: 'Quitter le jeu',
    RequestMatchMaking_Button_Join_Game: 'Rejoindre le jeu',
    RequestMatchMaking_Players: 'joueurs',
    RequestMatchMaking_Player: 'joueur',
    RequestMatchMaking_Joined_Players: 'a rejoint',
    RequestMatchMaking_Joined_Player: 'ont rejoint',
    // MANAGESCORE MODAL
    ManageScore_Title: 'Résultats finaux',
    ManageScore_Intro_1: 'Maintenant que votre défi est terminé, vous pouvez ajouter les scores ainsi que les statistiques de vos joueurs.',
    ManageScore_Intro_2: 'Pour rappel, les deux capitaines doivent confirmer le score pour valider les statistiques.',
    ManageScore_BG_Title_1: 'Finale',
    ManageScore_BG_Title_2: 'Résultats',
    ManageScore_Button_Add_Scores: 'Ajouter les scores',
    ManageScore_Button_Unconfirm: 'Déconfirmer',
    ManageScore_Button_Confirm: 'Confirmer le score final',
    ManageScore_Button_Save_Continue: 'Enregistrer et continuer plus tard',
    ManageScore_Button_Continue: 'Continuer',
    ManageScore_Confirm_Score_Content: 'Confirmez-vous le résultat final du défi?',
    ManageScore_Confirm_Score_Button_Yes: 'Oui',
    ManageScore_Confirm_Score_Button_No: 'Non',
    ManageScore_Added_Score_Info_1: 'Vous avez ajouté tous les buts et passes décisives de',
    ManageScore_Added_Score_Info_2: 'Maintenant, vous pouvez soit confirmer les résultats',
    ManageScore_Added_Score_Info_3: 'ou',
    ManageScore_Added_Score_Info_4: 'vous pouvez enregistrer et continuer plus tard.',
    ManageScore_Scorer_Label: 'Buteur',
    ManageScore_NB_Scorer_Label_1: 'Buts marqués par',
    ManageScore_NB_Scorer_Label_2: 'joueur',
    ManageScore_Selected_Passer_Label_1: 'Un joueur',
    ManageScore_Selected_Passer_Label_2: 'buts',
    ManageScore_Selected_Passer_Label_3: 'but',
    ManageScore_Selected_Passer_Label_4: '& a reçu',
    ManageScore_Selected_Passer_Label_5: 'passes décisives',
    ManageScore_Selected_Passer_Label_6: 'passe décisive',
    ManageScore_Selected_Scorer_Caption_1: 'Vous pouvez ajouter toutes les passes décisives',
    ManageScore_Selected_Scorer_Caption_2: "qu'un joueur",
    ManageScore_Selected_Scorer_Caption_3: 'a reçu',
    ManageScore_Selected_Scorer_Label: 'Passeur',
    ManageScore_NB_Assist_Label_1: 'Passes décisives par',
    ManageScore_NB_Assist_Label_2: 'joueur',
    ManageScore_Button_Confirm_Scorer: 'Confirmer le buteur',
    ManageScore_Button_Add_Assist: 'Ajouter une passe décisive',
    ManageScore_MOTM_Caption_1: 'Hommes du Match',
    ManageScore_MOTM_Caption_2: 'Homme du Match',
    ManageScore_MOTM_Intro_1: 'Si un joueur de',
    ManageScore_MOTM_Intro_2: "l'équipe adverse",
    ManageScore_MOTM_Intro_3: "vous a impressionné, nommez-le 'Homme du Match'.",
    ManageScore_MOTM_Intro_4: 'Votre adversaire fera de même pour votre équipe',
    ManageScore_Selected_MOTM_Caption_1: 'Vous avez nommé',
    ManageScore_Selected_MOTM_Label: 'Homme du Match',
    ManageScore_No_MOTM: 'PERSONNE',
    // SWITCHLANGUAGEBUTTON COMPONENT
    SwitchLanguageButton_Title: 'Sélectionner une langue',
    SwitchLanguageButton_Current_Lang: 'Langue actuelle',
    SwitchLanguageButton_Button_Title: 'Langues',
    // NEWCONVERSATION MODAL
    New_Conversation_Title: 'Nouvelle conversation',
    New_Conversation_Title_Label: 'Titre',
    New_Conversation_Description_Label: 'Sujet (optionnel)',
    New_Conversation_Description_Placeholder: 'Quel est/Quels sont le(s) sujet(s)/objectif(s) de la conversation',
    New_Conversation_Description_Helper_1: 'La note doit avoir au moins 0 caractères',
    New_Conversation_Description_Helper_2: 'Il y a un caractère non autorisé dans',
    New_Conversation_Author_Label: 'Rejoindre en tant que',
    New_Conversation_Participants_Label: 'Participants',
    New_Conversation_Participants_Placeholder: 'Sélectionner un participant',
    New_Conversation_No_Participants: 'Aucun participant sélectionné',
    New_Conversation_Create: 'Créer conversation',
    // CONVERSATIONS COMPONENT
    Conversations_Meta_Title: 'Conversations',
    Conversations_Conversations_Title: 'Conversations',
    Conversations_Conversation_Title: 'Conversation',
    Conversations_Button_New_Conversation: 'Nouvelle conversation',
    Conversations_Button_Conversations: 'Conversations',
    Conversations_Button_Add_Contact: 'Ajouter un contact',
    Conversations_Button_Contacts: 'Contacts',
    Conversations_Start_Now_Title: 'Commencez une conversation maintenant !',
    Conversations_Disabled_1: 'Cette fonctionnalité est désactivée',
    Conversations_Disabled_2: 'Reviens bientôt',
    // CONVERSATION COMPONENT
    Conversation_Sender_As: 'En tant que',
    Conversation_Button_Edit: 'Modifier la conversation',
    Conversation_First_Messager: 'Soyez le premier à écrire un message',
    Conversation_Loding_Messages_1: 'Chargement...',
    Conversation_Loding_Messages_2: 'Charger les messages précédents',
    Conversation_Message_Removed: 'Message supprimé',
    Conversation_Message_Edited: '(modifié)',
    Conversation_Content_Placeholder: 'Écrire un message...',
    // NEWS COMPONENT
    News_Meta_Title: 'Dernières nouvelles et actualités',
    News_No_News_Title: 'Pas de nouvelles infos',
    // MAKEPAYMENT MODAL
    MakePayment_Transactions: 'Transactions',
    MakePayment_Transaction: 'Transaction',
    MakePayment_To_Sign: 'à signer',
    MakePayment_Wallets: 'portefeuilles',
    MakePayment_Wallet: 'portefeuille',
    MakePayment_Need_PM: "Vous avez besoin d'un moyen de paiement pour utiliser cette fonctionnalité",
    MakePayment_Create_Wallet: 'Créez votre portefeuille',
    MakePayment_Need_Wallet_1: "Vous avez besoin d'un portefeuille",
    MakePayment_Need_Wallet_2: "et d'un moyen de paiement",
    MakePayment_Need_Wallet_3: 'pour utiliser cette fonctionnalité',
    // SCANQRCODE FEATURE
    ScanQRCode_Scanned: 'Scanné !',
    ScanQRCode_Scan_QRCode: 'Scannez un QRCode',
    ScanQRCode_Scan_Off: 'Scanner actuellement indisponible',
    // SHOWQRCODE FEATURE
    ShowQRCode_Source: 'Source de paiement',
    // WALLETS COMPONENT
    Wallets_Meta_Title: 'Portefeuilles',
    Wallets_SC_Updated: 'Code de dépense mis à jour !',
    Wallets_How_To: 'Comment utiliser mes portefeuilles ?',
    Wallets_Edit_SC: 'Modifier le code de dépense',
    Wallets_New_Wallet: 'Nouveau portefeuille',
    Wallets_Exchanges: 'Échanges',
    Wallets_Wallets_Title: 'portefeuilles',
    Wallets_Wallet_Title: 'portefeuille',
    Wallets_Own_By: 'appartenant à',
    Wallets_Tab_Personal: 'Personnel',
    Wallets_NB_Payment_Method: 'Moyen de paiement',
    // WALLET COMPONENT
    Wallet_Meta_Title: 'Portefeuille',
    Wallet_Meta_Recommened: '(fortement recommandé)',
    Wallet_How_To: 'Comment utiliser mon portefeuille ?',
    Wallet_Edit_Button: 'Modifier le portefeuille',
    Wallet_Delete_Button: 'Supprimer le portefeuille',
    Wallet_Lock_Button: 'Verrouiller temporairement le portefeuille',
    Wallet_Own_By: 'appartenant à',
    Wallet_Default: '(par défaut)',
    Wallet_Lipay_Info: 'Nous recommandons vivement de créer un compte Lipay, car il offre des options de paiement mondiales économiques et des fonctionnalités supplémentaires.',
    Wallet_Unavailable_Title: 'Temporairement indisponible',
    Wallet_Unavailable_Label: 'Votre portefeuille est indisponible, veuillez attendre de recevoir un e-mail/notification de notre part.',
    Wallet_KYC_Wait_Title: 'Vérification KYC',
    Wallet_KYC_Wait_Label_1: 'Votre portefeuille est en cours de validation,',
    Wallet_KYC_Wait_Label_2: 'Veuillez attendre de recevoir un e-mail/notification de notre part.',
    Wallet_KYC_Wait_Label_3: 'créé',
    Wallet_KYC_Failed_Title: 'Échec de la vérification KYC',
    Wallet_KYC_Failed_Label_1: 'La vérification de votre portefeuille a échoué,',
    Wallet_KYC_Failed_Label_2: 'Veuillez vérifier vos informations avant de réaliser une nouvelle vérification. Si votre vérification échoue plus de 3 fois, veuillez nous contacter via contact@thebhub.io.',
    Wallet_Button_New_KYC: 'Nouvelle vérification de portefeuille',
    Wallet_New_KYC_Title: 'nouvelle vérification de',
    Wallet_New_BC: 'Ajouter une carte bancaire',
    Wallet_New_BA: 'Nouveau compte Lipay',
    Wallet_New_PM: 'Nouveau moyen de paiement',
    // PAYMENTMETHOD COMPONENT
    PaymentMethod_Meta_Title: 'Moyen de paiement',
    PaymentMethod_All_Tx: 'Toutes les transactions',
    PaymentMethod_Tx_Me: 'Transactions par moi',
    PaymentMethod_Tx_Others: "Transactions par d'autres",
    PaymentMethod_Lock: 'Verrouiller temporairement le moyen de paiement',
    PaymentMethod_Lock_Label: 'Voulez-vous vraiment verrouiller',
    PaymentMethod_Unlock: 'Déverrouiller le moyen de paiement',
    PaymentMethod_Unlock_Label: 'Voulez-vous vraiment déverrouiller',
    PaymentMethod_Delete: 'Supprimer le moyen de paiement',
    PaymentMethod_Select_Default: 'Sélectionner comme par défaut',
    PaymentMethod_Copied: 'copié!',
    PaymentMethod_Title: '(Moyen de paiement)',
    PaymentMethod_Default: '(moyen de paiement par défaut)',
    PaymentMethod_Button_Unlock: 'Déverrouiller',
    PaymentMethod_Button_Lock: 'Verrouiller',
    PaymentMethod_Is_Lock: 'est verrouillé.',
    PaymentMethod_Is_Unlock: 'est déverrouillé.',
    // ALLTRANSACTIONS COMPONENT
    AllTransactions_Received: 'reçu',
    AllTransactions_Sent: 'envoyé',
    AllTransactions_No_Tx: 'Aucune transaction',
    // JOINTEAM PAGE
    Join_Team_Meta_Title: 'Rejoindre une équipe',
    Join_Team_Title: 'Rejoindre une équipe',
    Join_Team_Button_Join: 'Rejoindre',
    Join_Team_Button_Sent: 'demande envoyée',
    Join_Team_Button_New_Team: 'Nouvelle équipe',
    // VIEWTEAMLINEUP MODAL
    ViewTeamLineupModal_Request_Sent: 'Demande envoyée !',
    ViewTeamLineupModal_Request_Already_Sent: 'Vous avez déjà envoyé une demande',
    ViewTeamLineupModal_Button_Join_Team: "Rejoindre l'équipe",
    ViewTeamLineupModal_Button_Sending_Request: 'Envoi de la demande...',
    // ADDTEAMLINEUP MODAL
    AddTeamLineupModal_Title: "Nouvelle composition d'équipe",
    AddTeamLineupModal_Name_Label: 'Nom',
    AddTeamLineupModal_No_Teammate_Selected: 'Aucun coéquipier sélectionné',
    AddTeamLineupModal_Players_Label: 'Composez votre nouvelle composition',
    AddTeamLineupModal_Players_Placeholder: "Écrire un alias d'équipe",
    AddTeamLineupModal_Captains_Label: 'Sélectionnez votre/vos capitaine(s)',
    AddTeamLineupModal_Captains_Placeholder: 'Écrire un alias de joueur',
    AddTeamLineupModal_Button_Create: 'Créer la composition',
    // ADDTEAMPLAYER MODAL
    AddTeamPlayerModal_Title: 'Ajouter un nouveau joueur',
    AddTeamPlayerModal_Name_Label: 'Nom',
    AddTeamPlayerModal_No_Player_Selected: 'Aucun joueur sélectionné',
    AddTeamPlayerModal_Players_Label: 'Joueurs',
    AddTeamPlayerModal_Players_Placeholder: 'Écrire un alias de joueur',
    AddTeamPlayerModal_Button_Create: 'Ajouter le joueur',
    // LISTCONVERSATIONSMESSENGER COMPONENT
    ListConversationsMessenger_All_Contacts_Label: 'Contacts',
    ListConversationsMessenger_All_Contacts_Placeholder: 'Écrire un nom',
    ListConversationsMessenger_Search_Conversations_Placeholder: 'Rechercher dans toutes les conversations',
    ListConversationsMessenger_No_Conversations: 'Aucune conversation',
    // NEWCONVERSATIONMESSENGER COMPONENT
    NewConversationMessenger_Title: 'Nouvelle conversation',
    NewConversationMessenger_Title_Label: 'Titre',
    NewConversationMessenger_Description_Label: 'Sujet (facultatif)',
    NewConversationMessenger_Description_Placeholder: 'Quel est le(s) sujet(s)/objectif(s) de la conversation',
    NewConversationMessenger_Description_Helper_1: 'La note doit avoir au moins 0 caractères',
    NewConversationMessenger_Description_Helper_2: 'Il y a un caractère non autorisé dans',
    NewConversationMessenger_Author_Label: 'Rejoindre en tant que',
    NewConversationMessenger_All_Participants_Label: 'Participants',
    NewConversationMessenger_All_Participants_Placeholder: 'Sélectionner un participant',
    NewConversationMessenger_No_Participants: 'Aucun participant sélectionné',
    NewConversationMessenger_Button_View_Conversation: 'Voir la conversation existante',
    NewConversationMessenger_Button_Start_Conversation: 'Démarrer la conversation',
    // CONVERSATIONMESSENGER COMPONENT
    ConversationMessenger_Sender_As: 'En tant que',
    ConversationMessenger_Edited_Msg: '(modifié)',
    ConversationMessenger_Removed_Msg: 'Message supprimé',
    ConversationMessenger_Write_Msg: 'Écrire un message...',
    // ADDNEWTEAMMANAGER MODAL
    AddTeamManagerModal_Title: 'Ajouter un manager',
    AddTeamManagerModal_No_Manager_Selected: 'Aucun manager sélectionné',
    AddTeamManagerModal_Players_Label: 'Managers',
    AddTeamManagerModal_Players_Placeholder: "Écrire un nom d'utilisateur de joueur",
    AddTeamManagerModal_Button_Create: 'Ajouter un manager',
    // TEAMS PAGE
    Teams_Meta_Title: 'Équipes',
    Teams_New_Team: 'Créez votre équipe',
    Teams_Button_New_Team: 'Nouvelle équipe',
    Teams_Verify_Email: 'Pour créer une équipe, vous devez vérifier votre e-mail',
    // NEWFLEX MODAL
    NewFlexModal_Button_Create: 'Flex maintenant',
    NewFlexModal_No_Flexs: 'Pas de flexs',
    NewFlexModal_Caption_Label: 'Légende',
    NewFlexModal_Caption_Helper_1: 'Votre contenu doit être',
    NewFlexModal_Flexer_Label: 'Flex en tant que',
    NewFlexModal_CoFlexer_Label: 'Co-éditeurs',
    NewFlexModal_CoFlexer_Placeholder: "Écrivez un nom d'utilisateur",
    NewFlexModal_Flexed_Item_Label: 'Éléments flexibles',
    NewFlexModal_Flexed_Item_Placeholder: "Écrivez le nom d'un élément",
    // FLEX COMPONENT
    Flex_Meta_Title: '',
    Flex_Not_Found_1: 'Flex',
    Flex_Not_Found_2: 'non trouvé',
    Flex_Not_Found_3: "Nous sommes désolés, mais il semble que ce flex ne soit pas disponible, veuillez vérifier l'orthographe du nom ou utiliser notre",
    Flex_Not_Found_4: 'moteur de recherche',
    Flex_Not_Found_5: '.',
    Flex_Not_Found_6: 'Flex',
    Flex_Not_Found_7: 'bientôt disponible',
    Flex_Not_Found_8: 'Encore un peu de patience, ils finalisent les détails, mais en attendant, vous pouvez consulter les dernières nouvelles sur la page',
    Flex_Not_Found_9: 'Accueil',
    Flex_Not_Found_10: '.',
    Flex_Button_Edit: 'Modifier flex',
    Flex_Button_Accept: 'Accepter',
    Flex_Waiting_Confirmation_1: 'En attente de confirmation de',
    Flex_Waiting_Confirmation_2: 'pour rendre votre flex visible au public.',
    Flex_Share_Title: 'Fivezer Flex',
    Flex_Share_Text: 'Trouvez plus de flex sur',
    Flex_Flexer_As: 'En tant que',
    Flex_Likes: "j'aime",
    Flex_Like: "j'aime",
    Flex_Comment_Placeholder: 'Écrire un commentaire...',
    Flex_First_Comment: 'Soyez le premier à écrire un commentaire',

    // UTILITAIRES
    Utilities_Affirmation: 'Affirmation',
    Utilities_Affirmation_1: 'Je suis satisfait de mon achat !',
    Utilities_Affirmation_2: 'Expérience globale excellente.',
    Utilities_Affirmation_3: 'Merci pour votre excellent service !',

    Utilities_Anger: 'Colère',
    Utilities_Anger_1: 'Je suis frustré par le service.',
    Utilities_Anger_2: 'Déçu par mon expérience.',
    Utilities_Anger_3: 'Mécontent de la qualité du produit.',

    Utilities_Anxiety: 'Anxiété',
    Utilities_Anxiety_1: "Inquiet à propos de l'achat.",
    Utilities_Anxiety_2: 'Préoccupé par le service.',
    Utilities_Anxiety_3: 'Anxiété due à une mauvaise expérience.',

    Utilities_Blushing: 'Rougissement',
    Utilities_Blushing_1: "Gêné par l'achat.",
    Utilities_Blushing_2: 'Rougissement dû à une situation embarrassante.',
    Utilities_Blushing_3: 'Gêné par le service reçu.',

    Utilities_Contempt: 'Mépris',
    Utilities_Contempt_1: "Mépris pour l'achat.",
    Utilities_Contempt_2: 'Mécontent du service.',
    Utilities_Contempt_3: 'Mépris pour la qualité du produit.',

    Utilities_Despair: 'Désespoir',
    Utilities_Despair_1: "Désespoir à propos de l'achat.",
    Utilities_Despair_2: 'Désespoir dû à une mauvaise expérience.',
    Utilities_Despair_3: 'Désespoir à propos du service reçu.',

    Utilities_Determination: 'Détermination',
    Utilities_Determination_1: 'Déterminé à résoudre un problème.',
    Utilities_Determination_2: 'Motivé à surmonter les défis.',
    Utilities_Determination_3: 'Résolu à améliorer le service.',

    Utilities_Devotion: 'Dévouement',
    Utilities_Devotion_1: 'Dévoué à la marque et à ses produits.',
    Utilities_Devotion_2: 'Fidèle et engagé à soutenir continuellement.',
    Utilities_Devotion_3: 'Dévoué à une expérience client positive.',

    Utilities_Disdain: 'Dédain',
    Utilities_Disdain_1: "Dédain pour l'achat.",
    Utilities_Disdain_2: 'Dédain pour le service fourni.',
    Utilities_Disdain_3: "Mécontent de l'expérience globale.",

    Utilities_Disgust: 'Dégoût',
    Utilities_Disgust_1: "Dégoûté par l'achat.",
    Utilities_Disgust_2: 'Dégoût pour le service reçu.',
    Utilities_Disgust_3: 'Déçu par la qualité du produit.',

    Utilities_Fear: 'Peur',
    Utilities_Fear_1: "Peur du résultat de l'achat.",
    Utilities_Fear_2: 'Anxiété à propos de la qualité du service.',
    Utilities_Fear_3: "Peur d'avoir des problèmes avec le produit.",

    Utilities_Grief: 'Chagrin',
    Utilities_Grief_1: "Chagrin à propos de l'achat.",
    Utilities_Grief_2: 'Tristesse due à une expérience décevante.',
    Utilities_Grief_3: 'Chagrin pour le mauvais service reçu.',

    Utilities_Guilt: 'Culpabilité',
    Utilities_Guilt_1: "Culpabilité à propos de la décision d'achat.",
    Utilities_Guilt_2: 'Regret à propos du service choisi.',
    Utilities_Guilt_3: "Culpabilité pour l'impact négatif sur l'expérience.",

    Utilities_Hatred: 'Haîne',
    Utilities_Hatred_1: "Haîne intense à propos de l'achat.",
    Utilities_Hatred_2: 'Haîne pour le prestataire de service.',
    Utilities_Hatred_3: "Forte aversion pour l'expérience globale.",

    Utilities_Helplessness: 'Impuissance',
    Utilities_Helplessness_1: "Impuissance face à la situation d'achat.",
    Utilities_Helplessness_2: 'Impuissance à gérer les problèmes de service.',
    Utilities_Helplessness_3: "Sentiment accablant d'impuissance.",

    Utilities_HighSpirits: 'Bonne humeur',
    Utilities_HighSpirits_1: "De bonne humeur grâce à l'achat positif.",
    Utilities_HighSpirits_2: 'Énergique et enthousiaste à propos du service.',
    Utilities_HighSpirits_3: "Joyeux et excité par l'expérience globale.",

    Utilities_IllTemper: 'Mauvais caractère',
    Utilities_IllTemper_1: "Mauvais caractère à cause de l'achat.",
    Utilities_IllTemper_2: 'Irrité par le service fourni.',
    Utilities_IllTemper_3: "De mauvaise humeur à cause de l'expérience globale.",

    Utilities_Joy: 'Joie',
    Utilities_Joy_1: "Joie et bonheur à l'achat.",
    Utilities_Joy_2: 'Joyeux et satisfait du service.',
    Utilities_Joy_3: "Heureux et ravi de l'expérience globale.",

    Utilities_Love: 'Amour',
    Utilities_Love_1: "Amour et appréciation pour l'achat.",
    Utilities_Love_2: 'Amour et admiration pour le service reçu.',
    Utilities_Love_3: "Affection et tendresse pour l'expérience globale.",

    Utilities_LowSpirits: 'Baisse de moral',
    Utilities_LowSpirits_1: "Baisse de moral à propos de l'achat.",
    Utilities_LowSpirits_2: 'Découragé par la qualité du service.',
    Utilities_LowSpirits_3: "Baisse de moral à cause de l'expérience globale.",

    Utilities_Mediation: 'Médiation',
    Utilities_Mediation_1: 'Ouvert à la médiation pour résoudre les problèmes.',
    Utilities_Mediation_2: 'Volonté de discuter des préoccupations par la médiation.',
    Utilities_Mediation_3: 'Cherche une solution par la médiation pour les problèmes de service.',

    Utilities_Modesty: 'Modestie',
    Utilities_Modesty_1: "Satisfaction modeste à propos de l'achat.",
    Utilities_Modesty_2: 'Reconnaissance humble du service.',
    Utilities_Modesty_3: "Apprécie l'expérience avec modestie.",

    Utilities_Negation: 'Négation',
    Utilities_Negation_1: "Négation de la satisfaction à l'achat.",
    Utilities_Negation_2: 'Désaccord avec la qualité du service.',
    Utilities_Negation_3: "Négation de l'expérience globale.",

    Utilities_Neutral: 'Neutre',
    Utilities_Neutral_1: "Sentiment neutre à propos de l'achat.",
    Utilities_Neutral_2: 'Position neutre sur le service.',
    Utilities_Neutral_3: "Aucune émotion forte envers l'expérience globale.",

    Utilities_Patience: 'Patience',
    Utilities_Patience_1: 'Exerce la patience pour résoudre les problèmes.',
    Utilities_Patience_2: 'Patient en attente de résolution de service.',
    Utilities_Patience_3: "Patience maintenue tout au long de l'expérience globale.",

    Utilities_Pride: 'Fierté',
    Utilities_Pride_1: "Fier de la décision d'achat.",
    Utilities_Pride_2: 'Fierté du service reçu.',
    Utilities_Pride_3: "Sentiment de fierté pour l'expérience globale.",

    Utilities_Reflection: 'Réflexion',
    Utilities_Reflection_1: "Réflexion sur les choix d'achat.",
    Utilities_Reflection_2: 'Réflexion personnelle après le service.',
    Utilities_Reflection_3: "Réflexion attentive sur l'expérience globale.",

    Utilities_SelfAttention: 'Attention à soi',
    Utilities_SelfAttention_1: "Accord d'attention à soi pour les retours et préoccupations.",
    Utilities_SelfAttention_2: "Concentration sur l'amélioration personnelle après le service.",
    Utilities_SelfAttention_3: "Valorisation de l'attention à soi dans l'expérience globale.",

    Utilities_Shyness: 'Timidité',
    Utilities_Shyness_1: "Timidité à l'expression des retours.",
    Utilities_Shyness_2: 'Timide mais reconnaissant du service.',
    Utilities_Shyness_3: 'Expression des retours avec une pointe de timidité.',

    Utilities_Suffering: 'Souffrance',
    Utilities_Suffering_1: "Souffrance due à l'expérience d'achat.",
    Utilities_Suffering_2: 'Détresse causée par le service reçu.',
    Utilities_Suffering_3: "Souffrance endurée tout au long de l'expérience globale.",

    Utilities_Sulkiness: 'Bougonnerie',
    Utilities_Sulkiness_1: "Bougonnerie à propos du résultat de l'achat.",
    Utilities_Sulkiness_2: 'Bougon à cause de la dissatisfaction du service.',
    Utilities_Sulkiness_3: "Bougonnerie à propos de l'expérience globale.",

    Utilities_Surprise: 'Surprise',
    Utilities_Surprise_1: "Surpris par les aspects inattendus de l'achat.",
    Utilities_Surprise_2: 'Surprise positive avec le service.',
    Utilities_Surprise_3: "Expression de surprise pour l'expérience globale positive.",

    Utilities_TenderFeelings: 'Sentiments tendres',
    Utilities_TenderFeelings_1: "Adoption de sentiments tendres envers l'achat.",
    Utilities_TenderFeelings_2: "Expression d'émotions délicates pour le service.",
    Utilities_TenderFeelings_3: "Chérissement des sentiments tendres dans l'expérience globale.",

    Utilities_Weeping: 'Larmes',
    Utilities_Weeping_1: "Sentiment de pleurer à cause de l'expérience d'achat.",
    Utilities_Weeping_2: 'Larmes versées pour un service décevant.',
    Utilities_Weeping_3: "Expression de tristesse par des larmes dans l'expérience globale.",

    SelectTeamTournamentModal_Title: 'Sélectionnez votre équipe',
    RegistrationTournamentModal_Title: "Sélectionner la composition de l'équipe",
    RegistrationTournamentModal_Teams_Label: 'Équipes existantes',
    RegistrationTournamentModal_Teams_Label_2: 'enregistrées',
    RegistrationTournamentModal_Button_1: 'Rejoindre le tournoi...',
    RegistrationTournamentModal_Button_2: "S'inscrire",
    RegistrationTournamentModal_Button_3: 'Quitter le tournoi...',
    RegistrationTournamentModal_Button_4: 'Se désinscrire',
    RegistrationTournamentModal_New_Team_Label: 'Nouvelle composition',
    RegistrationTournamentModal_New_Team_Caption: 'Créer une nouvelle composition ou utiliser une composition existante',

    NewSponsorshipModal_Descriptions_Title: 'Descriptions',
    NewSponsorshipModal_Price_Title: 'Prix du parrainage',
    NewSponsorshipModal_Price_Value_1: 'Prix',
    NewSponsorshipModal_Price_Currency: 'Devise',
    NewSponsorshipModal_Button_Create: 'Soumettre le parrainage',

    Company_Translations: 'Traductions',

    ViewSponsorshipProposalModal_Proposed_Sponsorship: 'Sponsorship proposé',
    ViewSponsorshipProposalModal_Payment_Plan_1: 'Vous recevrez',
    ViewSponsorshipProposalModal_Payment_Plan_2: 'où le premier sera le',
    ViewSponsorshipProposalModal_Payment_Plan_3: 'et le dernier sera le',
    ViewSponsorshipProposalModal_Button_Accept: 'Accepter',
    ViewSponsorshipProposalModal_Button_Reject: 'Rejeter',
    ViewSponsorshipProposalModal_Response_1: 'Voulez-vous vraiment accepter cette proposition?',
    ViewSponsorshipProposalModal_Response_2: 'Voulez-vous vraiment rejeter cette proposition?',

    AddSponsorshipNoteModal_Title: 'Ajouter la note finale',
    AddSponsorshipNoteModal_Button_Create: 'Soumettre la note',
  },
};

{
  /* <FormattedMessage id="" />&nbsp; */
}
